import {
  Row, Button, Col, Container,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import '../style/Services.css';

function ServiceCard({ id }) {
  const { t } = useTranslation();
  return (
    <Col className='g-0 p-4'>
      <Row className='g-0 p-4 service-card text-center rounded'>
        <span className='mobinov-title mobinov-grey-color d-flex justify-content-center align-items-center'>{t(`service${id}`)}</span>
      </Row>
    </Col>
  );
}

export default function Services() {
  const { t } = useTranslation();
  const cronoIDs = Array(13).fill(null).map((x, i) => i + 1);

  return (
    <div id='services'>
      <Container>
        <h1 className='text-center mobinov-header mobinov-grey-color mb-5'>{t('ourServices')}</h1>
        <Row className='mb-3'>
          <p className='mobinov-text mobinov-grey-color mb-3'>{t('servicesDescription1')}</p>
          <p className='mobinov-text mobinov-grey-color mb-3'>{t('servicesDescription2')}</p>
        </Row>
        <Row className='justify-content-center row-cols-2 row-cols-lg-3'>
          {
            cronoIDs.map((id) => (
              <ServiceCard key={id} id={id} />
            ))
          }
        </Row>
      </Container>
      <Row className='g-0 mt-5'>
        <Col className='text-center g-0 mt-4'>
          <Button href='/become-member' size='lg'>{t('becomeMember')}</Button>
        </Col>
      </Row>
    </div>
  );
}
