/* eslint-disable react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import {
  Scheduler,
} from '@aldabil/react-scheduler';

import '../style/Schedule.css';
import { MdSupervisorAccount, MdLocationOn, MdLink } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import axios from '../api/axios';

function CustomEditor({ scheduler }) {
  useEffect(() => {
    scheduler.close();
  }, []);
  return null;
}

export default function Schedule() {
  const [events, setEvents] = useState([]);
  const { i18n } = useTranslation();

  const getEvents = async () => {
    axios
      .get('/eventsReader', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div>
      <Scheduler
        view='month'
        day={{ startHour: 0, endHour: 23, step: 60 }}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 1,
          startHour: 0,
          endHour: 23,
          step: 60,
        }}
        month={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 1,
          startHour: 0,
          endHour: 23,
        }}
        events={events.map((e) => ({
          event_id: e.event_id,
          title: i18n.language === 'pt' ? e.title_pt : e.title_en,
          start: new Date(e.start_date),
          end: new Date(e.end_date),
          description: i18n.language === 'pt' ? e.description_pt : e.description_en,
          title_pt: e.title_pt,
          title_en: e.title_en,
          description_pt: e.description_pt,
          description_en: e.description_en,
          location_pt: e.location_pt,
          location_en: e.location_en,
          type_pt: e.type_pt,
          type_en: e.type_en,
          link: e.link,
          image: e.image,
          type: i18n.language === 'pt' ? e.type_pt : e.type_en,
        }))}
        // eslint-disable-next-line react/no-unstable-nested-components
        customEditor={(scheduler) => <CustomEditor scheduler={scheduler} />}
        // eslint-disable-next-line react/no-unstable-nested-components
        viewerExtraComponent={(_fields, event) => (
          <div className='calendar-extra-components'>
            <div className='image-box-event'>
              <div className='image-box-image' style={{ backgroundImage: event.image ? `url(${process.env.REACT_APP_BACKEND_URL}/${event.image})` : null }} />
            </div>
            <div className='calendar-event-info'>
              <div style={{ marginRight: '10px', color: '#4D4D4D', fontSize: '13px' }}>
                <MdSupervisorAccount size={25} color='#4D4D4D' />
                { i18n.language === 'pt' ? event.type_pt : event.type_en || '' }
              </div>
              <div style={{ marginRight: '10px', color: '#4D4D4D', fontSize: '13px' }}>
                <MdLocationOn size={20} color='#4D4D4D' />
                {i18n.language === 'pt' ? event.location_pt : event.location_en || ''}
              </div>
            </div>
            <div>
              <p className='mobinov-grey-color mb-0'>Descrição</p>
              <p>
                {i18n.language === 'pt' ? event.description_pt : event.description_en || ''}
              </p>
            </div>
            <div style={{ marginRight: '10px', color: '#4D4D4D', fontSize: '13px' }}>
              <MdLink size={20} color='#4D4D4D' />
              <a href={event.link}>
                {event.link}
                {' '}
              </a>
            </div>

          </div>
        )}
      />
    </div>
  );
}
