import { Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import '../../style/Modal.css';

export default function CredentialsModal({ members, callback }) {
  const [username, setUsername] = useState('');
  const [enterprise, setEnterprise] = useState(null);
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');

  const createUser = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!enterprise) {
      document.getElementById('credentialsFormEnterprise').classList.add('is-invalid');
    } else if (password !== passwordConfirm) {
      document.getElementById('credentialsFormEnterprise').classList.remove('is-invalid');
      document.getElementById('credentialsFormPassword').classList.add('is-invalid');
    } else {
      document.getElementById('credentialsFormEnterprise').classList.remove('is-invalid');
      document.getElementById('credentialsFormPassword').classList.remove('is-invalid');
      callback({
        username, enterprise, role, password, passwordConfirm, linkedin, phoneNumber, email,
      });
    }
  };

  return (
    <div className='modal fade' id='credentialsModal' tabIndex='-1' role='dialog' aria-labelledby='credentialsModalType' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='row d-flex justify-content-end'>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
            <span aria-hidden='true' className='modal-close-btn' />
          </button>
        </div>
        <div className='modal-content py-4 modal-background'>
          <div className='modal-body d-flex justify-content-center'>
            <div className='row w-70'>
              <Form className='needs-validation' onSubmit={createUser} autoComplete='off'>
                <Form.Group className='mb-3 validate-me' controlId='formBasicSubject'>
                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Nome*</Form.Label>
                  <Form.Control type='text' required value={username} onChange={(e) => setUsername(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='credentialsFormEnterprise'>
                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Empresa*</Form.Label>
                  <Form.Select id='credentialsFormEnterprise' name='enterprise' defaultValue='0' onChange={(e) => setEnterprise(e.target.value)}>
                    <option hidden disabled value='0'>Selecione uma empresa</option>
                    {members.map((member) => <option key={member.member_id} value={member.member_id}>{member.name}</option>)}
                  </Form.Select>
                  <Form.Control.Feedback type='invalid'>Selecione uma empresa</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='credentialsFormRole'>
                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Cargo*</Form.Label>
                  <Form.Control type='text' required value={role} onChange={(e) => setRole(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='credentialsFormLinkedin'>
                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Linkedin</Form.Label>
                  <Form.Control type='text' value={linkedin} onChange={(e) => setLinkedin(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='credentialsFormPhone'>
                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Número de contacto</Form.Label>
                  <Form.Control type='text' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='credentialsFormEmail'>
                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Email*</Form.Label>
                  <Form.Control type='text' required value={email} onChange={(e) => setEmail(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='credentialsFormPassword'>
                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Password*</Form.Label>
                  <Form.Control type='password' required value={password} onChange={(e) => setPassword(e.target.value)} />

                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Voltar a introduzir Password*</Form.Label>
                  <Form.Control type='password' required value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>As passwords não são idênticas.</Form.Control.Feedback>
                </Form.Group>
                <div className='row d-flex justify-content-center mt-4'>
                  <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                    Criar Credencial
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
