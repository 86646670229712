import { Col } from 'react-bootstrap';

export default function FAQitem({ question, answer }) {
  return (
    <Col className='g-0 col-12 mx-md-0 col-md-5'>
      <h6 className='mobinov-title mobinov-blue-color text-center mb-3'>{question}</h6>
      <p className='mobinov-text mobinov-grey-color text-center'>{answer}</p>
    </Col>
  );
}
