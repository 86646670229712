import { FaBell } from 'react-icons/fa';
import { MdNotificationsActive } from 'react-icons/md';
import { Col, Row } from 'react-bootstrap';
import useAuth from '../../hooks/useAuth';
import ReservedSidebar from './ReservedSidebar';

function ReservedNavbar(props) {
  const { hasNotifications } = props;
  const { auth } = useAuth();
  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-light'>
      <div className='container-fluid'>
        <a className='navbar-brand mt-2 ml-5' href='/'><img id='logo' width='135' height='70' src='/images/logo-grey.svg' alt='logo' /></a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon' />
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav d-flex w-100 align-items-center justify-content-end mb-2 mb-lg-0'>
            <li className='me-lg-3 mt-4 mt-lg-0'>
              <Row className='g-0 align-items-center'>
                <Col className='text-center'>
                  <img alt='profile' className='profile-picture me-2' src={`${process.env.REACT_APP_BACKEND_URL}/${auth.profileImage}`} />
                </Col>
                <Col>
                  <p className='mobinov-subtitle mobinov-grey-color mb-0'>{auth.username}</p>
                  <p className='mobinov-grey-color mb-0' style={{ fontSize: '18px' }}>{auth.role}</p>
                </Col>
              </Row>
            </li>
            <li className='me-lg-3 mt-3 mt-lg-0'>
              <a href='/notifications' className='custom-a d-flex align-items-center mobinov-grey-color'>
                {hasNotifications ? <MdNotificationsActive size={35} /> : <FaBell size={35} />}
                <span className='ms-2 d-lg-none mobinov-subtitle'>Notifications</span>
              </a>
            </li>
            <li className='me-lg-3 mt-4 mb-2 d-lg-none'>
              <ReservedSidebar />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default ReservedNavbar;
