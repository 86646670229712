import axios from 'axios';

require('dotenv').config();

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || '/api/';

export default axios.create({
  baseURL: BACKEND_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BACKEND_URL,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: true,
});
