import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Button, Row, Col, Container, Form,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth';
import axios from '../../api/axios';

export default function Login() {
  const { setAuth } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || '/reserved-home-page';

  const { t } = useTranslation();

  const [userid, setUserid] = useState('');
  const [password, setPassword] = useState('');
  const [showLoginError, setShowLoginError] = useState(false);
  const [remember, setRemember] = useState(true);

  useEffect(() => {
    setShowLoginError(false);
  }, [userid, password]);

  const togglePersist = () => {
    setRemember((prev) => !prev);
  };

  const authenticate = async (e) => {
    e.preventDefault();

    axios
      .post('/auth', JSON.stringify({ userid, password }), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((response) => {
        const userID = response?.data?.user_id;
        const username = response?.data?.username;
        const email = response?.data?.email;
        const enterprise = response?.data?.enterprise;
        const role = response?.data?.role;
        const permissions = response?.data?.permissions;
        const accessToken = response?.data?.accessToken;
        const profileImage = response?.data?.profile_image;

        setAuth({
          userID, username, email, enterprise, role, permissions, accessToken, profileImage,
        });
        localStorage.setItem('persist', remember);

        navigate(from, { replace: true });
      })
      .catch(() => {
        setShowLoginError(true);
      });
  };

  return (
    <Container className='g-0'>
      <h1 className='mobinov-header mobinov-grey-color text-center mb-5'>Login</h1>

      <Row className='g-0 justify-content-center'>
        <Col className='col-lg-4 p-4 mx-sm-0 mx-3 rounded mobinov-grey-bg shadow'>
          <Form onSubmit={authenticate}>
            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Email</Form.Label>
              <Form.Control type='text' placeholder='Enter your email' value={userid} onChange={(e) => setUserid(e.target.value)} />
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Password</Form.Label>
              <Form.Control type='password' placeholder='Enter your password' value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>

            <Form.Group className='validate-me' controlId='formBasicCheckbox'>
              <Form.Check
                className='mobinov-text-small mobinov-darkgrey-color'
                type='checkbox'
                label={t('rememberMe')}
                onChange={togglePersist}
                checked={remember}
              />
            </Form.Group>

            <p className='text-end'><a href='/recover-password' className='link'>{t('forgotPassword')}</a></p>

            {showLoginError && (
              <div className='alert alert-danger py-1 loginError'>
                {t('errorLogin')}
              </div>
            )}

            <div className='text-end'>
              <Button className='mobinov-subtitle mt-3 px-5' variant='primary' type='submit'>
                Login
              </Button>
            </div>
          </Form>
        </Col>
      </Row>

      <div className='text-center mt-5'>
        <Button variant='primary' type='submit' onClick={() => navigate('/become-member')} className='mobinov-subtitle mobinov-blue-bg w-auto px-3 mt-5'>
          {t('becomeMember')}
        </Button>
      </div>
    </Container>
  );
}
