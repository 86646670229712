import '../style/Statistics.css';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CountuP from 'react-countup';
import { useState, useEffect } from 'react';
import axios from '../api/axios';

export default function Statistics() {
  const { t } = useTranslation();
  const [info, setInfo] = useState([]);

  const getInfo = async () => {
    axios
      .get('/info', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((res) => {
        setInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  return (
    <div id='statistic'>
      <div className='container'>
        <h1 className='text-center mobinov-header mobinov-grey-color mb-2'>{t('statisticsTitle')}</h1>
        <div className='info'>
          <div className='individual'>
            <div className='d-flex img-statistics-container align-baseline'>
              <img alt='' className='img-responsive statics' src='/images/statics/building.svg' />
            </div>
            <h4 className='statistic-title'>{t('enterprises')}</h4>
            <h3 className='result'>
              +
              <CountuP
                end={info.enterprises}
                useEasing
                delay={0}
                decimal={0}
                fontStyle={{ fontSize: 30 }}
                duration={5}
              />
            </h3>
          </div>
          <div className='individual'>
            <div className='d-flex img-statistics-container align-baseline'>
              <img alt='' className='img-responsive statics' src='/images/statics/person.svg' />
            </div>
            <h4 className='statistic-title'>{t('workStations')}</h4>
            <h3 className='result'>
              <CountuP
                end={info.working_stations}
                useEasing
                delay={0}
                decimal={0}
                fontStyle={{ fontSize: 30 }}
                duration={8}
              />
            </h3>
          </div>
          <div className='individual'>
            <div className='d-flex img-statistics-container align-baseline'>
              <img alt='' className='img-responsive statics' src='/images/statics/graphic.svg' />
            </div>
            <h4 className='statistic-title'>{t('businessVolume')}</h4>
            <h3 className='result'>
              <CountuP
                end={info.business_volume}
                useEasing
                delay={0.6}
                decimal={0}
                fontStyle={{ fontSize: 30 }}
                duration={10}
              />
              M€
            </h3>
          </div>
          <div className='individual'>
            <div className='d-flex img-statistics-container align-baseline'>
              <img alt='' className='img-responsive statics' src='/images/statics/plain.svg' />
            </div>
            <h4 className='statistic-title'>{t('exports')}</h4>
            <h3 className='result'>
              <CountuP
                end={info.exportation}
                useEasing
                delay={0.3}
                decimal={0}
                fontStyle={{ fontSize: 30 }}
                duration={5}
              />
              M€
            </h3>
          </div>
          <div className='individual'>
            <div className='d-flex img-statistics-container align-baseline'>
              <img alt='' className='img-responsive statics' src='/images/statics/coin.svg' />
            </div>
            <h4 className='statistic-title'>VAB</h4>
            <h3 className='result'>
              <CountuP
                end={info.vab}
                useEasing
                delay={0.5}
                decimal={0}
                fontStyle={{ fontSize: 30 }}
                duration={9}
              />
              M€
            </h3>
          </div>
        </div>
        <div className='row panel'>
          <div className='col-12 col-md-8 mb-5'>
            <img alt='' className='screenshot img-fluid' src='/images/statics/screenshot.png' />
          </div>
          <div className='col'>
            <Button href='https://observatorioauto.pt/' size='lg' className='position-absolute bottom-0 end-0 mb-5'>{t('moreStatistics')}</Button>
          </div>
        </div>
      </div>
      <Row className='g-0'>
        <Col className='text-center g-0 mt-4'>
          <Button href='/become-member' size='lg'>{t('becomeMember')}</Button>
        </Col>
      </Row>
    </div>
  );
}
