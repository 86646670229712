import '../../style/ReservedSidebar.css';

import {
  useMatch,
  useResolvedPath,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { Stack } from 'react-bootstrap';
import useLogout from '../../hooks/useLogout';
import useAuth from '../../hooks/useAuth';

function NavItem({ icon, text, to }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <a className='custom-a' href={to}>
      <div className={`${match ? 'sidebar-selected' : 'mobinov-grey-color'} sidebar-text border py-2 px-3`}>
        <div className='d-flex align-items-center'>
          <img alt='Sidebar icon' className='reserved-sidebar-icon' src={icon} />
          <span className='ms-3'>{text}</span>
        </div>
      </div>
    </a>
  );
}

function NavItemSmall({ text, to }) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });

  return (
    <a className='custom-a' href={to}>
      <div className={`${match ? 'sidebar-selected-small' : 'mobinov-grey-color'} sidebar-text-small py-2 ps-5`}>
        <span className='w-auto'>{text}</span>
      </div>
    </a>
  );
}

export default function ReservedSidebar() {
  const { auth } = useAuth();
  const navigate = useNavigate();
  const logout = useLogout();

  const logOut = async () => {
    await logout();
    navigate('/login');
  };

  const location = useLocation();
  const confActive = location.pathname.includes('/conf/');

  return (
    <Stack gap={1}>
      <NavItem icon='/images/reservedHomePage/calendar.svg' text='Agenda' to='/agenda' />
      <NavItem icon='/images/reservedHomePage/handshake.svg' text='Oportunidades' to='/opportunities' />
      {
        auth.permissions === 'Admin'
          ? (
            <div>
              <button
                type='button'
                className={`collapsed w-100 border py-2 px-3 mobinov-grey-color sidebar-text ${confActive && 'sidebar-selected'}`}
                data-bs-toggle='collapse'
                data-bs-target='#config-collapse'
                aria-expanded='true'
              >
                <div className='d-flex align-items-center'>
                  <img alt='' className='reserved-sidebar-icon' src='/images/reservedHomePage/config.svg' />
                  <span className='ms-3'>Configurações</span>
                </div>
              </button>
              <div className={`${confActive && 'show'} collapse border`} id='config-collapse'>
                <Stack>
                  <NavItemSmall text='Informações da Conta' to='/conf/account' />
                  <NavItemSmall text='Área Reservada' to='/conf/reserved-area' />
                  <NavItemSmall text='Informações do Site' to='/conf/site' />
                </Stack>
              </div>
            </div>
          )
          : <NavItem icon='/images/reservedHomePage/config.svg' text='Configurações' to='/conf/account' />
      }

      <NavItem icon='/images/reservedHomePage/chat.svg' text='Contactos' to='/contacts' />
      <button
        type='button'
        className='btn p-0 border py-2 px-3 mobinov-grey-color sidebar-text'
        onClick={logOut}
      >
        <div className='d-flex align-items-center'>
          <img alt='' className='reserved-sidebar-icon' src='/images/reservedHomePage/logout.svg' />
          <span className='ms-3'>Log Out</span>
        </div>
      </button>
    </Stack>
  );
}
