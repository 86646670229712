import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ClusterSubRow from '../components/ClusterSubRow';

import '../style/ClusterAuto.css';
import useWindowDimensions from '../components/HandleResize';

const constructors = {
  id: 1,
  clusters: {
    caetanobus: {
      logo: 'images/members/CaetanoBus.png',
    },
    mitsubishi: {
      logo: '/images/members/Mitsubishi.png',
      images: [
        '/images/clusterAuto/constructors/Mitsubishi/08_FUSO Canter Produktion Tramagal.JPG',
        '/images/clusterAuto/constructors/Mitsubishi/_KBF5422.JPG',
        '/images/clusterAuto/constructors/Mitsubishi/D608081.jpg',
        '/images/clusterAuto/constructors/Mitsubishi/D610145.JPG',
        '/images/clusterAuto/constructors/Mitsubishi/D613902.jpg',
        '/images/clusterAuto/constructors/Mitsubishi/DC1_2331.jpg',
        '/images/clusterAuto/constructors/Mitsubishi/DC1_2399.jpg',
        '/images/clusterAuto/constructors/Mitsubishi/DC3_8120.jpg',
        '/images/clusterAuto/constructors/Mitsubishi/DC3_8205.jpg',
      ],
    },
    stellantis: {
      logo: '/images/members/Stellantis.jpg',
    },
    toyota: {
      logo: '/images/members/Toyota.jpg',
      images: [
        '/images/clusterAuto/constructors/Toyota/CAR1.jpg',
        '/images/clusterAuto/constructors/Toyota/CAR3.jpg',
        '/images/clusterAuto/constructors/Toyota/CAR4.jpg',
        '/images/clusterAuto/constructors/Toyota/CAR8.jpg',
        '/images/clusterAuto/constructors/Toyota/CAR9.jpg',
        '/images/clusterAuto/constructors/Toyota/CAR10.jpg',
      ],
    },
    volkswagen: {
      logo: '/images/members/volkswagen.png',
    },
  },
};

const manufacturers = {
  id: 2,
  clusters: {
    aapico: {
      logo: '/images/members/AAPICO.png',
      images: [
        '/images/clusterAuto/manufacturers/AAPICO/AAPICO.JPG',
      ],
    },
    aspock: {
      logo: '/images/members/Aspock.png',
    },
    avl: {
      logo: '/images/members/AVL.jpg',
      images: [
        '/images/clusterAuto/manufacturers/AVLIberica/AVL_Automated and Connected Mobility.jpg',
        '/images/clusterAuto/manufacturers/AVLIberica/AVL_Commercial Vehicles.jpg',
        '/images/clusterAuto/manufacturers/AVLIberica/AVL_Electrification Portfolio.jpg',
        '/images/clusterAuto/manufacturers/AVLIberica/AVL_Instrumentation and Test Systems.jpg',
        '/images/clusterAuto/manufacturers/AVLIberica/AVL_Three Disciplines Under One Roof.jpg',
      ],
    },
    borgstena: {
      logo: '/images/members/Borgstena.png',
      images: [
        '/images/clusterAuto/manufacturers/Borgstena/image005.png',
        '/images/clusterAuto/manufacturers/Borgstena/image023.jpeg',
        '/images/clusterAuto/manufacturers/Borgstena/image024.jpeg',
        '/images/clusterAuto/manufacturers/Borgstena/image025.jpeg',
        '/images/clusterAuto/manufacturers/Borgstena/thumbnail_image007.png',
        '/images/clusterAuto/manufacturers/Borgstena/thumbnail_image009.png',
        '/images/clusterAuto/manufacturers/Borgstena/thumbnail_image013.jpeg',
        '/images/clusterAuto/manufacturers/Borgstena/thumbnail_image015.jpeg',
        '/images/clusterAuto/manufacturers/Borgstena/thumbnail_image017.jpeg',
        '/images/clusterAuto/manufacturers/Borgstena/thumbnail_image021.jpeg',
        '/images/clusterAuto/manufacturers/Borgstena/thumbnail_image022.jpeg',
      ],
    },
    brose: {
      logo: '/images/members/Brose.png',
      images: [
        '/images/clusterAuto/manufacturers/BROSE/HK-TGLII-2.JPG',
        '/images/clusterAuto/manufacturers/BROSE/Modulo-V408-2.JPG',
        '/images/clusterAuto/manufacturers/BROSE/SCA-3-1.jpg',
        '/images/clusterAuto/manufacturers/BROSE/WR-HJB-2.JPG',
      ],
    },
    c2c: {
      logo: '/images/members/C2C.png',
      images: [
        '/images/clusterAuto/manufacturers/C2C NewCap/C2C Go-start.jpg',
        '/images/clusterAuto/manufacturers/C2C NewCap/C2C Module flow.PNG',
        '/images/clusterAuto/manufacturers/C2C NewCap/C2C modules.PNG',
      ],
    },
    cablerias: {
      logo: '/images/members/CC Cablerias.png',
      pdf: '/images/clusterAuto/manufacturers/CableriasGroup/CablagemsAutomotivePortugal_Mobinov.pdf',
    },
    continental: {
      logo: '/images/members/Continental.png',
      images: [
        '/images/clusterAuto/manufacturers/Continental/Continental Advanced Antenna Portugal.jpeg',
      ],
    },
    copefi: {
      logo: '/images/members/Copefi.png',
      pdf: '/images/clusterAuto/manufacturers/COPEFI/mobinov_copefi.pdf',
    },
    copo_textil: {
      logo: '/images/members/CopoTextil.png',
      pdf: '/images/clusterAuto/manufacturers/Copo Textil/CTP Mobinov.pdf',
    },
    electrofer: {
      logo: '/images/members/Eletrofer.jpg',
      images: [
        '/images/clusterAuto/manufacturers/ELECTROFER/Bastidores.jpg',
        '/images/clusterAuto/manufacturers/ELECTROFER/Laboratório.jpg',
        '/images/clusterAuto/manufacturers/ELECTROFER/Linha.jpg',
      ],
      pdf: '/images/clusterAuto/manufacturers/ELECTROFER/Electrofer 4  presentation.pdf',
    },
    epedal: {
      logo: '/images/members/Epedal.png',
      pdf: '/images/clusterAuto/manufacturers/EPEDAL/EPEDAL Company Presentation 2020-01.pdf',
    },
    ert: {
      logo: '/images/members/ERT.png',
    },
    faurecia: {
      logo: '/images/members/Faurecia.png',
    },
    fehst: {
      logo: '/images/members/FEHST.png',
      images: [
        '/images/clusterAuto/manufacturers/Fehst Componentes/FEHST-Entrada.jpg',
      ],
      pdf: '/images/clusterAuto/manufacturers/Fehst Componentes/Mobinov site Fotos.pdf',
    },
    fobric: {
      logo: '/images/members/Fobric.jpg',
    },
    gestamp: {
      logo: '/images/members/Gestamp Vendas Novas.png',
    },
    hydro: {
      logo: '/images/members/Hydro.png',
      images: [
        '/images/clusterAuto/manufacturers/HYDRO/150708_FUP.png',
        '/images/clusterAuto/manufacturers/HYDRO/_AAQ1748.jpg',
        '/images/clusterAuto/manufacturers/HYDRO/_DSC0306.png',
        '/images/clusterAuto/manufacturers/HYDRO/Extrusion_ingot.png',
        '/images/clusterAuto/manufacturers/HYDRO/Extrusions_(1).png',
        '/images/clusterAuto/manufacturers/HYDRO/IMG_6679.png',
      ],
    },
    ieta: {
      logo: '/images/members/IETA.png',
    },
    microplasticos: {
      logo: '/images/members/Microplasticos.jpg',
      images: [
        '/images/clusterAuto/manufacturers/MICROPLÁSTICOS/IMG_6757.jpg',
        '/images/clusterAuto/manufacturers/MICROPLÁSTICOS/IMG_9049.jpg',
        '/images/clusterAuto/manufacturers/MICROPLÁSTICOS/Mp_Gala_01_20220420.jpg',
      ],
    },
    renault: {
      logo: '/images/members/renault.png',
    },
    simoldes: {
      logo: '/images/members/Simoldes.jpg',
      images: [
        '/images/clusterAuto/manufacturers/Simoldes/Parte 1/HR_20201204-Loba-SimoldesPlasticos-0428.jpg',
        '/images/clusterAuto/manufacturers/Simoldes/Parte 1/HR_20201204-Loba-SimoldesPlasticos-0439.jpg',
        '/images/clusterAuto/manufacturers/Simoldes/Parte 1/HR_20201204-Loba-SimoldesPlasticos-0472-Edit-Edit.jpg',
        '/images/clusterAuto/manufacturers/Simoldes/Parte 1/HR_20201204-Loba-SimoldesPlasticos-0476-Edit.jpg',
        '/images/clusterAuto/manufacturers/Simoldes/Parte 1/HR_20201204-Loba-SimoldesPlasticos-0496.jpg',
        '/images/clusterAuto/manufacturers/Simoldes/Parte 1/HR_20201204-Loba-SimoldesPlasticos-0519-2.jpg',
        '/images/clusterAuto/manufacturers/Simoldes/Parte 1/HR_20201204-Loba-SimoldesPlasticos-0565.jpg',
        '/images/clusterAuto/manufacturers/Simoldes/Parte 1/HR_20201204-Loba-SimoldesPlasticos-0582.jpg',
        '/images/clusterAuto/manufacturers/Simoldes/Parte 1/HR_20201204-Loba-SimoldesPlasticos-0761.jpg',
        '/images/clusterAuto/manufacturers/Simoldes/1.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/2.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/3.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/4.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/5.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/6.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/7.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/8.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/9.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/10.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/11.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/12.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/13.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/14.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/15.jpeg',
        '/images/clusterAuto/manufacturers/Simoldes/16.jpeg',
      ],
    },
    slm: {
      logo: '/images/members/SLM.png',
      images: [
        '/images/clusterAuto/manufacturers/SLM/AD0049148.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD0049150.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD0049163.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD0049263.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD0049330.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD0049341.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD0049362.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD0049574.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD0049577.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD0049618.jpg',
        '/images/clusterAuto/manufacturers/SLM/AD_1911_MG_0260.JPG',
        '/images/clusterAuto/manufacturers/SLM/AD_1911_MG_8311.JPG',
        '/images/clusterAuto/manufacturers/SLM/AD_1911_MG_8785.JPG',
        '/images/clusterAuto/manufacturers/SLM/AD_1911_MG_8814.JPG',
        '/images/clusterAuto/manufacturers/SLM/AD_1911_MG_8835.JPG',
      ],
    },
    soplast: {
      logo: '/images/members/Soplast.png',
    },
    texla: {
      logo: '/images/members/Texla.png',
    },
    tmg: {
      logo: '/images/members/TMG.png',
    },
    toolpresse: {
      logo: '/images/members/Toolpresse.png',
      images: [
        '/images/clusterAuto/manufacturers/Toolpresse/IMG_1371_a.jpg',
        '/images/clusterAuto/manufacturers/Toolpresse/IMG_1990 A.jpg',
        '/images/clusterAuto/manufacturers/Toolpresse/IMG_3507a.jpg',
      ],
    },
    yazaki: {
      logo: '/images/members/Yazaki.png',
      pdf: '/images/clusterAuto/manufacturers/YAZAKI/YSE.pdf',
    },
    zf: {
      logo: '/images/members/ZF.png',
      images: [
        '/images/clusterAuto/manufacturers/ZF/Airbag condutor.png',
        '/images/clusterAuto/manufacturers/ZF/Airbag lateral.png',
        '/images/clusterAuto/manufacturers/ZF/Volante ZF.png',
        '/images/clusterAuto/manufacturers/ZF/Volante ZF2.png',
      ],
    },
  },
};

const providers = {
  id: 3,
  clusters: {
    caetanocoatings: {
      logo: '/images/members/Caetano Coatings.png',
      pdf: '/images/clusterAuto/providers/CAETANOCOATINGS/Apresentação Institucional CC 2021 - PT.pdf',
    },
    controlar: {
      logo: '/images/members/Controlar.png',
      images: [
        '/images/clusterAuto/providers/Controlar/AOI.jpg',
        '/images/clusterAuto/providers/Controlar/Bare BRidge4netG.png',
        '/images/clusterAuto/providers/Controlar/Bare BRidget4Net.png',
        '/images/clusterAuto/providers/Controlar/BIMP 3.1.jpg',
        '/images/clusterAuto/providers/Controlar/BurnIn.png',
        '/images/clusterAuto/providers/Controlar/CTunit.png',
        '/images/clusterAuto/providers/Controlar/EOL Test system with vibration _V4.png',
        '/images/clusterAuto/providers/Controlar/Fixtures.jpg',
        '/images/clusterAuto/providers/Controlar/OLS400.png',
        '/images/clusterAuto/providers/Controlar/OLS600.png',
        '/images/clusterAuto/providers/Controlar/OLS800.png',
        '/images/clusterAuto/providers/Controlar/PARTS.jpg',
        '/images/clusterAuto/providers/Controlar/PTCase.png',
        '/images/clusterAuto/providers/Controlar/Stand alone BRidge4Net .png',
        '/images/clusterAuto/providers/Controlar/Stand alone BRidge4NetG.png',
        '/images/clusterAuto/providers/Controlar/TSIM.jpg',
      ],
    },
    drt: {
      logo: '/images/members/DRT Group.png',
      images: [
        '/images/clusterAuto/providers/DRT Group/01_Robot.jpg',
        '/images/clusterAuto/providers/DRT Group/_DSC1904_YK.jpg',
        '/images/clusterAuto/providers/DRT Group/_DSC2923.jpg',
        '/images/clusterAuto/providers/DRT Group/_DSC2948_YK.jpg',
        '/images/clusterAuto/providers/DRT Group/DRT_02.jpg',
      ],
    },
    fluidotronica: {
      logo: '/images/members/Fluidotrónica.png',
      images: [
        '/images/clusterAuto/providers/Fluidotronica/Fotos/2.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/33.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/44.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/55.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/66.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/77.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/88.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/99.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/100.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/111.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/122.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/133.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/144.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/155.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/166.png',
        '/images/clusterAuto/providers/Fluidotronica/Fotos/177.png',
      ],
      pdf: '/images/clusterAuto/providers/Fluidotronica/Fluidotronica_Presentation_PT_Links_no_20210709.pdf',
    },
    follow_inspiration: {
      logo: '/images/members/Follow Inspiration.png',
      images: [
        '/images/clusterAuto/providers/Follow Inspiration/1-PHOTO-2020-10-28-11-41-14.jpg',
        '/images/clusterAuto/providers/Follow Inspiration/2-PHOTO-2020-10-28-12-07-24.jpg',
        '/images/clusterAuto/providers/Follow Inspiration/IMG_4579-2.jpg',
        '/images/clusterAuto/providers/Follow Inspiration/IMG_4588-1.jpg',
      ],
    },
    incredible_vanguard: {
      logo: '/images/members/Incredible Vanguard.png',
    },
    nm3d: {
      logo: '/images/members/NM3D IBERICA.jpg',
      pdf: '/images/clusterAuto/providers/NM3D/NM3DIberica PT.PDF',
    },
    pixartidea: {
      logo: '/images/members/Pixartidea.png',
      images: [
        '/images/clusterAuto/providers/Pixartidea/Pixartidea_process1.jpg',
        '/images/clusterAuto/providers/Pixartidea/Pixartidea_process2.jpg',
        '/images/clusterAuto/providers/Pixartidea/Pixartidea_process3.jpg',
      ],
    },
    rangel: {
      logo: '/images/members/RANGEL.png',
      images: [
        '/images/clusterAuto/providers/RANGEL/barco-rangel.jpg',
        '/images/clusterAuto/providers/RANGEL/camiao-rangel.jpg',
        '/images/clusterAuto/providers/RANGEL/logistica-rangel.jpg',
        '/images/clusterAuto/providers/RANGEL/sede-rangel.jpg',
      ],
    },
    setlevel: {
      logo: '/images/members/Setlevel.jpg',
      images: [
        '/images/clusterAuto/providers/Setlevel/maquina-01.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-02-1920.png',
        '/images/clusterAuto/providers/Setlevel/maquina-03-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-04-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-05-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-06-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-07-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-09-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-15-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-16-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-17-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-20-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-26-1920.jpg',
        '/images/clusterAuto/providers/Setlevel/maquina-plastico.jpeg',
        '/images/clusterAuto/providers/Setlevel/maquina-robot.jpeg',
      ],
    },
    trumpf: {
      logo: '/images/members/TRUMPF.jpg',
      images: [
        '/images/clusterAuto/providers/TRUMPF/Small RGB-TLD_0853.jpg',
      ],
    },
    ubiwhere: {
      logo: '/images/members/Ubiwhere.png',
    },
  },
};

const enterprises = {
  id: 4,
  clusters: {
    acap: {
      logo: '/images/members/ACAP.png',
    },
    afia: {
      logo: '/images/members/AFIA.png',
    },
    apf: {
      logo: '/images/members/APF.png',
    },
    idescom: {
      logo: '/images/members/IDESCOM.png',
    },
  },
};

const universities = {
  id: 5,
  clusters: {
    ipl: {
      logo: '/images/members/IPL.png',
      images: [
        '/images/clusterAuto/universities/IPL/20170127_123155.jpg',
        '/images/clusterAuto/universities/IPL/Apresentacao Formula Student 1.jpg',
        '/images/clusterAuto/universities/IPL/Apresentacao Formula Student 2.jpg',
        '/images/clusterAuto/universities/IPL/bp 1.jpg',
        '/images/clusterAuto/universities/IPL/bp 5.jpg',
        '/images/clusterAuto/universities/IPL/crash 1.jpg',
        '/images/clusterAuto/universities/IPL/DIAMANT.jpg',
        '/images/clusterAuto/universities/IPL/equipa_FSIPLeiria2.jpg',
        '/images/clusterAuto/universities/IPL/ESTG 4.jpg',
        '/images/clusterAuto/universities/IPL/fs 1.jpg',
        '/images/clusterAuto/universities/IPL/fs 2.jpg',
        '/images/clusterAuto/universities/IPL/IMG_7569.jpg',
        '/images/clusterAuto/universities/IPL/INSTRUM.jpg',
        '/images/clusterAuto/universities/IPL/LAB EA0 1.jpg',
        '/images/clusterAuto/universities/IPL/LAB EA0 2.jpg',
        '/images/clusterAuto/universities/IPL/LAB EA1 1.jpg',
        '/images/clusterAuto/universities/IPL/LAB EA1 2.jpg',
        '/images/clusterAuto/universities/IPL/LAB EA1 5.jpg',
      ],
    },
    ips: {
      logo: '/images/members/IPS.png',
    },
    ist: {
      logo: '/images/members/IST.png',
    },
    ua: {
      logo: '/images/members/UA.png',
    },
    uc: {
      logo: '/images/members/UC.png',
      images: [
        '/images/clusterAuto/universities/UC/DSC_6313.jpg',
        '/images/clusterAuto/universities/UC/DSC_6326.jpg',
      ],
    },
  },
};

const investigation = {
  id: 6,
  clusters: {
    ccg: {
      logo: '/images/members/CCG.png',
    },
    ceiia: {
      logo: '/images/members/Ceiia.jpg',
    },
    centi: {
      logo: '/images/members/CENTI.jpg',
    },
    citeve: {
      logo: '/images/members/Citeve.jpg',
    },
    inegi: {
      logo: '/images/members/Inegi.png',
      images: [
        '/images/clusterAuto/investigation/INEGI/6.jpg',
        '/images/clusterAuto/investigation/INEGI/7.jpg',
        '/images/clusterAuto/investigation/INEGI/DSC_0392_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_0602_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_0764_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_0769_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_1785_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_3064_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_3094_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_3157_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_3718_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_4761edit.jpg',
        '/images/clusterAuto/investigation/INEGI/IMG_4882_edit.jpg',
        '/images/clusterAuto/investigation/INEGI/inegi_drone.jpg',
      ],
    },
    inestec: {
      logo: '/images/members/Inesctec.png',
    },
    ipn: {
      logo: '/images/members/IPN.png',
    },
  },
};

const knowledge = {
  id: 7,
  clusters: {
    drivers: {
      logo: '/images/members/3 drivers.png',
      pdf: '/images/clusterAuto/knowledge/3DRIVERS/3drivers.pdf',
    },
    bply: {
      logo: '/images/members/BPLY.png',
    },
    isq: {
      logo: '/images/members/ISQ.jpg',
      images: [
        '/images/clusterAuto/knowledge/ISQ/_MG_0314.JPG',
        '/images/clusterAuto/knowledge/ISQ/_MG_0318.JPG',
        '/images/clusterAuto/knowledge/ISQ/DSC07576.JPG',
        '/images/clusterAuto/knowledge/ISQ/DSC07594.JPG',
        '/images/clusterAuto/knowledge/ISQ/DSC07662.JPG',
        '/images/clusterAuto/knowledge/ISQ/Eng Processos.png',
      ],
    },
    itecons: {
      logo: '/images/members/Itecons.png',
      images: [
        '/images/clusterAuto/knowledge/IteCons/Itecons_Camaras_Climaticas_1.jpg',
        '/images/clusterAuto/knowledge/IteCons/Itecons_Camaras_Climaticas_2.jpg',
        '/images/clusterAuto/knowledge/IteCons/Itecons_Camaras_Climaticas_3.png',
        '/images/clusterAuto/knowledge/IteCons/Itecons_Camaras_Climaticas_4.png',
      ],
    },
    lneg: {
      logo: '/images/members/Lneg.png',
      images: [
        '/images/clusterAuto/knowledge/LNEG/_DSC2831.jpg',
        '/images/clusterAuto/knowledge/LNEG/Edit v2.Still017.jpg',
        '/images/clusterAuto/knowledge/LNEG/Edit v2.Still036.jpg',
      ],
    },
  },
};

const professional = {
  id: 8,
  clusters: {
    atec: {
      logo: '/images/members/ATEC.png',
    },
    cenertec: {
      logo: '/images/members/Cenertec.jpg',
      images: [
        '/images/clusterAuto/professional/CENERTEC/Cenertec40Anos-28.05.2021-Final.png',
        '/images/clusterAuto/professional/CENERTEC/cenertec-prox-cursos.png',
        '/images/clusterAuto/professional/CENERTEC/Infub-13-FINAL.jpg',
        '/images/clusterAuto/professional/CENERTEC/sala.jpg',
        '/images/clusterAuto/professional/CENERTEC/sala2.jpg',
        '/images/clusterAuto/professional/CENERTEC/visita.jpg',
      ],
    },
    opco: {
      logo: '/images/members/RH OPCO.png',
      images: [
        '/images/clusterAuto/professional/RH OPCO/1.png',
        '/images/clusterAuto/professional/RH OPCO/1c.png',
        '/images/clusterAuto/professional/RH OPCO/2.png',
        '/images/clusterAuto/professional/RH OPCO/3.png',
        '/images/clusterAuto/professional/RH OPCO/4.png',
        '/images/clusterAuto/professional/RH OPCO/5.png',
        '/images/clusterAuto/professional/RH OPCO/6.png',
        '/images/clusterAuto/professional/RH OPCO/7.png',
        '/images/clusterAuto/professional/RH OPCO/8.png',
        '/images/clusterAuto/professional/RH OPCO/9.png',
        '/images/clusterAuto/professional/RH OPCO/10.png',
        '/images/clusterAuto/professional/RH OPCO/11.png',
        '/images/clusterAuto/professional/RH OPCO/12.png',
        '/images/clusterAuto/professional/RH OPCO/13.png',
        '/images/clusterAuto/professional/RH OPCO/14.png',
        '/images/clusterAuto/professional/RH OPCO/15.png',
        '/images/clusterAuto/professional/RH OPCO/16.png',
        '/images/clusterAuto/professional/RH OPCO/17.png',
        '/images/clusterAuto/professional/RH OPCO/18.png',
        '/images/clusterAuto/professional/RH OPCO/19.png',
        '/images/clusterAuto/professional/RH OPCO/20.png',
        '/images/clusterAuto/professional/RH OPCO/88.png',
        '/images/clusterAuto/professional/RH OPCO/formacao opco online.jpg',
        '/images/clusterAuto/professional/RH OPCO/image1.png',
        '/images/clusterAuto/professional/RH OPCO/image4.png',
        '/images/clusterAuto/professional/RH OPCO/image5.png',
        '/images/clusterAuto/professional/RH OPCO/image6.png',
        '/images/clusterAuto/professional/RH OPCO/imagem3.png',
        '/images/clusterAuto/professional/RH OPCO/OPCO-1166.jpg',
        '/images/clusterAuto/professional/RH OPCO/OPCO-1423.jpg',
        '/images/clusterAuto/professional/RH OPCO/OPCO-1768.jpg',
      ],
    },
    sgs: {
      logo: '/images/members/SGS.png',
      images: [
        '/images/clusterAuto/professional/SGS/2-orig air conditioner engineers working on roof top Getty-1151366539.jpg',
        '/images/clusterAuto/professional/SGS/2-P1022197.png',
        '/images/clusterAuto/professional/SGS/MicrosoftTeams-image (151).png',
        '/images/clusterAuto/professional/SGS/NewHQ_SGSPortugal.jpg',
        '/images/clusterAuto/professional/SGS/vlcsnap-2021-12-10-17h44m34s762.png',
      ],
    },
  },
};

export default function ClusterAuto() {
  const { width } = useWindowDimensions();

  return (
    width < 992 ? <ClusterAutoMobile /> : <ClusterAutoLarge />
  );
}

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
function ClusterAutoLarge() {
  const [selectedTopCard, setSelectedTopCard] = useState(null);
  const [selectedBottomCard, setSelectedBottomCard] = useState(null);

  function changeTopCard(clustObj) {
    if (selectedBottomCard) setSelectedBottomCard(null);

    if (selectedTopCard && selectedTopCard.id === clustObj.id) {
      setSelectedTopCard(null);
    } else {
      setSelectedTopCard(clustObj);
    }
  }

  function changeBottomCard(clustObj) {
    if (selectedTopCard) setSelectedTopCard(null);

    if (selectedBottomCard && selectedBottomCard.id === clustObj.id) {
      setSelectedBottomCard(null);
    } else {
      setSelectedBottomCard(clustObj);
    }
  }

  const { t } = useTranslation();

  return (
    <Container className='g-0'>
      <Row className='g-0'>
        <Col className='text-center g-0'>
          <h1 className='mobinov-header mobinov-grey-color mb-4'>{t('clusterTitle')}</h1>
        </Col>
      </Row>
      <Row className='d-flex align-items-center justify-content-center'>
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedTopCard?.id === 1 && 'mobinov-card-selected'}`} onClick={() => changeTopCard(constructors)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/car.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterConstructors')}
          </h5>
        </div>
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedTopCard?.id === 2 && 'mobinov-card-selected'}`} onClick={() => changeTopCard(manufacturers)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/utensil.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterComponents')}
          </h5>
        </div>
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedTopCard?.id === 3 && 'mobinov-card-selected'}`} onClick={() => changeTopCard(providers)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/tier.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterProviders')}
          </h5>
        </div>
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedBottomCard?.id === 4 && 'mobinov-card-selected'}`} onClick={() => changeBottomCard(enterprises)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/building.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterEnterprises')}
          </h5>
        </div>
        { selectedTopCard && <ClusterSubRow cluster={selectedTopCard.clusters} /> }
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedBottomCard?.id === 5 && 'mobinov-card-selected'}`} onClick={() => changeBottomCard(universities)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/hat.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterUniversities')}
          </h5>
        </div>
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedBottomCard?.id === 6 && 'mobinov-card-selected'}`} onClick={() => changeBottomCard(investigation)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/hat.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterInvestigation')}
          </h5>
        </div>
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedBottomCard?.id === 7 && 'mobinov-card-selected'}`} onClick={() => changeBottomCard(knowledge)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/brain.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterKnowledge')}
          </h5>
        </div>
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedBottomCard?.id === 8 && 'mobinov-card-selected'}`} onClick={() => changeBottomCard(professional)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/book.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterProfessional')}
          </h5>
        </div>
        { selectedBottomCard && <ClusterSubRow cluster={selectedBottomCard.clusters} /> }
      </Row>
      <Row className='g-0 mt-5'>
        <Col className='text-center g-0 mt-4'>
          <Button href='/become-member' size='lg'>{t('becomeMember')}</Button>
        </Col>
      </Row>
    </Container>
  );
}

function ClusterAutoMobile() {
  const [selectedCard, setSelectedCard] = useState(null);

  function changeCard(clustObj) {
    if (selectedCard && selectedCard.id === clustObj.id) {
      setSelectedCard(null);
    } else {
      setSelectedCard(clustObj);
    }
  }

  const { t } = useTranslation();

  return (
    <Container className='g-0'>
      <Row className='g-0'>
        <Col className='text-center g-0'>
          <h1 className='mobinov-header mobinov-grey-color mb-4'>{t('clusterTitle')}</h1>
        </Col>
      </Row>
      <Row className='g-0 d-flex align-items-center justify-content-center'>
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedCard?.id === 1 && 'mobinov-card-selected'}`} onClick={() => changeCard(constructors)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/car.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterConstructors')}
          </h5>
        </div>
        { selectedCard?.id === 1 && <ClusterSubRow cluster={selectedCard.clusters} /> }
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedCard?.id === 2 && 'mobinov-card-selected'}`} onClick={() => changeCard(manufacturers)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/utensil.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterComponents')}
          </h5>
        </div>
        { selectedCard?.id === 2 && <ClusterSubRow cluster={selectedCard.clusters} /> }
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedCard?.id === 3 && 'mobinov-card-selected'}`} onClick={() => changeCard(providers)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/tier.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterProviders')}
          </h5>
        </div>
        { selectedCard?.id === 3 && <ClusterSubRow cluster={selectedCard.clusters} /> }
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedCard?.id === 4 && 'mobinov-card-selected'}`} onClick={() => changeCard(enterprises)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/building.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterEnterprises')}
          </h5>
        </div>
        { selectedCard?.id === 4 && <ClusterSubRow cluster={selectedCard.clusters} /> }
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedCard?.id === 5 && 'mobinov-card-selected'}`} onClick={() => changeCard(universities)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/hat.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterUniversities')}
          </h5>
        </div>
        { selectedCard?.id === 5 && <ClusterSubRow cluster={selectedCard.clusters} /> }
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedCard?.id === 6 && 'mobinov-card-selected'}`} onClick={() => changeCard(investigation)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/hat.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterInvestigation')}
          </h5>
        </div>
        { selectedCard?.id === 6 && <ClusterSubRow cluster={selectedCard.clusters} /> }
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedCard?.id === 7 && 'mobinov-card-selected'}`} onClick={() => changeCard(knowledge)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/brain.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterKnowledge')}
          </h5>
        </div>
        { selectedCard?.id === 7 && <ClusterSubRow cluster={selectedCard.clusters} /> }
        <div className={`d-flex flex-column align-items-center justify-content-center mobinov-card ${selectedCard?.id === 8 && 'mobinov-card-selected'}`} onClick={() => changeCard(professional)}>
          <img alt='' className='img-responsive statics' src='/images/clusterAuto/book.png' />
          <h5 className='text-center cluster-card-title'>
            {t('clusterProfessional')}
          </h5>
        </div>
        { selectedCard?.id === 8 && <ClusterSubRow cluster={selectedCard.clusters} /> }
      </Row>
      <Row className='g-0 mt-5'>
        <Col className='text-center g-0 mt-4'>
          <Button href='/become-member' size='lg'>{t('becomeMember')}</Button>
        </Col>
      </Row>
    </Container>
  );
}
