import { useTranslation } from 'react-i18next';
import {
  Button, Row, Col, Container, Form,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import axios from '../api/axios';

export default function RecoverPassword() {
  const { t } = useTranslation();
  const { token } = useParams();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [email, setEmail] = useState('');
  const [updated, setUpdated] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  const getToken = () => {
    axios
      .get(`/resetPassword/${token}`)
      .then((res) => {
        setLoading(false);
        setError(false);
        setUpdated(false);
        setShowPasswordError(false);
        setEmail(res.data.email);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setUpdated(false);
      });
  };

  const resetPassword = async (e) => {
    e.preventDefault();

    if (!password.trim().length || !confirmPassword.trim().length) {
      setShowPasswordError(true);
      return;
    }

    if (password !== confirmPassword) {
      setShowPasswordError(true);
      return;
    }

    axios
      .patch('/resetPassword', {
        email,
        password,
      }).then(() => {
        setUpdated(true);
      })
      .catch(() => {
        setShowPasswordError(true);
      });
  };

  useEffect(() => {
    setLoading(true);
    setError(false);
    setEmail('');
    setUpdated(false);
    setShowPasswordError(false);
    getToken();
  }, []);

  const getReturnPage = () => {
    if (loading) {
      return (
        <p className='text-center'>A carregar dados...</p>
      );
    }

    if (error) {
      return (
        <div>
          <p className='text-start'>
            {t('resetPasswordFailure')}
          </p>
          <p className='text-center mt-4'><a href='/recover-password' className='link'>{t('sendAnotherEmail')}</a></p>
        </div>
      );
    }

    return (
      <div>
        { !updated ? (
          <Form onSubmit={resetPassword}>

            <Form.Group className='mb-3' controlId='formBasicPassword'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>{t('newPassword')}</Form.Label>
              <Form.Control type='password' placeholder='Enter your new password' value={password} onChange={(e) => setPassword(e.target.value)} />
            </Form.Group>

            <Form.Group className='mb-3' controlId='formBasicConfirmPassword'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>{t('repeatNewPassword')}</Form.Label>
              <Form.Control type='password' placeholder='Confirm your new password' value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
            </Form.Group>

            {showPasswordError && (
              <div className='alert alert-danger py-1'>
                {t('resetPasswordInvalidPass')}
              </div>
            )}

            <div className='text-center'>
              <Button className='mobinov-subtitle mt-3 px-5' variant='primary' type='submit'>
                {t('resetPasswordSubmit')}
              </Button>
            </div>

            <p className='text-center mt-4'><a href='/login' className='link'>{t('backToLogin')}</a></p>
          </Form>
        )
          : (
            <div>
              <p>
                {t('resetPasswordSuccess')}
              </p>
              <p className='text-center mt-4'><a href='/login' className='link'>{t('backToLogin')}</a></p>
            </div>
          )}
      </div>
    );
  };

  return (
    <Container className='g-0'>
      <h1 className='mobinov-header mobinov-grey-color text-center mb-5 px-3 px-sm-0'>{t('resetPassword')}</h1>
      <Row className='g-0 justify-content-center'>
        <Col className='col-lg-4 p-4 mx-sm-0 mx-3 rounded mobinov-grey-bg shadow'>
          {getReturnPage()}
        </Col>
      </Row>
      <div className='text-center mt-5'>
        <Button variant='primary' href='/become-member' className='mobinov-subtitle mobinov-blue-bg w-auto px-3 mt-5'>
          {t('becomeMember')}
        </Button>
      </div>
    </Container>
  );
}
