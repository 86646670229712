import { Outlet } from 'react-router-dom';
import { Row, Col, Container } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import useAuth from '../../hooks/useAuth';
import ReservedNavbar from './ReservedNavbar';
import ReservedSidebar from './ReservedSidebar';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function ReservedLayout() {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [notifications, setNotifications] = useState(null);

  useEffect(() => {
    axiosPrivate.get(`notifications/${auth.userID}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        setNotifications(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className='App background-img'>
      {notifications && <ReservedNavbar hasNotifications={notifications.length !== 0} />}
      {(window.location.pathname !== '/reserved-home-page') ? (
        <Row className='g-0 my-5 mx-5'>
          <Col className='col-lg-3 me-5 d-none d-lg-block'>
            <ReservedSidebar />
          </Col>
          <Col>
            <Outlet />
          </Col>
        </Row>
      ) : (
        <Container className='g-0 my-auto'>
          <Outlet />
        </Container>
      )}
    </div>
  );
}
