import { Form } from 'react-bootstrap';
import '../../style/Modal.css';
import { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function SubmitApplication({
  buttonText, email, opportunity,
}) {
  const [name, setNewName] = useState('');
  const [enterprise, setNewEnterprise] = useState('');
  const [solution, setNewSolution] = useState('');
  const [contact, setNewContact] = useState('');
  const [validated, setValidated] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const submitApplication = async (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.preventDefault();
      e.stopPropagation();
    }

    setValidated(true);

    if (form.checkValidity() !== false) {
      try {
        axiosPrivate.post('/submitApplication', JSON.stringify({
          name, enterprise, solution, contact, opportunity, email,
        }), {
          headers: { 'Content-Type': 'application/json' },
        })
          .then((res) => console.log('sent with success', res))
          .catch((err) => console.log('not sent', err));
      } catch (msg) {
        console.log(msg);
      }
    }
  };

  return (
    <div className='modal fade' id='submit-application' tabIndex='-1' role='dialog' aria-labelledby='addModalTitle' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content modal-background py-4'>
          <div className='modal-body d-flex justify-content-center'>
            <div className='row w-70'>
              <Form noValidate validated={validated} className='needs-validation' onSubmit={submitApplication}>
                <Form.Group className='mb-3 validate-me' controlId='formBasicSubject'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'>Nome*</Form.Label>
                  <Form.Control value={name} required type='text' onChange={(e) => setNewName(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Por favor insira um nome.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='formBasicSubject'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'>Empresa*</Form.Label>
                  <Form.Control value={enterprise} required type='text' onChange={(e) => setNewEnterprise(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Por favor insira uma empres.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='formBasicName'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'>Solução*</Form.Label>
                  <Form.Control value={solution} required as='textarea' style={{ height: '150px' }} onChange={(e) => setNewSolution(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Por favor insira uma solução.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='formBasicSubject'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'>Contacto*</Form.Label>
                  <Form.Control value={contact} required type='text' onChange={(e) => setNewContact(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Por favor insira um contacto.</Form.Control.Feedback>
                </Form.Group>
                <div className='row d-flex justify-content-center mt-4'>
                  <button
                    variant='primary'
                    type='submit'
                    className='mobinov-subtitle mobinov-blue-bg w-auto btn btn-primary py-2 px-3 btn-modal mt-2'
                  >
                    <span aria-hidden='true'>{buttonText}</span>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
