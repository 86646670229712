import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import axios from '../api/axios';

import '../style/Gallery.css';

export default function Gallery() {
  const { t } = useTranslation();
  const [gallery, setGallery] = useState([]);

  const getGallery = async () => {
    axios
      .get('/galleryReader', {
        withCredentials: true,
      })
      .then((res) => {
        setGallery(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getGallery();
  }, []);

  return (
    <Container className='g-0'>
      <Row className='g-0'>
        <Col className='text-center g-0'><h1 className='mobinov-header mobinov-grey-color mb-4'>{t('gallery')}</h1></Col>
      </Row>
      <Row className='g-0'>
        {gallery.map((image) => (
          <Col key={image.gallery_id} className='g-0 mb-5 px-4' lg={4}>
            <div className='card card-gallery' style={{ backgroundImage: `url(${process.env.REACT_APP_BACKEND_URL}/${image.image_key})` }} />
          </Col>
        ))}
      </Row>
      <Row className='g-0'>
        <Col className='text-center g-0 mt-4'>
          <Button href='/become-member' size='lg'>{t('becomeMember')}</Button>
        </Col>
      </Row>
    </Container>
  );
}
