import '../style/News.css';
import {
  Container, Row, Col, Card, Button, Spinner,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import axios from '../api/axios';

export default function News() {
  const { t, i18n } = useTranslation();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 20;

  const getNews = () => {
    setLoading(true);

    axios
      .get('/newsReader/paginated', { params: { offset, limit } })
      .then((res) => {
        if (!total) setTotal(res.data.count);
        setNews((prevNews) => [...prevNews, ...res.data.rows]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && news.length < total) {
        setOffset((prevOffset) => prevOffset + limit);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, news]);

  const getDate = (date) => {
    const dateInfo = date.split('-');
    return `${dateInfo[2]}/${dateInfo[1]}/${dateInfo[0]}`;
  };

  useEffect(() => {
    getNews();
  }, [offset]);

  return (
    <Container className='g-0'>
      <Row className='g-0'>
        <Col className='text-center g-0'><h1 className='mobinov-header mobinov-grey-color mb-4'>{t('news')}</h1></Col>
      </Row>
      <Col className='d-flex flex-column align-items-center'>
        {news.map((newInfo, index) => (
          <Card
            className='d-flex flex-row mb-5'
            key={newInfo.news_id}
            style={{
              width: '70%',
              background: 'linear-gradient(0deg, #E6E6E6, #E6E6E6), #F5F5F7',
            }}
          >
            <a href={newInfo.link} target='_blank' rel='noopener noreferrer' className='link_news' ref={(news.length === index + 1) ? lastElementRef : null}>
              <Card.Body className='d-flex flex-column justify-content-center'>
                <Card.Title className='title text-center mb-4 mobinov-title'>{i18n.language === 'pt' ? newInfo.title_pt : newInfo.title_en}</Card.Title>
                <div className='card-new'>
                  <Col className='photo-news-container justify-content-center mb-3 mb-lg-0 me-lg-3'>
                    <img className='photo-news' alt='News' src={`${process.env.REACT_APP_BACKEND_URL}/${newInfo.image}`} />
                  </Col>
                  <Col>
                    <p className='card-text mb-4 text-center mobinov-text'>{i18n.language === 'pt' ? newInfo.description_pt : newInfo.description_en}</p>
                  </Col>
                </div>
                <span className='date mobinov-blue-color'>
                  {
                    getDate(newInfo.date)
                  }
                </span>
              </Card.Body>
            </a>
          </Card>
        ))}
      </Col>
      {
        loading
        && (
        <div className='d-flex justify-content-center my-5'>
          <Spinner animation='border' role='status' className='mobinov-blue-color'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
        </div>
        )
      }
      <Row className='g-0'>
        <Col className='text-center g-0 mt-4'>
          <Button className='m-3' href='/become-member' size='lg'>{t('becomeMember')}</Button>
        </Col>
      </Row>
    </Container>
  );
}
