/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import {
  Scheduler,
} from '@aldabil/react-scheduler';
import { useTranslation } from 'react-i18next';
import { MdSupervisorAccount, MdLocationOn, MdLink } from 'react-icons/md';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

import '../../style/Schedule.css';
import HorizontalNonLinearStepper from './ReservedScheduleForm';

function CustomEditor({ scheduler, events, setEvents }) {
  const event = scheduler.edited;
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [error, setError] = useState(null);
  const { i18n } = useTranslation();

  // Make your own form/state
  const [state, setState] = useState({
    title_pt: event?.title_pt || '',
    title_en: event?.title_en || '',
    description_pt: event?.description_pt || '',
    description_en: event?.description_en || '',
    link: event?.link || '',
    location_pt: event?.location_pt || '',
    location_en: event?.location_en || '',
    type_pt: event?.type_pt || '',
    type_en: event?.type_en || '',
    start_date: event?.start || new Date(),
    end_date: event?.end || new Date(),
    user_id: event?.user_id || null,
    image: event?.image || null,
  });

  const createFormData = () => {
    const data = new FormData();
    data.append('title_pt', state.title_pt);
    data.append('title_en', state.title_en);
    data.append('start_date', new Date(state.start_date));
    data.append('end_date', new Date(state.end_date));
    data.append('description_pt', state.description_pt);
    data.append('description_en', state.description_en);
    data.append('link', state.link);
    data.append('location_pt', state.location_pt);
    data.append('location_en', state.location_en);
    data.append('type_pt', state.type_pt);
    data.append('type_en', state.type_en);
    data.append('user_id', auth.userID);
    state.image.data ? data.append('image', state.image.data) : null;

    return data;
  };

  const createNewUpdatedEvent = (res) => ({
    event_id: res.data.event_id,
    title: i18n.language === 'pt' ? state.title_pt : state.title_en,
    start: new Date(state.start_date),
    end: new Date(state.end_date),
    description: i18n.language === 'pt' ? state.description_pt : state.description_en,
    /* não obrigatórios */
    title_pt: state.title_pt,
    title_en: state.title_en,
    description_pt: state.description_pt,
    description_en: state.description_en,
    location_pt: state.location_pt,
    location_en: state.location_en,
    type_pt: state.type_pt,
    type_en: state.type_en,
    link: state.link,
    user_id: state.user_id,
    image: state.image,
  });

  const handleChange = (value, name) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    // Your own validation
    if (state.title_pt.length < 3 || state.title_pt.length < 3) {
      return setError({ ...error, title: 'Min 3 letters' });
    }

    try {
      scheduler.loading(true);
      let addedupdatedevent;

      event
        ? await axiosPrivate
          .patch(`/events/${event.event_id}`, createFormData(), {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => {
            addedupdatedevent = createNewUpdatedEvent(res);
            console.log('successfuly sent');
          })
          .catch((e) => {
            setError({ ...error, updateEvent: 'Failed' });
            console.log('not sent', e);
          })
        : await axiosPrivate
          .post('/events', createFormData(), {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
          .then((res) => {
            addedupdatedevent = createNewUpdatedEvent(res);
            console.log('successfuly sent');
          })
          .catch((e) => {
            setError({ ...error, createEvent: 'Failed' });
            console.log('not sent', e);
          });

      if (!error?.createEvent && !error?.updateEvent) {
        const addedUpdatedEvent = (await new Promise((res) => {
          setTimeout(() => {
            res(addedupdatedevent);
          }, 3000);
        }));
        scheduler.onConfirm(addedUpdatedEvent, event ? 'edit' : 'create');
      }

      setEvents((current) => current.map((e) => {
        if (e.event_id === event.event_id) {
          return { ...state };
        }
        return e;
      }));

      scheduler.close();
      return 0;
    } finally {
      scheduler.loading(false);
    }
  };

  return (
    <HorizontalNonLinearStepper state={state} handleChange={handleChange} handleSubmit={handleSubmit} error={error} scheduler={scheduler} />
  );
}

export default function ReservedSchedule() {
  // eslint-disable-next-line no-unused-vars
  const axiosPrivate = useAxiosPrivate();
  const [events, setEvents] = useState([]);
  const { i18n } = useTranslation();

  const getEvents = async () => {
    await axios
      .get('/eventsReader', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((res) => {
        setEvents(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDelete = async (deletedId) => {
    axiosPrivate
      .delete(`/events/${deletedId}`)
      .then(() => {
        console.log('sent with success');
      })
      .catch((e) => {
        console.log('not sent', e);
      });

    return new Promise((res, rej) => {
      setTimeout(() => {
        res(deletedId);
      }, 3000);
    });
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div>
      <Scheduler
        view='month'
        day={{ startHour: 0, endHour: 23, step: 60 }}
        week={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 1,
          startHour: 0,
          endHour: 23,
          step: 60,
        }}
        month={{
          weekDays: [0, 1, 2, 3, 4, 5, 6],
          weekStartOn: 1,
          startHour: 0,
          endHour: 23,
        }}
        events={events.map((e) => ({
          event_id: e.event_id,
          title: i18n.language === 'pt' ? e.title_pt : e.title_en,
          start: new Date(e.start_date),
          end: new Date(e.end_date),
          description: i18n.language === 'pt' ? e.description_pt : e.description_en,
          /* não obrigatórios */
          title_pt: e.title_pt,
          title_en: e.title_en,
          description_pt: e.description_pt,
          description_en: e.description_en,
          location_pt: e.location_pt,
          location_en: e.location_en,
          type_pt: e.type_pt,
          type_en: e.type_en,
          link: e.link,
          user_id: e.user_id,
          image: e.image,
        }))}
        onDelete={handleDelete}
        // eslint-disable-next-line react/no-unstable-nested-components
        customEditor={(scheduler) => <CustomEditor scheduler={scheduler} events={events} setEvents={setEvents} />}
        // eslint-disable-next-line react/no-unstable-nested-components
        viewerExtraComponent={(_fields, event) => (
          <div className='calendar-extra-components'>
            <div className='image-box-event'>
              <div className='image-box-image' style={{ backgroundImage: event.image ? event.image.preview ? `url(${event.image.preview})` : `url(${process.env.REACT_APP_BACKEND_URL}/${event.image})` : null }} />
            </div>
            <div className='calendar-event-info'>
              <div style={{ marginRight: '10px', color: '#4D4D4D', fontSize: '13px' }}>
                <MdSupervisorAccount size={25} color='#4D4D4D' />
                { i18n.language === 'pt' ? event.type_pt : event.type_en || '' }
              </div>
              <div style={{ marginRight: '10px', color: '#4D4D4D', fontSize: '13px' }}>
                <MdLocationOn size={20} color='#4D4D4D' />
                {i18n.language === 'pt' ? event.location_pt : event.location_en || ''}
              </div>
            </div>
            <div>
              <p className='mobinov-grey-color mb-0'>Descrição</p>
              <p>
                {i18n.language === 'pt' ? event.description_pt : event.description_en || ''}
              </p>
            </div>
            <div style={{ marginRight: '10px', color: '#4D4D4D', fontSize: '13px' }}>
              <MdLink size={20} color='#4D4D4D' />
              <a href={event.link}>
                {event.link}
                {' '}
              </a>
            </div>

          </div>
        )}
      />
    </div>
  );
}
