import { RiSettings5Fill } from 'react-icons/ri';
import { FaLightbulb } from 'react-icons/fa';
import { AiFillCar } from 'react-icons/ai';

export default function Opportunitiy({ opportunity, place }) {
  return (
    <div id={`opportunity-${opportunity.opportunity_id}`} className='row w-100 mb-4'>
      <div className='col-2'>
        <div className='opportunity-icon d-flex justify-content-center align-items-center w-100 h-100'>
          {((place - 1) % 3 === 0 || (place === 0)) && <RiSettings5Fill size={70} color='#0060AE' />}
          {((place + 1) % 3) === 0 && place !== 0 && <FaLightbulb size={70} color='#0060AE' />}
          {(place % 3) === 0 && place !== 0 && <AiFillCar size={70} color='#0060AE' />}
        </div>
      </div>
      <div className='col-6 ml-2'>
        <div className='opportunity-title mobinov-grey-color'>
          {opportunity.title}
        </div>
        <div>
          <span className='opportunity-subtitle mobinov-grey-color'>Tipo: </span>
          <span className='mobinov-grey-color'>{opportunity.type}</span>
        </div>
        <div>
          <a href={`/configurations/opportunity/${opportunity.opportunity_id}`} style={{ color: 'rgba(0, 96, 174, 1)' }}>Ver Oportunidade</a>
        </div>

      </div>
      <div className='col-4'>
        <div className='d-flex align-items-center'>
          <span className='opportunity-subtitle mobinov-grey-color'>Status: </span>
          {opportunity.status === 'green' && <span className='opportunity-bar green ml-2 rounded' />}
          {opportunity.status === 'red' && <span className='opportunity-bar red ml-2 rounded' />}
          {opportunity.status === 'yellow' && <span className='opportunity-bar yellow ml-2 rounded' />}
        </div>
        <div>
          <span className='opportunity-subtitle mobinov-grey-color'>Data Limite de Proposta: </span>
          <span className='mobinov-grey-color'>
            {opportunity.date}
          </span>
        </div>
        <div>
          <span className='opportunity-subtitle mobinov-grey-color'> Localização: </span>
          <span className='mobinov-grey-color'>{opportunity.location}</span>
        </div>
      </div>
    </div>
  );
}
