import {
  Row, Col,
} from 'react-bootstrap';

import ReservedSchedule from '../../components/reservedArea/ReservedSchedule';

export default function PrivateSchedule() {
  return (
    <div className='schedule align-middle'>
      <Row className='g-0'>
        <Col className='text-center g-0'><h1 className='mobinov-header mobinov-grey-color mb-4'>Agenda</h1></Col>
      </Row>
      <div>
        <ReservedSchedule />
      </div>
    </div>
  );
}
