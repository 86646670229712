import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function NotFound() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goHome = () => navigate('/', { replace: true });

  return (
    <Row
      className='g-0 text-center align-items-center'
      style={{ height: '100vh' }}
    >
      <Col className='g-0'>
        <img width='500' src='/images/logo-grey.svg' alt='logo' />
        <h2 className='mobinov-grey-color mb-5'>
          Oops!
          {' '}
          {t('text404')}
        </h2>
        <Button onClick={goHome}>
          <b className='fs-5'>{t('button404')}</b>
        </Button>
      </Col>
    </Row>
  );
}
