import '../../style/Opportunities.css';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SubmitApplication from '../../components/modals/SubmitApplication';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function Opportunity() {
  const location = useLocation();
  const path = location.pathname;
  const [opportunity, setOportunity] = useState([]);
  const [status, setState] = useState([]);
  const [pdfs, setPDFs] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const id = path.replace('/configurations/opportunity/', '');

  useEffect(() => {
    axiosPrivate.get(`opportunities/${auth.userID}/${id}`, {
      headers: { 'Content-Type': 'application/json' },
    })
      .then((res) => {
        setOportunity(res.data.opportunity);
        setState(res.data.status);
        setPDFs(res.data.pdfs);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div id='opportunity h-100'>
      <div className='row w-100'>
        <div className='col'>
          <div className='d-flex w-100 align-items-start align-items-center'>
            <span className='mobinov-title mobinov-grey-color'>
              {opportunity.title}
            </span>
          </div>
          <div className='row w-100 my-3'>
            <div className='col mobinov-grey-color'>
              <span className='opportunity-subtitle'>Tipo: </span>
              {opportunity.type}
            </div>
            <div className='col'>
              <div className='d-flex align-items-center'>
                <span className='opportunity-subtitle mobinov-grey-color'>Status: </span>
                {status === 'green' && <span className='opportunity-bar green ml-2 rounded' />}
                {status === 'red' && <span className='opportunity-bar red ml-2 rounded' />}
                {status === 'yellow' && <span className='opportunity-bar yellow ml-2 rounded' />}
              </div>
            </div>
            <div className='col mobinov-grey-color'>
              <span className='opportunity-subtitle'>Data limite de proposta: </span>
              {opportunity.date}
            </div>
            <div className='col mobinov-grey-color'>
              <span className='opportunity-subtitle'>Localização: </span>
              {opportunity.location}
            </div>

          </div>
          <div className='opportunity-description px-4 py-4 border-blue mobinov-grey-color'>
            <div className='mb-2'>
              <span className='opportunity-bold'> Descrição: </span>
              {opportunity.description}
            </div>
            <span className='opportunity-bold'> Cliente: </span>
            {opportunity.username}
          </div>
          <div className='ml-3 mt-4'>
            <span className='opportunity-bold mobinov-grey-color'>Documentos anexados:</span>
          </div>
          <div className='row'>
            <div className='d-flex flex-wrap col-9 mt-2 align-content-center'>
              {pdfs.map((annex) => (
                <a href={`${process.env.REACT_APP_BACKEND_URL}${annex.link}`} key={annex.pdf_id} className='w-25 mx-5' target='_blank' rel='noopener noreferrer'>
                  <div className='align-items-center d-flex flex-column'>
                    <img className='img-responsive statics' alt={annex.name} src='/images/pdf.svg' width='150px' />
                    <span className='opportunity-pdf mobinov-grey-color'>{annex.name}</span>
                  </div>
                </a>
              ))}
            </div>
            <div className='col d-flex justify-content-end align-items-end'>
              <button type='button' className='opportunity-bold mobinov-blue-bg btn btn-primary mb-3 py-2' aria-label='submit application' data-toggle='modal' data-target='#submit-application'>Submeter Candidatura</button>
            </div>
            <SubmitApplication buttonText='Submeter' email={opportunity.email} opportunity={opportunity.title} />
          </div>
        </div>
      </div>
    </div>
  );
}
