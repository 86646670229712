import React, {
  useState, useEffect, useRef, useCallback,
} from 'react';
import {
  Row, Col, Button, Card, Container, Spinner,
} from 'react-bootstrap';
import { FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import axios from '../api/axios';
import '../style/Schedule.css';
import Schedule from '../components/Schedule';

export default function HomePage() {
  const { t, i18n } = useTranslation();
  const [calendar, setCalendar] = useState(false);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 20;

  const getDate = (date) => {
    const dateInfo = date.split('-');
    return `${dateInfo[2]}/${dateInfo[1]}/${dateInfo[0]}`;
  };

  const getEvents = () => {
    setLoading(true);

    axios
      .get('/eventsReader/paginated', { params: { offset, limit } })
      .then((res) => {
        if (!total) setTotal(res.data.count);
        setEvents((prevEvents) => [...prevEvents, ...res.data.rows]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && events.length < total) {
        setOffset((prevOffset) => prevOffset + limit);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, events]);

  useEffect(() => {
    getEvents();
  }, [offset]);

  return (
    <div className='schedule align-middle'>
      <Row className='g-0'>
        <Col className='text-center g-0'><h1 className='mobinov-header mobinov-grey-color mb-4'>Agenda</h1></Col>
      </Row>
      <div className='text-center mx-auto mb-4 w-75'>
        <Button className='mobinov-grey-color list-button m-0' style={calendar ? { color: 'grey' } : { color: '#0060AE' }} onClick={() => { setCalendar(false); }}>{t('list')}</Button>
        <Button className='mobinov-grey-color calendar-button m-0' style={calendar ? { color: '#0060AE' } : { color: 'grey' }} onClick={() => { setCalendar(true); }}>{t('calendar')}</Button>
      </div>
      {calendar
        ? (
          <Container>
            <Schedule />
          </Container>
        )
        : (
          <>
            <div className='d-flex flex-wrap justify-content-center mx-auto w-75 mb-3'>
              {events.map((eventInfo, index) => (
                <Card key={eventInfo.event_id} className='mx-3 mb-5' style={{ width: '15rem' }} ref={(events.length === index + 1) ? lastElementRef : null}>
                  <Card.Img variant='top' src={`${process.env.REACT_APP_BACKEND_URL}/${eventInfo.image}`} />
                  <Card.Body className='d-flex flex-column'>
                    <Card.Title>{i18n.language === 'pt' ? eventInfo.title_pt : eventInfo.title_en}</Card.Title>
                    <div>
                      <p>
                        <FaClock className='m-1' />
                        {getDate(eventInfo.start_date)}
                      </p>
                      {eventInfo.start_date !== eventInfo.end_date
                        && (
                          <p>
                            <FaClock className='m-1' />
                            {getDate(eventInfo.end_date)}
                          </p>
                        )}
                      <p>
                        <FaMapMarkerAlt className='m-1' />
                        {i18n.language === 'pt' ? eventInfo.location_pt : eventInfo.location_en}
                      </p>
                    </div>
                    <div className='text-center mx-auto w-100 mt-auto'>
                      <Button variant='primary' href={eventInfo.link} className='mobinov-subtitle mobinov-blue-bg w-auto'>
                        {t('moreInfo')}
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
            {
              loading
              && (
                <div className='d-flex justify-content-center my-5'>
                  <Spinner animation='border' role='status' className='mobinov-blue-color'>
                    <span className='visually-hidden'>Loading...</span>
                  </Spinner>
                </div>
              )
            }
          </>
        )}
    </div>
  );
}
