/* eslint-disable camelcase */
import { Modal, Form, Button } from 'react-bootstrap';
import '../../style/Modal.css';
import { useState } from 'react';

export default function ServiceModal({
  show, onHide, history, callback, buttonText,
}) {
  const [title_pt, setTitle_pt] = useState(history ? history.title_pt : '');
  const [title_en, setTitle_en] = useState(history ? history.title_en : '');
  const [description_pt, setDescription_pt] = useState(history ? history.description_pt : '');
  const [description_en, setDescription_en] = useState(history ? history.description_en : '');
  const [date, setDate] = useState(history ? history.date : '');

  const submition = (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      callback('history', {
        title_pt, title_en, description_pt, description_en, date,
      });
    }
  };

  return (
    <Modal show={show} onHide={onHide} centered animation>
      <div className='modal-background rounded'>
        <Modal.Body className='mx-5 my-3'>
          <Form className='needs-validation' onSubmit={submition}>
            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Título PT </Form.Label>
              <Form.Control type='text' required value={title_pt} onChange={(e) => { setTitle_pt(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Título EN </Form.Label>
              <Form.Control type='text' required value={title_en} onChange={(e) => { setTitle_en(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Descrição PT </Form.Label>
              <Form.Control type='text' value={description_pt} onChange={(e) => { setDescription_pt(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Descrição EN </Form.Label>
              <Form.Control type='text' value={description_en} onChange={(e) => { setDescription_en(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Data </Form.Label>
              <Form.Control type='date' required value={date} onChange={(e) => { setDate(e.target.value); }} />
            </Form.Group>

            <div className='row d-flex justify-content-center mt-4'>
              <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                {buttonText}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
}
