/* eslint-disable camelcase */
import { Modal, Form, Button } from 'react-bootstrap';
import '../../style/Modal.css';
import { useState } from 'react';
import useAuth from '../../hooks/useAuth';

export default function EventModal({
  show, onHide, event, callback, buttonText,
}) {
  const { auth } = useAuth();

  const [title_pt, setTitle_pt] = useState(event ? event.title_pt : '');
  const [title_en, setTitle_en] = useState(event ? event.title_en : '');
  const [description_pt, setDescription_pt] = useState(event ? event.description_pt : '');
  const [description_en, setDescription_en] = useState(event ? event.description_en : '');
  const [start_date, setStartDate] = useState(event ? event.start_date : '');
  const [end_date, setEndDate] = useState(event ? event.end_date : '');
  const [link, setLink] = useState(event ? event.link : '');
  const [location_pt, setLocation_pt] = useState(event ? event.location_pt : '');
  const [location_en, setLocation_en] = useState(event ? event.location_en : '');
  const [type_pt, setType_pt] = useState(event ? event.type_pt : '');
  const [type_en, setType_en] = useState(event ? event.type_en : '');

  const [validated, setValidated] = useState(false);
  const [eventImage, setEventImage] = useState(null);

  const submition = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (eventImage == null) {
      callback('events', {
        title_pt,
        title_en,
        description_pt,
        description_en,
        location_pt,
        location_en,
        start_date,
        end_date,
        user_id: auth.userID,
        type_pt,
        type_en,
        link,
      });
    } else {
      const formData = new FormData();
      formData.append('title_pt', title_pt);
      formData.append('title_en', title_en);
      formData.append('description_pt', description_pt);
      formData.append('description_en', description_en);
      formData.append('location_pt', location_pt);
      formData.append('location_en', location_en);
      formData.append('type_pt', type_pt);
      formData.append('type_en', type_en);
      formData.append('link', link);
      formData.append('start_date', start_date);
      formData.append('end_date', end_date);
      formData.append('image', eventImage);
      formData.append('user_id', auth.userID);
      callback('events', {
        title_pt,
        title_en,
        description_pt,
        description_en,
        location_pt,
        location_en,
        start_date,
        end_date,
        user_id: auth.userID,
        type_pt,
        type_en,
        link,
      }, formData);
    }

    setValidated(true);
  };

  const handleFileSelect = (e) => {
    setEventImage(e.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onHide} centered animation>
      <div className='modal-background rounded'>
        <Modal.Body className='mx-5 my-3'>
          <Form validated={validated} className='needs-validation' onSubmit={submition}>
            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Título PT </Form.Label>
              <Form.Control type='text' required value={title_pt} onChange={(e) => { setTitle_pt(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Título EN </Form.Label>
              <Form.Control type='text' required value={title_en} onChange={(e) => { setTitle_en(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Descrição PT </Form.Label>
              <Form.Control as='textarea' required value={description_pt} style={{ height: '150px' }} onChange={(e) => { setDescription_pt(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Descrição EN </Form.Label>
              <Form.Control as='textarea' required value={description_en} style={{ height: '150px' }} onChange={(e) => { setDescription_en(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <div className='row'>
              <Form.Group className='mb-3 col-md-6 validate-me'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Data Início</Form.Label>
                <Form.Control type='date' required value={start_date} onChange={(e) => { setStartDate(e.target.value); }} />
                <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className='mb-3 col-md-6 validate-me'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Data Fim</Form.Label>
                <Form.Control type='date' required value={end_date} onChange={(e) => { setEndDate(e.target.value); }} />
                <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='row'>
              <Form.Group className='mb-3 col-md-6 validate-me'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Tipo PT </Form.Label>
                <Form.Control type='text' required value={type_pt} onChange={(e) => { setType_pt(e.target.value); }} />
                <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 col-md-6 validate-me'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Tipo EN </Form.Label>
                <Form.Control type='text' required value={type_en} onChange={(e) => { setType_en(e.target.value); }} />
                <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className='row'>
              <Form.Group className='mb-3 col-md-6 validate-me'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Localização PT</Form.Label>
                <Form.Control type='text' required value={location_pt} onChange={(e) => { setLocation_pt(e.target.value); }} />
                <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 col-md-6 validate-me'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Localização EN </Form.Label>
                <Form.Control type='text' required value={location_en} onChange={(e) => { setLocation_en(e.target.value); }} />
                <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
              </Form.Group>
            </div>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Link </Form.Label>
              <Form.Control type='url' required value={link} onChange={(e) => { setLink(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Imagem </Form.Label>
              <Form.Control type='file' accept='image/*' required={event == null} onChange={handleFileSelect} />
            </Form.Group>

            <div className='row d-flex justify-content-center mt-4'>
              <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                {buttonText}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
}
