import {
  Container, Row, Col, Button, Stack,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { GoCalendar } from 'react-icons/go';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useTranslation } from 'react-i18next';
import i18n from '../locales/i18n';
import ButtonGroup from '../components/ButtonGroup';
import '../style/WhoAreWe.css';
import axios from '../api/axios';

export default function WhoAreWe(props) {
  const { deviceType } = props;
  const [cronoIDs, setHistoryCards] = useState(null);
  const { t } = useTranslation();
  const responsive = {
    desktop: {
      breakpoint: { max: 2160, min: 992 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 992, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    axios.get('/history').then((res) => setHistoryCards(res.data))
      .catch((err) => console.log(err));
  }, []);

  const getDate = (date) => {
    const dateInfo = date.split('-');
    return `${dateInfo[1]}-${dateInfo[0]}`;
  };

  return (
    <Container className='g-0 justify-content-center px-lg-4 px-2'>
      <h1 className='mobinov-header mobinov-grey-color mb-5 text-center'>
        {t('whoarewe')}
      </h1>
      {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
      <video src='/videos/mobinov-institucional-HD.mp4' width='100%' height='100%' controls playsInline poster='/images/logo-grey.png'>
        Your browser does not support the video tag.
      </video>
      <Stack direction='horizontal' className='justify-content-evenly mt-3'>
        <Button href='https://mobinovptpt-my.sharepoint.com/:b:/g/personal/isabel_oliveira_mobinov_pt/EVwEbeaUC9BJjfoLuHIhBOgB3VkAjqjI8WbgsJfRBJtCUQ' target='_blank' rel='noopener noreferrer' className='whoarewe-pdf-button' size='lg'>{t('chart')}</Button>
        <Button href='https://mobinovptpt-my.sharepoint.com/:b:/g/personal/isabel_oliveira_mobinov_pt/EU6fuzG62aNLgRJ0C4kddP4Bn-JvCB2N1ExM4rXZRAnr9w' target='_blank' rel='noopener noreferrer' className='whoarewe-pdf-button' size='lg'>{t('statute')}</Button>
      </Stack>
      <Row className='g-0 mt-5'>
        <p className='mobinov-text mobinov-grey-color'>
          {t('whoarewe1')}
        </p>
        <p className='mobinov-text mobinov-grey-color'>
          {t('whoarewe2')}
        </p>
      </Row>
      <Row className='g-0'>
        <Col lg={6} className='g-0 pe-lg-3'>
          <h1 className='mobinov-header mobinov-grey-color mt-5 mb-4 text-center align-items-center'>
            <img src='/images/whoarewe/vision.svg' height='45rem' alt='Vision' className='me-3' />
            <span className='align-middle'>{t('vision')}</span>
          </h1>
          <p className='mobinov-text mobinov-grey-color'>{t('visionText')}</p>
        </Col>
        <Col lg={6} className='g-0 ps-lg-3'>
          <h1 className='mobinov-header mobinov-grey-color mt-5 mb-4 text-center'>
            <img src='/images/whoarewe/mission.svg' height='45rem' alt='Mission' className='me-3' />
            <span className='align-middle'>{t('mission')}</span>
          </h1>
          <p className='mobinov-text mobinov-grey-color'>{t('missionText')}</p>
        </Col>
      </Row>
      <Row className='g-0 mt-5'>
        <h1 className='text-center mobinov-header mobinov-grey-color mt-5 mb-4'>{t('mobinovHistory')}</h1>
      </Row>
      <Row className='g-0 text-center px-sm-0 px-3'>
        <div className='whoarewe-carousel'>
          {cronoIDs !== null
            && (
              <Carousel
                arrows={false}
                swipeable={false}
                draggable={false}
                responsive={responsive}
                renderButtonGroupOutside
                customButtonGroup={<ButtonGroup />}
                ssr // means to render carousel on server-side.
                slidesToSlide={1}
                autoPlay={false}
                autoPlaySpeed={86400000}
                keyBoardControl
                transitionDuration={500}
                containerClass='carousel-container'
                deviceType={deviceType}
                dotListClass='custom-dot-list-style'
                itemClass='carousel-item-padding-40-px'
              >
                {
                  cronoIDs.map((cronoCard) => (
                    <Col key={cronoCard.history_id} className='g-0 my-4 px-2'>
                      <div className='card'>
                        <div className='card-header whoarewe-card-header'>
                          <h5 className='card-title mobinov-title mobinov-blue-color my-2'>{i18n.language === 'pt' ? cronoCard.title_pt : cronoCard.title_en}</h5>
                        </div>
                        <div className='card-body whoarewe-card-body py-4'>
                          <div className='card-title mb-3 mobinov-darkblue-color d-flex justify-content-center align-items-center'>
                            <GoCalendar className='whoarewe-icon me-2' />
                            <p className='whoarewe-card-date m-0 ms-2'>{getDate(cronoCard.date)}</p>
                          </div>
                          <p className='whoarewe-card-text card-text text-start mobinov-darkblue-color mobinov-text'>
                            {i18n.language === 'pt' ? cronoCard.description_pt : cronoCard.description_en}
                          </p>
                        </div>
                      </div>
                    </Col>
                  ))
                }
              </Carousel>
            )}
        </div>
      </Row>
      <Row className='g-0'>
        <Col className='text-center g-0 mt-4'>
          <Button href='/become-member' className='mobinov-subtitle mobinov-blue-bg w-auto px-3 mt-4' size='lg'>{t('becomeMember')}</Button>
        </Col>
      </Row>
    </Container>
  );
}
