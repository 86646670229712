export default function ButtonGroup(
  {
    next, previous, goToSlide, size, ...rest
  },
) {
  const { carouselState: { currentSlide, totalItems, slidesToShow } } = rest;
  const numItems = size || totalItems;

  return (
    <div className='whoarewe-button-group'>
      <button aria-label='previous' type='button' className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()}><img className='whoarewe-carousel-icon' src='/images/whoarewe/left_arrow.png' alt='Left Arrow' /></button>
      <button aria-label='next' type='button' className={currentSlide >= (numItems - slidesToShow) ? 'disable' : ''} onClick={() => next()}><img className='whoarewe-carousel-icon' src='/images/whoarewe/right_arrow.png' alt='Right Arrow' /></button>
    </div>
  );
}
