/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
import { Card } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { RiDeleteBinFill } from 'react-icons/ri';
import { MdModeEdit } from 'react-icons/md';
import { GrAddCircle } from 'react-icons/gr';
import Carousel from 'react-bootstrap/Carousel';
import DeleteModal from '../../components/modals/DeleteModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import Datatable from '../../components/DataTable';
import NewsModal from '../../components/modals/NewsModal';
import HistoryModal from '../../components/modals/HistoryModal';
import EventModal from '../../components/modals/EventModal';
import ProjectsModal from '../../components/modals/ProjectsModal';
import MembersModal from '../../components/modals/MembersModal';
import '../../style/Configurations.css';

export default function Administration() {
  const axiosPrivate = useAxiosPrivate();
  const [siteInfo, setSiteInfo] = useState({});
  const [news, setNews] = useState([]);
  const [events, setEvents] = useState([]);
  const [history, setHistory] = useState([]);
  const [projects, setProjects] = useState([]);
  const [gallery, setGallery] = useState([]);
  const [members, setMembers] = useState([]);

  const [modalInfo, setModalInfo] = useState([]);
  const [modalType, setModalType] = useState('');
  const [showAdd, setAddShow] = useState(false);
  const [showEdit, setEditShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);

  const handleCloseEdit = () => setEditShow(false);
  const handleCloseDelete = () => setDeleteShow(false);
  const handleCloseAdd = () => setAddShow(false);

  const handleShowEdit = () => setEditShow(true);
  const handleShowDelete = () => setDeleteShow(true);
  const handleShowAdd = () => setAddShow(true);

  const add = (type, body, formData = null) => {
    if (formData == null) {
      axiosPrivate.post(`/${type}`, JSON.stringify(body), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }).then(() => window.location.reload())
        .catch((e) => console.log(e));
      return;
    }

    axiosPrivate.post(`/${type}`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
    }).then(() => window.location.reload())
      .catch((e) => console.log(e));
  };

  const addImageToGallery = (file) => {
    const formData = new FormData();
    formData.append('image', file);
    axiosPrivate.post('/gallery', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
    }).then(() => window.location.reload())
      .catch((e) => console.log(e));
  };

  const deleteGalleryImage = (image) => {
    axiosPrivate.delete(`/gallery/${image.gallery_id}`, {
      withCredentials: true,
    }).then(() => window.location.reload())
      .catch((e) => console.log(e));
  };

  const newsColumns = [
    {
      name: 'Título',
      selector: (row) => row.title_pt,
      cell: ((row) => (
        <span>
          <b>Título: </b>
          {row.title_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Data',
      selector: (row) => row.date,
      cell: ((row) => (
        <span>
          <b>Data: </b>
          {row.date}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Link',
      selector: (row) => row.link,
      cell: ((row) => (
        <span>
          <b>Link: </b>
          {row.link}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.editButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  const eventsColumns = [
    {
      name: 'Título',
      selector: (row) => row.title_pt,
      cell: ((row) => (
        <span>
          <b>Título: </b>
          {row.title_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Data',
      selector: (row) => row.start_date,
      cell: ((row) => (
        <span>
          <b>Data: </b>
          {row.start_date}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Localidade',
      selector: (row) => row.location_pt,
      cell: ((row) => (
        <span>
          <b>Localização: </b>
          {row.location_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.editButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  const getDate = (date) => {
    const dateInfo = date.split('-');
    return `${dateInfo[0]}-${dateInfo[1]}`;
  };

  const historyColumns = [
    {
      name: 'Título',
      selector: (row) => row.title_pt,
      cell: ((row) => (
        <span>
          <b>Título: </b>
          {row.title_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Descrição',
      selector: (row) => row.description_pt,
      cell: ((row) => (
        <span>
          <b>Descrição: </b>
          {row.description_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Data',
      selector: (row) => row.date,
      cell: ((row) => (
        <span>
          <b>Data: </b>
          {getDate(row.date)}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.editButton,
      cell: ((row) => row.editButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.deleteButton,
      cell: ((row) => row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  const projectsColumns = [
    {
      name: 'Título',
      selector: (row) => row.title_pt,
      cell: ((row) => (
        <span>
          <b>Título: </b>
          {row.title_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Completo',
      selector: (row) => row.complete,
      cell: ((row) => (
        <span>
          <b>Concluído: </b>
          {row.complete && 'Concluído'}
          {!row.complete && 'A decorrer'}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.editButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  const membersColumns = [
    {
      name: 'Imagem',
      selector: (row) => row.image_key,
      cell: ((row) => (
        <img src={`${process.env.REACT_APP_BACKEND_URL}/${row.image_key}`} className='member-partner-image' alt='member_partner_image' />
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'link',
      selector: (row) => row.link,
      cell: ((row) => (
        <span>
          {row.link}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'type',
      selector: (row) => row.type,
      cell: ((row) => (
        <span>
          {row.type}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.type !== 'Admin' && row.editButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.type !== 'Admin' && row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  const saveInfo = () => {
    axiosPrivate.patch('info', JSON.stringify(siteInfo), {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        console.log(res.statusText);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    axiosPrivate.get('news', {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        const newsData = res.data;
        newsData.forEach((singleNews) => {
          const deleteButton = (
            <button type='button' onClick={() => { setModalInfo(singleNews); setModalType('news'); handleShowDelete(); }} aria-label='delete news' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );

          const editButton = (
            <button type='button' onClick={() => { setModalInfo(singleNews); setModalType('news'); handleShowEdit(); }} aria-label='edit news' className='icon'><MdModeEdit style={{ color: ' #4D4D4D' }} /></button>
          );

          singleNews.deleteButton = deleteButton;
          singleNews.editButton = editButton;
        });
        setNews(newsData);
      })
      .catch((err) => console.log(err));

    axiosPrivate.get('events', {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        const eventsData = res.data;
        eventsData.forEach((event) => {
          const deleteButton = (
            <button type='button' onClick={() => { setModalInfo(event); setModalType('event'); handleShowDelete(); }} aria-label='delete event' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );
          const editButton = (
            <button type='button' onClick={() => { setModalInfo(event); setModalType('event'); handleShowEdit(); }} aria-label='edit event' className='icon'><MdModeEdit style={{ color: ' #4D4D4D' }} /></button>
          );
          event.deleteButton = deleteButton;
          event.editButton = editButton;
        });
        setEvents(eventsData);
      })
      .catch((err) => console.log(err));

    axiosPrivate.get(
      'history',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      },
    )
      .then((res) => {
        const historyData = res.data;

        historyData.forEach((historyCard) => {
          const deleteButton = (
            <button type='button' onClick={() => { setModalInfo(historyCard); setModalType('history'); handleShowDelete(); }} aria-label='delete service' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );

          const editButton = (
            <button type='button' onClick={() => { setModalInfo(historyCard); setModalType('history'); handleShowEdit(); }} aria-label='edit history' className='icon'><MdModeEdit style={{ color: ' #4D4D4D' }} /></button>
          );
          historyCard.deleteButton = deleteButton;
          historyCard.editButton = editButton;
        });
        setHistory(historyData);
      })
      .catch((err) => console.log(err));

    axiosPrivate.get(
      'projects',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      },
    )
      .then((res) => {
        const projectsData = res.data;
        projectsData.forEach((project) => {
          const deleteButton = (
            <button type='button' onClick={() => { setModalInfo(project); setModalType('project'); handleShowDelete(); }} aria-label='delete service' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );
          const editButton = (
            <button type='button' onClick={() => { setModalInfo(project); setModalType('project'); handleShowEdit(); }} aria-label='edit service' className='icon'><MdModeEdit style={{ color: ' #4D4D4D' }} /></button>
          );
          project.deleteButton = deleteButton;
          project.editButton = editButton;
        });
        setProjects(projectsData);
      })
      .catch((err) => console.log(err));

    axiosPrivate.get('info', {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => setSiteInfo(res.data));

    axiosPrivate.get('gallery', {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        const { data } = res;
        if ((data.length % 3) !== 0) {
          data.push(data[0]);
          if ((data.length % 3) !== 0) data.push(res.data[1]);
        }

        const galleryTrios = [];
        for (let i = 0; i < data.length; i += 3) {
          galleryTrios.push([data[i], data[(i + 1) % data.length], data[i + 2]]);
        }

        setGallery(galleryTrios);
      });
    axiosPrivate.get('members', {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        const membersData = res.data;
        membersData.forEach((member) => {
          const deleteButton = (
            <button type='button' onClick={() => { setModalInfo(member); setModalType('member'); handleShowDelete(); }} aria-label='delete member' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );

          const editButton = (
            <button type='button' onClick={() => { setModalInfo(member); setModalType('member'); handleShowEdit(); }} aria-label='edit member' className='icon'><MdModeEdit style={{ color: ' #4D4D4D' }} /></button>
          );

          member.editButton = editButton;
          member.deleteButton = deleteButton;
        });
        setMembers(membersData);
      })
      .catch((err) => console.log(err));
  }, []);

  /* eslint-disable react/function-component-definition */
  const ModalContent = () => {
    if (modalType === 'member') {
      if (showAdd) return <MembersModal show={showAdd} onHide={handleCloseAdd} buttonText='Adicionar' callback={add} />;

      const deleteMember = () => {
        axiosPrivate.delete(`members/${modalInfo.member_id}`, {
          withCredentials: true,
        }).then(() => { window.location.reload(); })
          .catch((e) => console.log(e));
      };

      const editMember = (typeQuery, newMember, formData) => {
        axiosPrivate.patch(`${typeQuery}/${modalInfo.member_id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      return showEdit ? <MembersModal show={showEdit} onHide={handleCloseEdit} member={modalInfo} callback={editMember} buttonText='Editar' />
        : <DeleteModal show={showDelete} onHide={handleCloseDelete} text='o membro' deleteFunction={deleteMember} />;
    }

    if (modalType === 'project') {
      if (showAdd) return <ProjectsModal show={showAdd} onHide={handleCloseAdd} buttonText='Adicionar' callback={add} />;

      const deleteProject = () => {
        axiosPrivate.delete(`projects/${modalInfo.project_id}`, {
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      const editProject = (type, newProject, formData = null) => {
        if (formData == null) {
          const {
            title_pt, title_en, complete, link, sheet_link,
          } = newProject;
          try {
            axiosPrivate.patch(`${type}/${modalInfo.project_id}`, JSON.stringify({
              title_pt, title_en, complete, link, sheet_link,
            }), {
              headers: { 'Content-Type': 'application/json' },
              withCredentials: true,
            }).then(() => window.location.reload());
          } catch (e) {
            console.log(e);
          }
          return;
        }
        try {
          axiosPrivate.patch(`${type}/${modalInfo.project_id}`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
          }).then(() => window.location.reload());
        } catch (e) {
          console.log(e);
        }
      };

      return showEdit ? <ProjectsModal show={showEdit} onHide={handleCloseEdit} project={modalInfo} callback={editProject} buttonText='Editar' />
        : <DeleteModal show={showDelete} onHide={handleCloseDelete} text='o projeto' deleteFunction={deleteProject} />;
    }

    if (modalType === 'history') {
      if (showAdd) return <HistoryModal show={showAdd} onHide={handleCloseAdd} buttonText='Adicionar' callback={add} />;

      const deleteHistory = () => {
        axiosPrivate.delete(`history/${modalInfo.history_id}`, {
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      const editHistory = (type, newHistory) => {
        const {
          title_pt, title_en, description_pt, description_en, date,
        } = newHistory;
        try {
          axiosPrivate.patch(`${type}/${modalInfo.history_id}`, JSON.stringify({
            title_pt, title_en, description_pt, description_en, date,
          }), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }).then(() => window.location.reload());
        } catch (e) {
          console.log(e);
        }
      };

      return showEdit ? <HistoryModal show={showEdit} onHide={handleCloseEdit} history={modalInfo} callback={editHistory} buttonText='Editar' />
        : <DeleteModal show={showDelete} onHide={handleCloseDelete} text='a história' deleteFunction={deleteHistory} />;
    }

    if (modalType === 'event') {
      if (showAdd) return <EventModal show={showAdd} onHide={handleCloseAdd} buttonText='Adicionar' callback={add} />;

      const deleteEvent = () => {
        axiosPrivate.delete(`events/${modalInfo.event_id}`, {
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      const editEvent = (type_, newEvent, formData = null) => {
        if (formData === null) {
          const {
            title_pt, title_en, description_pt, description_en, location_pt, location_en, start_date, end_date, user_id, type_pt, type_en, link,
          } = newEvent;
          axiosPrivate.patch(`${type_}/${modalInfo.event_id}`, JSON.stringify({
            title_pt, title_en, description_pt, description_en, location_pt, location_en, start_date, end_date, user_id, type_pt, type_en, link,
          }), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }).then(() => window.location.reload())
            .catch((e) => console.log(e));

          return;
        }
        axiosPrivate.patch(`${type_}/${modalInfo.event_id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      return showEdit ? <EventModal show={showEdit} onHide={handleCloseEdit} event={modalInfo} callback={editEvent} buttonText='Editar' />
        : <DeleteModal show={showDelete} onHide={handleCloseDelete} text='o evento' deleteFunction={deleteEvent} />;
    }

    if (modalType === 'news') {
      if (showAdd) return <NewsModal show={showAdd} onHide={handleCloseAdd} buttonText='Adicionar' callback={add} />;

      const deleteNews = () => {
        axiosPrivate.delete(`news/${modalInfo.news_id}`, {
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      const editNews = (type, newNews, formData = null) => {
        if (formData == null) {
          const {
            title_pt, title_en, description_pt, description_en, date, link,
          } = newNews;
          axiosPrivate.patch(`${type}/${modalInfo.news_id}`, JSON.stringify({
            title_pt, title_en, description_pt, description_en, date, link,
          }), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }).then(() => window.location.reload())
            .catch((e) => console.log(e));
          return;
        }
        axiosPrivate.patch(`${type}/${modalInfo.news_id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      return showEdit ? <NewsModal show={showEdit} onHide={handleCloseEdit} news={modalInfo} buttonText='Editar' callback={editNews} />
        : <DeleteModal show={showDelete} onHide={handleCloseDelete} text='a notícia' deleteFunction={deleteNews} />;
    }

    if (modalType === 'gallery' && showDelete) {
      console.log(modalInfo);
      return <DeleteModal show={showDelete} onHide={handleCloseDelete} text='a história' deleteFunction={() => deleteGalleryImage(modalInfo)} />;
    }

    return null;
  };

  return (
    <div id='siteInformation'>
      <div className='row w-100'>
        <ModalContent />
        <div className='col d-flex align-items-center'>
          <span className='mobinov-title mobinov-grey-color'>Informações do Site </span>
        </div>
      </div>
      <div className='config-row'>
        <div className='row w-100 mx-1'>
          <div className='col-md-5  align-items-start'>
            <div className='row mobinov-title mobinov-grey-color my-4'>
              Números da empresa:
            </div>
            <div id='config-event-row' className='row border-blue blue-background w-100 ml-2 mobinov-text'>
              <div className='d-flex justify-content-between my-2 py-2'>
                Empresas
                <input type='text' size='10' defaultValue={siteInfo.enterprises} onChange={(e) => { siteInfo.enterprises = e.target.value; }} />
              </div>
            </div>
            <div id='config-event-row' className='row border-blue blue-background w-100  mobinov-text'>
              <div className='d-flex justify-content-between my-2 py-2'>
                Postos de Trabalho
                <input type='text' size='10' defaultValue={siteInfo.working_stations} onChange={(e) => { siteInfo.working_stations = e.target.value; }} />
              </div>
            </div>
          </div>
          <div className='col-md-5  align-items-center'>
            <div className='mobinov-title mobinov-grey-color d-flex flex-row-reverse my-4'>
              &nbsp;
            </div>
            <div id='config-event-row' className='row border-blue blue-background w-100 mobinov-text'>
              <div className='d-flex justify-content-between my-2 py-2'>
                Volume de negócios
                <input type='text' size='10' defaultValue={siteInfo.business_volume} onChange={(e) => { siteInfo.business_volume = e.target.value; }} />
              </div>
            </div>
            <div id='config-event-row' className='row border-blue blue-background w-100 ml-2 mobinov-text'>
              <div className='d-flex justify-content-between my-2 py-2'>
                Exportações
                <input type='text' size='10' defaultValue={siteInfo.exportation} onChange={(e) => { siteInfo.exportation = e.target.value; }} />
              </div>
            </div>
            <div id='config-event-row' className='row border-blue blue-background w-100  mobinov-text'>
              <div className='d-flex justify-content-between my-2 py-2'>
                VAB
                <input type='text' size='10' defaultValue={siteInfo.vab} onChange={(e) => { siteInfo.vab = e.target.value; }} />
              </div>
            </div>
          </div>
          <div className='col-md-2  align-items-center'>
            <div className='mobinov-title mobinov-grey-color d-flex mt-2'>
              {' '}
            </div>
          </div>
        </div>
        <div className='row w-100'>
          <div className='d-flex flex-row-reverse my-3'>
            <button type='button' className='btn btn-primary mobinov-subtitle mb-3 py-2' onClick={saveInfo}> Guardar alterações</button>
          </div>
        </div>
        <div className='row w-100'>
          <div className='col d-flex align-items-center'>
            <button type='button' aria-label='de' className='icon' onClick={() => { setModalType('news'); handleShowAdd(); }}><GrAddCircle style={{ color: ' #4D4D4D' }} /></button>
            <span className='mobinov-title mobinov-grey-color'>Notícias: </span>
          </div>
        </div>
        <div className='my-3 mr-4'>
          <Datatable columns={newsColumns} data={news} text='title_pt' searchbar='título' paginationB searchable />
        </div>
        <div className='row w-100'>
          <div className='col d-flex align-items-center'>
            <button type='button' aria-label='de' className='icon' onClick={() => { setModalType('event'); handleShowAdd(); }}><GrAddCircle style={{ color: ' #4D4D4D' }} /></button>
            <span className='mobinov-title mobinov-grey-color'>Eventos: </span>
          </div>
        </div>
        <div className='my-3 mr-4'>
          <Datatable columns={eventsColumns} data={events} text='title_pt' searchbar='título' paginationB searchable />
        </div>
        <div className='row w-100'>
          <div className='col d-flex align-items-center'>
            <button type='button' aria-label='de' className='icon' onClick={() => { setModalType('member'); handleShowAdd(); }}><GrAddCircle style={{ color: ' #4D4D4D' }} /></button>
            <span className='mobinov-title mobinov-grey-color'>Membros: </span>
          </div>
        </div>
        <div className='my-3 mr-4'>
          <Datatable columns={membersColumns} data={members} text='type' searchbar='tipo' paginationB searchable />
        </div>
        <div className='row w-100'>
          <div className='col d-flex align-items-center'>
            <button type='button' aria-label='de' className='icon' onClick={() => { setModalType('history'); handleShowAdd(); }}><GrAddCircle style={{ color: ' #4D4D4D' }} /></button>
            <span className='mobinov-title mobinov-grey-color'>História: </span>
          </div>
        </div>
        <div className='my-3 mr-4'>
          <Datatable columns={historyColumns} data={history} text='title_pt' searchbar='título' paginationB searchable />
        </div>
        <div className='row w-100'>
          <div className='col d-flex align-items-center'>
            <button type='button' aria-label='de' className='icon' onClick={() => { setModalType('project'); handleShowAdd(); }}><GrAddCircle style={{ color: ' #4D4D4D' }} /></button>
            <span className='mobinov-title mobinov-grey-color'>Projetos: </span>
          </div>
        </div>
        <div className='my-3 mr-4'>
          <Datatable columns={projectsColumns} data={projects} text='title_pt' searchbar='título' paginationB searchable />
        </div>
      </div>
      <div className='config-row row'>
        <div className='col d-flex align-items-center'>
          <button type='button' aria-label='de' className='icon' data-toggle='modal' data-target='#addServicesModal' onClick={() => document.getElementById('button-input-gallery-img').click()}><GrAddCircle style={{ color: ' #4D4D4D' }} /></button>
          <input id='button-input-gallery-img' type='file' accept='image/*' style={{ display: 'none' }} onChange={(e) => addImageToGallery(e.target.files[0])} />
          <span className='mobinov-title mobinov-grey-color'>Galeria: </span>
        </div>
      </div>
      <Carousel variant='dark' className='gallery-carousel config-row mt-4'>
        {gallery.map((trio) => (
          <Carousel.Item key={trio[0].image_key} className='carousel-item-config'>
            <div className='row justify-content-center row-cols-md-3'>
              <Card
                className='m-1 col-sm-4'
                style={{
                  width: '16rem',
                  height: '20rem',
                  border: 'unset',
                  padding: 'unset',
                  background: 'linear-gradient(0deg, #E6E6E6, #E6E6E6), #F5F5F7',
                }}
              >
                <Card.Img variant='top' style={{ width: '100%', height: '72%' }} src={`${process.env.REACT_APP_BACKEND_URL}/${trio[0].image_key}`} />
                <Card.Body className='no-margin'>
                  <Card.Title className='d-flex flex-column align-items-end'>
                    <button type='button' aria-label='delete service' className='gallery-icon mr-4' onClick={() => { setModalInfo(trio[0]); setModalType('gallery'); handleShowDelete(); }}><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
                  </Card.Title>
                </Card.Body>
              </Card>
              <Card
                className='m-1 col-sm-4'
                style={{
                  width: '16rem',
                  height: '20rem',
                  border: 'unset',
                  padding: 'unset',
                  background: 'linear-gradient(0deg, #E6E6E6, #E6E6E6), #F5F5F7',
                }}
              >
                <Card.Img variant='top' style={{ width: '100%', height: '72%' }} src={`${process.env.REACT_APP_BACKEND_URL}/${trio[1].image_key}`} />
                <Card.Body className='no-margin'>
                  <Card.Title className='d-flex flex-column align-items-end'>
                    <button type='button' aria-label='delete service' className='gallery-icon mr-4' onClick={() => { setModalInfo(trio[1]); setModalType('gallery'); handleShowDelete(); }}><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
                  </Card.Title>
                </Card.Body>
              </Card>
              <Card
                className='m-1 col-sm-4'
                style={{
                  width: '16rem',
                  height: '20rem',
                  border: 'unset',
                  padding: 'unset',
                  background: 'linear-gradient(0deg, #E6E6E6, #E6E6E6), #F5F5F7',
                }}
              >
                <Card.Img variant='top' style={{ width: '100%', height: '72%' }} src={`${process.env.REACT_APP_BACKEND_URL}/${trio[2].image_key}`} />
                <Card.Body className='no-margin'>
                  <Card.Title className='d-flex flex-column align-items-end'>
                    <button type='button' aria-label='delete service' className='gallery-icon mr-4' onClick={() => { setModalInfo(trio[2]); setModalType('gallery'); handleShowDelete(); }}><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
                  </Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
