import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';

function RequireAuth({ allowedPermissions }) {
  const { auth } = useAuth();
  const location = useLocation();

  return auth.permissions ? (
    allowedPermissions.includes(auth.permissions) ? (
      <Outlet />
    ) : (
      <Navigate to='/unauthorized' state={{ from: location }} replace />
    )
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
}

export default RequireAuth;
