import '../../style/Notifications.css';
import { useEffect, useState } from 'react';
import Notification from '../../components/Notification';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function Notifications() {
  const axiosPrivate = useAxiosPrivate();
  const [notifications, setNotifications] = useState([]);
  const { auth } = useAuth();

  useEffect(() => {
    axiosPrivate.get(`notifications/${auth.userID}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => setNotifications(res.data))
      .catch((err) => console.log(err));
  }, []);

  return (
    <table className='table table-hover'>
      <thead className='text-white blue-table-header'>
        <tr className='border-blue'>
          <th scope='col w-50'>Assunto</th>
          <th scope='col'>Data</th>
          <th scope='col'>Utilizador</th>
          <th scope='col w-25'> </th>
        </tr>
      </thead>
      <tbody id='notifications-list'>
        {notifications.map((notification) => (
          <Notification
            key={notification.notification_id}
            notification={notification}
          />
        ))}
      </tbody>
    </table>
  );
}
