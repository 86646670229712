/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { TextField, Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BiImageAdd } from 'react-icons/bi';

const steps = ['Versão Português', 'Versão Inglês', 'Adicionar Imagem'];

export default function HorizontalNonLinearStepper({
  state, handleChange, handleSubmit, error, scheduler,
}) {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => steps.length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const handleNext = () => {
    const newActiveStep = isLastStep() ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <Box sx={{ width: '100%', padding: '1rem' }}>
      <div className='text-center g-0'><h1 className='mobinov-header mobinov-grey-color mb-2'>{ state.title_pt ? 'Editar Evento' : 'Adicionar Evento' }</h1></div>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        { activeStep !== totalSteps() - 1
          ? (
            <div>
              <TextField
                className='title-input'
                id='outlined-basic'
                label='Titulo'
                variant='outlined'
                value={activeStep === 0 ? state.title_pt : state.title_en}
                onChange={(e) => handleChange(e.target.value, activeStep === 0 ? 'title_pt' : 'title_en')}
                error={!!error}
                helperText={!!error && error.title}
                fullWidth
              />
              <TextField
                className='description-input'
                id='outlined-basic'
                variant='outlined'
                label='Descrição'
                multiline
                minRows={2}
                maxRows={4}
                value={activeStep === 0 ? state.description_pt : state.description_en}
                onChange={(e) => handleChange(e.target.value, activeStep === 0 ? 'description_pt' : 'description_en')}
                fullWidth
              />
              <TextField
                className='description-input'
                id='outlined-basic'
                variant='outlined'
                label='Link'
                value={state.link}
                onChange={(e) => handleChange(e.target.value, 'link')}
                fullWidth
              />
              <div className='dates-picker'>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    clearable
                    className='date-picker'
                    ampm={false}
                    label='Inicio'
                    value={state.start_date}
                    onChange={(date) => handleChange(date, 'start_date')}
                    renderInput={(params) => <TextField {...params} />}
                  />
                  <DatePicker
                    clearable
                    className='date-picker'
                    ampm={false}
                    label='Fim'
                    value={state.end_date}
                    minDate={state.start_date}
                    onChange={(date) => handleChange(date, 'end_date')}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </LocalizationProvider>
              </div>
              <div className='local-type-input'>
                <TextField
                  className='local-input'
                  id='outlined-basic'
                  variant='outlined'
                  label='Location'
                  value={activeStep === 0 ? state.location_pt : state.location_en}
                  onChange={(e) => handleChange(e.target.value, activeStep === 0 ? 'location_pt' : 'location_en')}
                />
                <TextField
                  className='type-input'
                  id='outlined-basic'
                  variant='outlined'
                  label='Type'
                  value={activeStep === 0 ? state.type_pt : state.type_en}
                  onChange={(e) => handleChange(e.target.value, activeStep === 0 ? 'type_pt' : 'type_en')}
                />
              </div>
            </div>
          )
          : (
            <div>
              <div className='image-box'>
                <div className='image-box-image' style={{ backgroundImage: state.image ? state.image.preview ? `url(${state.image.preview})` : `url(${process.env.REACT_APP_BACKEND_URL}/${state.image})` : '' }}>
                  <div className='back' />
                  <BiImageAdd size={40} className='image-box-image-icon' />
                  <input className='image-btn' type='file' accept='image/*' onChange={(e) => handleChange({ preview: URL.createObjectURL(e.target.files[0]), data: e.target.files[0] }, 'image')} />
                </div>
              </div>
            </div>
          )}
        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
          <Button
            color='inherit'
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Voltar
          </Button>
          <Box sx={{ flex: '1 1 auto' }} />
          <Button onClick={scheduler.close}>Cancel</Button>
          {activeStep === totalSteps() - 1 ? null : (
            <Button onClick={handleNext} sx={{ mr: 1 }}>
              Próximo
            </Button>
          )}
          {activeStep !== totalSteps() - 1 ? null : (
            <Button onClick={() => handleSubmit()}>
              Confirmar
            </Button>
          )}
        </Box>
      </div>
    </Box>
  );
}
