/* eslint-disable prefer-destructuring */
/* eslint-disable camelcase */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
import { useRef, useEffect, useState } from 'react';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { Form, Button, Spinner } from 'react-bootstrap';
import { RiDeleteBinFill } from 'react-icons/ri';
import { MdModeEdit } from 'react-icons/md';
import useAuth from '../../hooks/useAuth';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import '../../style/Configurations.css';
import Datatable from '../../components/DataTable';
import PasswordModal from '../../components/modals/PasswordModal';
import DeleteModal from '../../components/modals/DeleteModal';
import EventModal from '../../components/modals/EventModal';
import OpportunityModal from '../../components/modals/OpportunityModal';

export default function Profile() {
  const axiosPrivate = useAxiosPrivate();
  const [passwordType, setPasswordType] = useState('password');
  const [user, setUser] = useState('');
  const [emailPassword, setEmailPassword] = useState('');
  const [events, setEvents] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const imageRef = useRef();
  const [image, setImage] = useState(null);
  const { auth, setAuth } = useAuth();

  const [modalInfo, setModalInfo] = useState([]);
  const [modalType, setModalType] = useState('');
  const [showEdit, setEditShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);

  const handleCloseEdit = () => setEditShow(false);
  const handleCloseDelete = () => setDeleteShow(false);

  const handleShowEdit = () => setEditShow(true);
  const handleShowDelete = () => setDeleteShow(true);

  const eventsColumns = [
    {
      name: 'Título',
      selector: (row) => row.title_pt,
      cell: ((row) => (
        <span>
          <b>Título: </b>
          {row.title_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Data',
      selector: (row) => row.start_date,
      cell: ((row) => (
        <span>
          <b>Data: </b>
          {row.start_date}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Localidade',
      selector: (row) => row.location_pt,
      cell: ((row) => (
        <span>
          <b>Localização: </b>
          {row.location_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.editButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  const opportunitiesColumns = [
    {
      name: 'Título',
      selector: (row) => row.title,
      cell: ((row) => (
        <span>
          <b>Título: </b>
          {row.title}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Tipo',
      selector: (row) => row.type,
      cell: ((row) => (
        <span>
          <b>Área de negócio: </b>
          {row.type}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Localização',
      selector: (row) => row.location,
      cell: ((row) => (
        <span>
          <b>Localização: </b>
          {row.location}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.type,
      cell: ((row) => row.editButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.type,
      cell: ((row) => row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  const createFormData = () => {
    const data = new FormData();
    data.append('username', user.username);
    data.append('role', user.role);
    data.append('linkedin', user.linkedin);
    data.append('phone_number', user.phone_number);
    user.image ? data.append('image', user.image) : null;

    return data;
  };

  const saveUser = (e) => {
    e.preventDefault();

    axiosPrivate.patch(`users/${auth.userID}`, createFormData(), {
      headers: { 'Content-Type': 'multipart/form-data' },
      withCredentials: true,
    }).then(
      (u) => {
        if (u.status === 200) {
          setImage(null);
          setUser(u.data[1]);
          setAuth({ ...auth, profileImage: u.data[1].profile_image });
        }
      },
    )
      .catch((err) => console.log(err));

    if (emailPassword !== '') {
      axiosPrivate.patch('sendEmail', JSON.stringify({ emailPassword }), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }).then((password) => setEmailPassword(password.data))
        .catch((err) => console.log(err));
    }
  };

  const changePassword = async (password) => {
    await axiosPrivate.patch(`users/password/${auth.userID}`, JSON.stringify(password), {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then(() => {
        const modal = document.getElementById('passwordModal');
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.getElementsByClassName('modal-backdrop fade show')[0].remove();
        document.getElementById('passwordForm').reset();
        document.getElementById('formPassword').classList.remove('is-invalid');
        document.getElementById('formCurrPassword').classList.remove('is-invalid');
      })
      .catch(() => {
        document.getElementById('formCurrPassword').classList.add('is-invalid');
      });
    document.getElementById('loading').style.display = 'none';
  };

  useEffect(() => {
    axiosPrivate.get(`users/${auth.userID}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        setUser(res.data);

        if (res.data.permissions === 'Admin') {
          axiosPrivate.get('sendEmail', {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          })
            .then((password) => {
              setEmailPassword(password.data);
            })
            .catch((error) => console.log(error));
        }
      })
      .catch((err) => console.log(err));

    axiosPrivate.get(`events/${auth.userID}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        const eventsData = res.data;
        eventsData.forEach((event) => {
          const deleteButton = (
            <button type='button' onClick={() => { setModalInfo(event); setModalType('event'); handleShowDelete(); }} aria-label='delete event' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );
          const editButton = (
            <button type='button' onClick={() => { setModalInfo(event); setModalType('event'); handleShowEdit(); }} aria-label='edit event' className='icon'><MdModeEdit style={{ color: ' #4D4D4D' }} /></button>
          );
          event.deleteButton = deleteButton;
          event.editButton = editButton;
        });
        setEvents(eventsData);
      })
      .catch((err) => console.log(err));

    axiosPrivate.get(`opportunities/user/${auth.userID}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        const opportunitiesData = res.data;
        opportunitiesData.forEach((opportunity) => {
          const deleteButton = (
            <button type='button' onClick={() => { setModalInfo(opportunity); setModalType('opportunity'); handleShowDelete(); }} aria-label='delete event' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );
          const editButton = (
            <button type='button' onClick={() => { setModalInfo(opportunity); setModalType('opportunity'); handleShowEdit(); }} aria-label='edit event' className='icon'><MdModeEdit style={{ color: ' #4D4D4D' }} /></button>
          );
          opportunity.deleteButton = deleteButton;
          opportunity.editButton = editButton;
        });
        setOpportunities(opportunitiesData);
      })
      .catch((err) => console.log(err));
  }, []);

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };

  /* eslint-disable react/function-component-definition */
  const ModalContent = () => {
    if (modalType === 'event') {
      const deleteEvent = () => {
        try {
          axiosPrivate.delete(`events/${modalInfo.event_id}`, {
            withCredentials: true,
          });
        } catch (e) {
          console.log(e);
        }
        window.location.reload(true);
      };

      const editEvent = (type_, newEvent, formData = null) => {
        if (formData === null) {
          const {
            title_pt, title_en, description_pt, description_en, location_pt, location_en, start_date, end_date, user_id, type_pt, type_en, link,
          } = newEvent;
          axiosPrivate.patch(`${type_}/${modalInfo.event_id}`, JSON.stringify({
            title_pt, title_en, description_pt, description_en, location_pt, location_en, start_date, end_date, user_id, type_pt, type_en, link,
          }), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }).then(() => window.location.reload(true))
            .catch((e) => console.log(e));

          return;
        }
        axiosPrivate.patch(`${type_}/${modalInfo.event_id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        }).then(() => window.location.reload(true))
          .catch((e) => console.log(e));
      };

      return showEdit ? <EventModal show={showEdit} onHide={handleCloseEdit} event={modalInfo} callback={editEvent} buttonText='Editar' />
        : <DeleteModal show={showDelete} onHide={handleCloseDelete} text='o evento' deleteFunction={deleteEvent} />;
    }

    if (modalType === 'opportunity') {
      const deleteOpportunity = () => {
        try {
          axiosPrivate.delete(`opportunities/${modalInfo.opportunity_id}`, {
            withCredentials: true,
          });
        } catch (e) {
          console.log(e);
        }
        window.location.reload(true);
      };

      const editOpportunity = (newOpportunity, formData = null) => {
        if (formData === null) {
          axiosPrivate.patch(`opportunities/${modalInfo.opportunity_id}`, JSON.stringify(newOpportunity), {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
          }).then(() => window.location.reload(true))
            .catch((e) => console.log(e));
          return;
        }
        axiosPrivate.patch(`opportunities/${modalInfo.opportunity_id}`, formData, {
          withCredentials: true,
          headers: { 'Content-Type': 'multipart/form-data' },
        }).then(() => window.location.reload(true))
          .catch((e) => console.log(e));
      };

      return showEdit ? <OpportunityModal show={showEdit} onHide={handleCloseEdit} opportunity={modalInfo} callback={editOpportunity} buttonText='Editar' />
        : <DeleteModal show={showDelete} onHide={handleCloseDelete} text=' a oportunidade' deleteFunction={deleteOpportunity} />;
    }

    return null;
  };

  if (!user) {
    return (
      <Spinner variant='primary' />
    );
  }

  return (
    <div id='profile'>
      <div className='row w-100'>
        <ModalContent />
        <div className='col d-flex align-items-start align-items-center'>
          <span className='mobinov-title mobinov-grey-color'>Informações da Conta: </span>
        </div>
      </div>
      <div className='row my-4'>
        <div className='col-auto d-flex align-items-center justify-content-start'>
          <img alt='profile' className='profile-picture my-3' src={image ? image.preview : `${process.env.REACT_APP_BACKEND_URL}/${user.profile_image}`} />
        </div>
        <div className='col-auto d-flex align-items-center justify-content-start'>
          <button type='button' className='choose-pic-btn ms-3 px-2 rounded' onClick={() => imageRef.current.click()}>Escolher Fotografia</button>
          <input ref={imageRef} onChange={(e) => { setImage({ preview: URL.createObjectURL(e.target.files[0]), data: e.target.files[0] }); user.image = e.target.files[0]; }} multiple={false} type='file' accept='image/*' hidden />
        </div>
        <div className='col-auto d-flex align-items-center justify-content-start'>
          <button type='button' className='change-pass-btn px-2 rounded' data-toggle='modal' data-target='#passwordModal'>Alterar Password</button>
        </div>
      </div>
      <Form className='needs-validation' onSubmit={saveUser}>
        <div className='row w-75'>
          <Form.Group className='mb-3 col-md-6 validate-me'>
            <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Nome </Form.Label>
            <Form.Control type='text' required value={user.username} onChange={(e) => setUser((u) => ({ ...u, username: e.target.value }))} />
            <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
          </Form.Group>

          { user.enterprise && (
            <Form.Group className='mb-3 col-md-6'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Empresa </Form.Label>
              <Form.Control id='enterprise' disabled name='enterprise' defaultValue={user.enterprise} />
            </Form.Group>
          )}
        </div>

        <div className='row w-75'>
          <Form.Group className='mb-3 col-md-6 validate-me'>
            <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Cargo </Form.Label>
            <Form.Control type='text' required value={user.role} onChange={(e) => setUser((u) => ({ ...u, role: e.target.value }))} />
            <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className='mb-3 col-md-6'>
            <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Email </Form.Label>
            <Form.Control type='text' disabled defaultValue={user.email} />
          </Form.Group>
        </div>

        <div className='row w-75'>
          <Form.Group className='mb-3 col-md-6'>
            <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Linkedin </Form.Label>
            <Form.Control type='text' value={user.linkedin} onChange={(e) => setUser((u) => ({ ...u, linkedin: e.target.value }))} />
          </Form.Group>

          <Form.Group className='mb-3 col-md-6 validate-me'>
            <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Número de telemóvel </Form.Label>
            <Form.Control type='text' maxLength={13} value={user.phone_number} onChange={(e) => setUser((u) => ({ ...u, phone_number: e.target.value }))} />
            <Form.Control.Feedback type='invalid'>Preencha o campo com no máximo 13 digitos</Form.Control.Feedback>
          </Form.Group>

          {auth.email === 'isabel.oliveira@mobinov.pt' && (
            <Form.Group className='mb-3 col-md-6 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Password do email </Form.Label>
              <div className='input-group'>
                <Form.Control type={passwordType} required defaultValue={emailPassword} onChange={(e) => { setEmailPassword(e.target.value); }} />
                <div className='input-group-btn'>
                  <button type='button' className='btn btn-outline-primary' onClick={togglePassword}>
                    { passwordType === 'password' ? <AiFillEye /> : <AiFillEyeInvisible /> }
                  </button>
                </div>
              </div>
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>
          )}
        </div>

        <div className='text-end'>
          <Button className='btn-primary mobinov-subtitle mt-3' type='submit'>
            Guardar Alterações
          </Button>
        </div>
      </Form>

      <div className='row w-100'>
        <div className='col d-flex align-items-start align-items-center'>
          <span className='mobinov-title mobinov-grey-color'>Os meus eventos:  </span>
        </div>
      </div>
      <div className='my-3 mr-4'>
        <Datatable columns={eventsColumns} data={events} text='title_pt' searchbar='título' paginationB searchable />
      </div>
      <div className='row w-100'>
        <div className='col d-flex align-items-start align-items-center'>
          <span className='mobinov-title mobinov-grey-color mt-4'>As minhas oportunidades de negócio: </span>
        </div>
      </div>
      <div className='my-3 mr-4'>
        <Datatable columns={opportunitiesColumns} data={opportunities} text='title' searchbar='título' paginationB searchable />
      </div>

      <PasswordModal callback={changePassword} />
    </div>
  );
}
