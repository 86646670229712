import { useState, useEffect, useRef } from 'react';
import { Row } from 'react-bootstrap';
import ClusterCarousel from './ClusterCarousel';

/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
export default function ClusterSubRow({ cluster }) {
  const [selectedCluster, setSelectedCluster] = useState(null);
  const carouselRef = useRef(null);

  useEffect(() => {
    setSelectedCluster(null);
  }, [cluster]);

  useEffect(() => {
    selectedCluster && carouselRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [selectedCluster]);

  function changeCluster(value) {
    if (selectedCluster?.logo === value.logo) {
      setSelectedCluster(null);
    } else {
      setSelectedCluster(value);
    }
  }

  return (
    <>
      <Row className='g-0 d-flex align-items-center justify-content-center'>
        {
          Object.entries(cluster).map(([, value]) => (
            <div
              key={value.logo}
              className={`d-flex flex-column align-items-center justify-content-center cluster-card ${(value?.images || value?.pdf) && ' cluster-card-hover'} ${selectedCluster?.logo === value.logo && 'cluster-card-selected'}`}
              onClick={() => (value?.images || value?.pdf) && changeCluster(value)}
            >
              <img alt='Logo' className='logo-images' src={value.logo} />
            </div>
          ))
        }
      </Row>
      {(selectedCluster?.images || selectedCluster?.pdf)
        && (
        <div ref={carouselRef}>
          <ClusterCarousel key={selectedCluster.logo} images={selectedCluster?.images} pdf={selectedCluster?.pdf} />
        </div>
        )}
    </>
  );
}
