import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import useRefreshToken from '../../hooks/useRefreshToken';
import useAuth from '../../hooks/useAuth';

export default function PersistLogin() {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuth();

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        // eslint-disable-next-line
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // Refresh token if auth isn't set
    !auth?.accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

    // eslint-disable-next-line no-return-assign
    return () => (isMounted = false);
  }, []);

  // TODO change for something like a spinner when loading
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      { !persist
        ? <Outlet />
        : !isLoading && <Outlet />}
    </>
  );
}
