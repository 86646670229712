import { useNavigate, Outlet } from 'react-router-dom';
import { useEffect } from 'react';
import useAuth from '../../hooks/useAuth';

function RedirectAuth() {
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.permissions) navigate('/');
  }, []);

  return <Outlet />;
}

export default RedirectAuth;
