import { Form, Button } from 'react-bootstrap';
import '../../style/Modal.css';
import { useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import useAuth from '../../hooks/useAuth';

export default function NewOpportunity({
  buttonText,
}) {
  const [title, setNewTitle] = useState('');
  const [description, setNewDescription] = useState('');
  const [location, setNewLocation] = useState('');
  const [type, setNewType] = useState('');
  const [date, setNewDate] = useState('');
  const [validated, setValidated] = useState(false);
  const [image, setNewPDF] = useState('');
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();

  const addOpportunity = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    }

    const files = [...image];
    if (form.checkValidity() !== false) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('image', files[i]);
      }
      formData.append('title', title);
      formData.append('type', type);
      formData.append('location', location);
      formData.append('date', date);
      formData.append('description', description);
      formData.append('user_id', auth.userID);
      try {
        axiosPrivate.post('opportunities', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((err) => console.log(err));
      } catch (msg) {
        console.log(msg);
      }
    }
    setValidated(true);
  };

  const handleFileSelect = (e) => {
    setNewPDF(e);
  };

  return (
    <div className='modal fade' id='add-opportunity' tabIndex='-1' role='dialog' aria-labelledby='addModalTitle' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='modal-content py-4 modal-background'>
          <div className='modal-body d-flex justify-content-center'>
            <div className='row w-70'>
              <h2 className='mobinov-formlabel mobinov-grey-color mb-4 fw-bold'> Adicionar Oportunidade </h2>
              <Form noValidate validated={validated} className='needs-validation' onSubmit={addOpportunity}>
                <Form.Group className='mb-3'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'>Nome*</Form.Label>
                  <Form.Control value={title} type='text' required onChange={(e) => setNewTitle(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Por favor ensira um nome.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'>Tipo</Form.Label>
                  <Form.Control value={type} type='text' onChange={(e) => setNewType(e.target.value)} />
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'>Descrição*</Form.Label>
                  <Form.Control value={description} as='textarea' required style={{ height: '150px' }} onChange={(e) => setNewDescription(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Por favor ensira uma descrição.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'>Data limite*</Form.Label>
                  <Form.Control type='date' onChange={(e) => setNewDate(e.target.value)} />
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'>Localização*</Form.Label>
                  <Form.Control value={location} required type='text' onChange={(e) => setNewLocation(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>Por favor ensira uma localização.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3'>
                  <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'> Adicionar ficheiros </Form.Label>
                  <Form.Control type='file' accept='application/pdf' onChange={(e) => handleFileSelect(e.target.files)} multiple />
                </Form.Group>

                <div className='row d-flex justify-content-center mt-4'>
                  <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                    {buttonText}
                  </Button>
                </div>

              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
