/* eslint-disable camelcase */
import { Modal, Form, Button } from 'react-bootstrap';
import '../../style/Modal.css';
import { useState } from 'react';

export default function projectModal({
  show, onHide, project, callback, buttonText,
}) {
  const [title_pt, setTitle_pt] = useState(project ? project.title_pt : '');
  const [title_en, setTitle_en] = useState(project ? project.title_en : '');
  const [link, setLink] = useState(project ? project.link : '');
  const [sheet_link, setSheetLink] = useState(project ? project.sheet_link : '');
  const [complete, setComplete] = useState(project ? project.complete : true);
  const [projectImage, setProjectImage] = useState(null);

  const submition = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else if (projectImage == null) {
      callback('projects', {
        title_pt, title_en, complete, link, sheet_link,
      });
    } else {
      const formData = new FormData();
      formData.append('title_pt', title_pt);
      formData.append('title_en', title_en);
      formData.append('link', link);
      formData.append('sheet_link', sheet_link);
      formData.append('complete', complete);
      formData.append('image', projectImage);
      callback('projects', null, formData);
    }
  };

  const handleFileSelect = (event) => {
    setProjectImage(event.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onHide} centered animation>
      <div className='modal-background rounded'>
        <Modal.Body className='mx-5 my-3'>
          <Form className='needs-validation' onSubmit={submition}>
            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Nome PT* </Form.Label>
              <Form.Control type='text' required value={title_pt} onChange={(e) => { setTitle_pt(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Nome EN* </Form.Label>
              <Form.Control type='text' required value={title_en} onChange={(e) => { setTitle_en(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Link* </Form.Label>
              <Form.Control type='text' required value={link} onChange={(e) => { setLink(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Ficha do projeto (link) </Form.Label>
              <Form.Control type='text' value={sheet_link} onChange={(e) => { setSheetLink(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Estado* </Form.Label>
              <Form.Select id='complete' name='complete' value={complete ? 'Concluído' : 'Aprovado'} onChange={(e) => { e.target.value === 'Aprovado' ? setComplete(false) : setComplete(true); }}>
                <option value='Concluído'>Concluído</option>
                <option value='Aprovado'>Aprovado</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Imagem </Form.Label>
              <Form.Control type='file' accept='image/*' required={project == null} onChange={handleFileSelect} />
            </Form.Group>

            <div className='row d-flex justify-content-center mt-4'>
              <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                {buttonText}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
}
