import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import axios from '../api/axios';

import '../style/Projects.css';

export default function Projects() {
  const { t, i18n } = useTranslation();

  const [approvedProjects, setApprovedProjects] = useState([]);
  const [concludedProjects, setConcludedProjects] = useState([]);

  const getProjects = async () => {
    axios
      .get('/projectsReader', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((res) => {
        const approved = [];
        const concluded = [];
        const projects = res.data;

        for (let index = 0; index < projects.length; index++) {
          const proj = projects[index];
          if (proj.complete) {
            concluded.push(proj);
          } else {
            approved.push(proj);
          }
        }

        setApprovedProjects(approved);
        setConcludedProjects(concluded);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <Container className='g-0 justify-content-center px-md-5 px-2'>
      <Row className='g-0'>
        <Col className='text-center g-0'><h1 className='mobinov-header mobinov-grey-color mb-4'>{t('projects')}</h1></Col>
      </Row>
      <Row className='g-0 my-4'>
        <Col className='text-center g-0'><h1 className='mobinov-header mobinov-blue-color my-4'>{t('projectsApproved')}</h1></Col>
      </Row>
      <Row className='g-0'>
        {approvedProjects.map((proj) => (
          <Col key={proj.project_id} className='g-0 mb-5 px-4' lg={6}>
            <div className='card card-project text-white' style={{ backgroundImage: `linear-gradient(to bottom, rgba(38, 38, 38, 0), rgba(38, 38, 38, 0), rgba(38, 38, 38, 0.4)), url(${process.env.REACT_APP_BACKEND_URL}/${proj.image})` }}>
              <div className='card-img-overlay d-flex justify-content-between align-items-end'>
                <p className='card-text m-0 mobinov-text'>{i18n.language === 'pt' ? proj.title_pt : proj.title_en}</p>
                <div>
                  {proj.sheet_link && <p className='card-text mobinov-text-nojustify'><a className='text-white' href={`${proj.sheet_link}`}>{t('viewProjectFile')}</a></p>}
                  <p className='card-text text-end mobinov-text-nojustify'><a className='text-white' href={`${proj.link}`}>{t('readMore')}</a></p>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <Row className='g-0 my-4 mt-5'>
        <Col className='text-center g-0'><h1 className='mobinov-header mobinov-blue-color my-4'>{t('projectsFinished')}</h1></Col>
      </Row>
      <Row className='g-0'>
        {concludedProjects.map((proj) => (
          <Col key={proj.project_id} className='g-0 mb-5 px-4' lg={6}>
            <div className='card card-project text-white' style={{ backgroundImage: `linear-gradient(to bottom, rgba(38, 38, 38, 0), rgba(38, 38, 38, 0), rgba(38, 38, 38, 0.4)), url(${process.env.REACT_APP_BACKEND_URL}/${proj.image})` }}>
              <div className='card-img-overlay d-flex justify-content-between align-items-end'>
                <p className='card-text m-0 mobinov-text'>{i18n.language === 'pt' ? proj.title_pt : proj.title_en}</p>
                <div>
                  {proj.sheet_link && <p className='card-text mobinov-text-nojustify'><a className='text-white' href={`${proj.sheet_link}`}>{t('viewProjectFile')}</a></p>}
                  <p className='card-text mobinov-text-nojustify'><a className='text-white' href={`${proj.link}`}>{t('readMore')}</a></p>
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>
      <Row className='g-0 my-4 mt-5'>
        <Col className='text-center g-0'><h1 className='mobinov-header mobinov-blue-color my-4'>{t('partnerships')}</h1></Col>
      </Row>
      <Row className='g-0 align-items-center text-center mb-5'>
        <Col md={4} className='g-0 mb-sm-3 my-3'>
          <img alt='' className='img-responsive' src='/images/projects/parcerias1.png' />
        </Col>
        <Col md={4} className='g-0 mb-sm-3 my-3'>
          <img alt='' className='img-responsive' src='/images/projects/parcerias2.png' />
        </Col>
        <Col md={4} className='g-0 mb-sm-3 my-3'>
          <img alt='' className='img-responsive' src='/images/projects/parcerias3.png' />
        </Col>
      </Row>
      <Row className='g-0 pt-3'>
        <Col className='text-center g-0'>
          <Button href='/become-member' className='mobinov-subtitle mobinov-blue-bg w-auto px-3 mt-4' size='lg'>{t('becomeMember')}</Button>
        </Col>
      </Row>
    </Container>
  );
}
