import Carousel from 'react-multi-carousel';
import { Row } from 'react-bootstrap';
import ButtonGroup from './ButtonGroup';

export default function ClusterCarousel({ images, pdf }) {
  const imagesLength = images ? images.length : 0;
  const size = imagesLength + (pdf ? 1 : 0);
  const responsive = {
    desktop: {
      breakpoint: { max: 4000, min: 1480 },
      items: size > 2 ? 3 : size,
    },
    tablet: {
      breakpoint: { max: 1480, min: 1090 },
      items: size > 1 ? 2 : 1,
    },
    mobile: {
      breakpoint: { max: 1090, min: 0 },
      items: 1,
    },
  };

  return (
    <Row className='position-relative g-0 mb-5'>
      <Carousel
        arrows={false}
        swipeable
        draggable={false}
        partialVisible={false}
        autoPlay={false}
        shouldResetAutoplay={false}
        slidesToSlide={1}
        ssr
        itemClass='cluster-carousel-item'
        responsive={responsive}
        renderButtonGroupOutside
        customButtonGroup={window.innerWidth < 620 ? <MobilePlaceHolder /> : <ButtonGroup size={size} />}
      >
        {images && images.map((image) => (
          <img key={image} src={image} alt='Cluster' className='cluster-carousel-image' />
        ))}
        {pdf && (
          <a href={pdf} target='_blank' rel='noopener noreferrer'>
            <img src='/images/pdf.svg' width={128} height={128} alt='Cluster PDF' />
          </a>
        )}
      </Carousel>
    </Row>
  );
}

function MobilePlaceHolder(
  {
    // eslint-disable-next-line no-unused-vars
    next, previous, goToSlide, size, ...rest
  },
) {
  return (
    <div />
  );
}
