import { useTranslation } from 'react-i18next';
import {
  Button, Row, Col, Container, Form,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from '../api/axios';

export default function RecoverPassword() {
  const { t } = useTranslation();

  const [email, setEmail] = useState('');
  const [showEmailError, setShowEmailError] = useState(false);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);

  useEffect(() => {
    setShowEmailError(false);
  }, [email]);

  const recoverPassword = async (e) => {
    setShowEmailSuccess(false);
    setShowEmailError(false);
    e.preventDefault();

    axios
      .post('/recoverPassword', JSON.stringify({ email }), {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then(() => {
        setShowEmailSuccess(true);
      })
      .catch(() => {
        setShowEmailError(true);
      });
  };

  return (
    <Container className='g-0'>
      <h1 className='mobinov-header mobinov-grey-color text-center mb-5'>{t('recoverPassword')}</h1>

      <Row className='g-0 justify-content-center'>
        <Col className='col-lg-4 p-4 mx-sm-0 mx-3 rounded mobinov-grey-bg shadow'>
          <Form onSubmit={recoverPassword}>
            <p className='text-start'>{t('recoverPasswordText')}</p>

            <Form.Group className='mb-3' controlId='formBasicEmail'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Email</Form.Label>
              <Form.Control type='text' placeholder='Enter your email' value={email} onChange={(e) => setEmail(e.target.value)} />
            </Form.Group>
            {showEmailError && (
              <div className='alert alert-danger py-1'>
                {t('errorRecoverPassword')}
              </div>
            )}

            {showEmailSuccess ? (
              <div className='alert alert-success py-1'>
                {t('successRecoverPassword')}
              </div>
            ) : (
              <div className='text-center'>
                <Button className='mobinov-subtitle mt-3 px-5' variant='primary' type='submit'>
                  {t('recoverPasswordSubmit')}
                </Button>
              </div>
            )}

            <p className='text-center mt-4'><a href='/login' className='link'>{t('backToLogin')}</a></p>
          </Form>
        </Col>
      </Row>

      <div className='text-center mt-5'>
        <Button variant='primary' href='/become-member' className='mobinov-subtitle mobinov-blue-bg w-auto px-3 mt-5'>
          {t('becomeMember')}
        </Button>
      </div>
    </Container>
  );
}
