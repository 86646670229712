import { Modal, Form, Button } from 'react-bootstrap';
import '../../style/Modal.css';
import { useState } from 'react';

export default function OpportunityModal({
  show, onHide, opportunity, callback, buttonText,
}) {
  const [title, setTitle] = useState(opportunity ? opportunity.title : '');
  const [description, setDescription] = useState(opportunity ? opportunity.description : '');
  const [location, setLocation] = useState(opportunity ? opportunity.location : '');
  const [date, setDate] = useState(opportunity ? opportunity.date : '');
  const [type, setType] = useState(opportunity ? opportunity.type : '');

  const [file, setFile] = useState(null);

  const submition = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (file !== null) {
      const files = [...file];
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append('image', files[i]);
      }
      formData.append('title', title);
      formData.append('type', type);
      formData.append('location', location);
      formData.append('date', date);
      formData.append('description', description);
      callback(null, formData);
    } else {
      callback({
        title, description, location, date, type,
      });
    }
  };

  const handleFileSelect = (e) => {
    setFile(e);
  };

  return (
    <Modal show={show} onHide={onHide} centered animation>
      <div className='modal-background rounded'>
        <Modal.Body className='mx-5 my-3'>
          <Form className='needs-validation' onSubmit={submition}>
            <Form.Group className='mb-3 validate-me' controlId='formBasicSubject'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Nome </Form.Label>
              <Form.Control type='text' required value={title} onChange={(e) => { setTitle(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3 validate-me' controlId='formBasicName'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Tipo </Form.Label>
              <Form.Control type='text' required value={type} onChange={(e) => { setType(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3 validate-me' controlId='formBasicName'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Descrição </Form.Label>
              <Form.Control as='textarea' required value={description} style={{ height: '150px' }} onChange={(e) => { setDescription(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3 validate-me' controlId='formBasicName'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Data limite </Form.Label>
              <Form.Control type='date' required value={date} onChange={(e) => { setDate(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3 validate-me' controlId='formBasicName'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Localização </Form.Label>
              <Form.Control type='text' required value={location} onChange={(e) => { setLocation(e.target.value); }} />
              <Form.Control.Feedback type='invalid'>Preencha o campo</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className='mb-3'>
              <Form.Label className='mobinov-formlabel mobinov-grey-color fw-bold'> Adicionar ficheiros </Form.Label>
              <Form.Control type='file' accept='application/pdf' onChange={(e) => handleFileSelect(e.target.files)} multiple />
            </Form.Group>

            <div className='row d-flex justify-content-center mt-4'>
              <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                {buttonText}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
}
