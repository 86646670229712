/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import { React, useState } from 'react';
import { BiSearch } from 'react-icons/bi';
import DataTable from 'react-data-table-component';
import DataTableExtensions from 'react-data-table-component-extensions';
import 'react-data-table-component-extensions/dist/index.css';

export default function Datatable({
  columns, data, text, paginationB, searchable, searchbar,
}) {
  const [input, setInput] = useState('');

  const search = (rows) => rows.filter((row) => row[text].toLowerCase().indexOf(input.toLowerCase()) > -1);
  const string = `Pesquisa por ${searchbar}`;

  return (
    <>
      <div className='d-flex flex-row-reverse'>
        <BiSearch size='30px' />
        <span className='mx-3'><input type='text' placeholder={string} value={input} onChange={(e) => setInput(e.target.value)} /></span>
      </div>
      <DataTableExtensions
        columns={columns}
        data={searchable ? search(data) : data}
        export={false}
        print={false}
        filter={false}
      >
        <DataTable
          noTableHead
          columns={columns}
          data={searchable ? search(data) : data}
          striped
          responsive
          pagination={paginationB}
          highlightOnHover
        />
      </DataTableExtensions>
    </>
  );
}
