import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import NewOpportunity from '../../components/modals/NewOpportunity';
import Opportunity from '../../components/Opportunity';
import '../../style/Opportunities.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function Opportunities() {
  const axiosPrivate = useAxiosPrivate();
  const [opportunities, setOportunity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 20;

  const getOpportunities = () => {
    setLoading(true);

    axiosPrivate
      .get('/opportunities/paginated', { params: { offset, limit } })
      .then((res) => {
        if (!total) setTotal(res.data.count);
        setOportunity((prevOpportunities) => [...prevOpportunities, ...res.data.rows]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && opportunities.length < total) {
        setOffset((prevOffset) => prevOffset + limit);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, offset]);

  useEffect(() => {
    getOpportunities();
  }, [offset]);

  return (
    <div id='opportunities'>
      <div className='row w-100'>
        <div className='col d-flex align-items-start align-items-center'>
          <span className='mobinov-title mobinov-grey-color'>Oportunidades de Negócio</span>
        </div>
        <div className='col d-flex justify-content-end align-items-start'>
          <button type='button' className='mb-3 py-2 mobinov-subtitle mobinov-blue-bg w-auto btn btn-primary' aria-label='add event' data-toggle='modal' data-target='#add-opportunity'> Adicionar Oportunidade</button>
        </div>
        <NewOpportunity buttonText='Adicionar' />
      </div>
      <div id='opportunities-list mt-2'>
        {opportunities.map((opp, key) => (
          <div key={opp.opportunity_id} ref={(opportunities.length === key + 1) ? lastElementRef : null}>
            <Opportunity
              opportunity={opp}
              place={key + 1}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
