import { Form, Button, Modal } from 'react-bootstrap';
import '../../style/Modal.css';
import { useState } from 'react';

export default function MembersModal({
  show, onHide, member, buttonText, callback,
}) {
  const [name, setName] = useState(member ? member.name : '');
  const [link, setLink] = useState(member ? member.link : '');
  const [type, setType] = useState(member ? member.type : 'Associações');
  const [image, setImage] = useState(null);

  const submition = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else {
      const formData = new FormData();
      formData.append('name', name);
      formData.append('type', type);
      formData.append('link', link);
      formData.append('image', image);

      callback('members', null, formData);
    }
  };

  const handleFileSelect = (e) => {
    setImage(e.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onHide} centered animation>
      <div className='modal-background rounded'>
        <Modal.Body className='mx-5 my-3'>
          <Form className='needs-validation' onSubmit={submition}>
            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Nome </Form.Label>
              <Form.Control required type='text' value={name} onChange={(e) => { setName(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Link </Form.Label>
              <Form.Control required type='text' value={link} onChange={(e) => { setLink(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Tipo </Form.Label>
              <Form.Select id='type' name='type' value={type} onChange={(e) => { setType(e.target.value); }}>
                <option value='Associações'>Associações</option>
                <option value='Empresas'>Empresas</option>
                <option value='Construtores'>Construtores</option>
                <option value='Ensino Superior'>Ensino Superior</option>
                <option value='Entidades STC'>Entidades STC</option>
              </Form.Select>
            </Form.Group>

            <Form.Group className='mb-3 validate-me validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Imagem </Form.Label>
              <Form.Control type='file' accept='image/*' required onChange={handleFileSelect} />
            </Form.Group>

            <div className='row d-flex justify-content-center mt-4'>
              <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                {buttonText}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
}
