const LOCALES_PT = {
  contactUs: 'Contacte-nos',
  calendar: 'Calendário',
  subjectForm: 'Assunto',
  nameForm: 'Nome',
  phoneForm: 'Telefone',
  messageForm: 'Mensagem',
  termsForm: 'Concordo com os Termos de Utilização e Política de Privacidade e declaro ter lido a informação que é requerida de acordo com o Artigo 13 da GDPR.',
  emailValidation: 'Por favor insira um email válido.',
  fieldValidation: 'Por favor preencha o campo em falta.',
  messageValidation: 'Por favor insira uma messagem.',
  mustAgree: 'Tem de concordar antes de submeter.',
  moreEvents: 'Mais eventos',
  sendEmail: 'Enviar email',
  mobinovHeadquarters: 'Sede Mobinov',
  mobinovLisbon: 'Delegação de Lisboa',
  membersPartners: 'Membros e Parceiros',
  associations: 'Associações',
  entities: 'Entidades STC',
  education: 'Ensino superior',
  enterprises: 'Empresas',
  builders: 'Construtores',
  faqQuestion1: 'Qual o número de postos de trabalho na indústria automóvel?',
  faqAnswer1: '12.7 M de europeus trabalham na Indústria Automóvel (direta e indiretamente) representando 6.6% de todos os postos de trabalho da UE.',
  faqQuestion2: 'Qual o volume de negócios gerado pela indústria automóvel na UE?',
  faqAnswer2: 'O volume de negócios gerado pela indústria automóvel representa mais de 8% do PIB da UE.',
  faqQuestion3: 'Quantas viaturas foram produzidas em Portugal?',
  faqAnswer3: 'Foram produzidas em Portugal no ano de 2020 um total de 264.000 viaturas.',
  faqQuestion4: 'Quantos modelos de viaturas são produzidos em Portugal?',
  faqAnswer4: 'Até ao final de 2019 eram produzidos 8 modelos de 4 diferentes construtores.',
  becomeMember: 'Torne-se Membro',
  moreNews: 'Mais Notícias',
  fullSchedule: 'Agenda Completa',
  moreInfo: 'Mais Informação',
  cofinanced: 'Cofinanciado por:',
  socialDesignation: 'Designação social',
  enterpriseAddress: 'Endereço da empresa',
  postalCode: 'Código postal',
  locality: 'Localidade',
  cc: 'cc',
  enterpriseDescription: 'Descrição da empresa',
  contactName: 'Nome do contacto',
  list: 'Lista',
  logo: 'Logótipo',
  postalCodeValidation: 'Por favor insira um código postal válido.',
  ccValidation: 'Por favor insira um cc válido.',
  rememberMe: 'Lembre-se de mim',
  forgotPassword: 'Esqueceu a senha?',
  errorLogin: 'Combinação email/password errada',
  news: 'Notícias',
  contacts: 'Contactos',
  reservedArea: 'Área Reservada',
  members: 'Membros',
  services: 'Serviços',
  projects: 'Projetos',
  projectsDescription1: 'A MOBINOV tem desenvolvido vários projetos de forma direta ou com parceiros nacionais ou estrangeiros.',
  projectsDescription2: 'Estes projetos têm o objetivo de ampliar a difusão nacional e no estrangeiro do Setor da Indústria Automóvel de Portugal. Temos ainda como objetivos o aumento da competividade, através de projetos de investigação, de inovação tecnológica ou outros.',
  projectsApproved: 'Aprovados',
  projectsFinished: 'Concluídos',
  partnerships: 'Parcerias',
  readMore: 'Ler Mais',
  viewProjectFile: 'Ver Ficha de Projeto',
  gallery: 'Galeria',
  statistics: 'Estatísticas',
  backToLogin: 'Voltar ao Login',
  sendAnotherEmail: 'Enviar outro email',
  resetPassword: 'Alteraração da Password',
  resetPasswordSubmit: 'Alterar Password',
  resetPasswordInvalidPass: 'Passwords Inválidas',
  newPassword: 'Nova Password',
  repeatNewPassword: 'Repita a Nova Password',
  resetPasswordSuccess: 'A sua password foi reposta com sucesso, por favor tente efetuar novamente o login.',
  resetPasswordFailure: 'Problema a recuperar a password. Por favor envie outro email.',
  recoverPassword: 'Recuperação de Password',
  recoverPasswordSubmit: 'Recuperar Password',
  recoverPasswordText: 'Esqueceu-se dos seus dados de acesso? Digite seu endereço de e-mail, e receberá as instruções para recuperar a sua conta.',
  errorRecoverPassword: 'Email inválido',
  successRecoverPassword: 'Email enviado com sucesso',
  errorEmailSent: 'Erro ao enviar email',
  mobinovHistory: 'História da MOBINOV',
  whoarewe1: 'A MOBINOV é uma plataforma agregadora de conhecimento e competência no âmbito da indústria automóvel, com o objetivo de promover uma crescente valorização da competitividade e da internacionalização do setor. Por outro lado, visa contribuir para transformar Portugal numa referência na investigação, inovação, conceção, desenvolvimento, fabrico e teste de produtos e serviços da indústria do setor automóvel.',
  whoarewe2: 'A MOBINOV apoia os seus membros em vários processos de inovação, desenvolvimento e aceleração a nível nacional e internacional. Ao tornar-se membro do cluster, poderá beneficiar de uma vasta gama de serviços dedicados à indústria automóvel. Encontre abaixo a lista de serviços que a MOBINOV presta a todos os seus membros.',
  whoarewe: 'Quem Somos',
  vision: 'Visão',
  visionText: 'Uma Indústria Automóvel competitiva e reconhecida ao nível do desenvolvimento de soluções tecnológicas e da industrialização de componentes, módulos, sistemas e veículos.',
  mission: 'Missão',
  missionText: 'Promover de forma sustentável o crescimento e a competitividade da Indústria Automóvel nacional através de uma maior cooperação e coordenação entre empresas, associações, organismos da administração pública e entidades do SI&I.',
  chart: 'Organograma',
  statute: 'Estatutos',
  ourServices: 'Os nossos Serviços',
  servicesDescription1: 'A MOBINOV apoia os seus membros em vários processos de inovação, desenvolvimento e aceleração a nível nacional e internacional. Ao tornar-se membro do cluster, poderá beneficiar de uma vasta gama de serviços dedicados à indústria automóvel.',
  servicesDescription2: 'Encontre abaixo a lista de serviços que a MOBINOV presta a todos os seus membros.',
  service1: 'Aproximar fabricantes e fornecedores de componentes',
  service2: 'Acesso à Área Reservada',
  service3: 'Acesso a estudos atualizados',
  service4: 'Participação em Grupos de Trabalhos',
  service5: 'Apoio na preparação de Calls Nacionais e Europeias',
  service6: 'Integração em Projetos Nacionais e Europeus',
  service7: 'Apoio na integração e constituição de consórcios europeus',
  service8: 'Networking',
  service9: 'Visibilidade nacional e internacional',
  service10: 'Newsletter semanal',
  service11: 'Participação na construção de políticas públicas para o cluster',
  service12: 'Workshops e seminários temáticos',
  service13: 'Lobby',
  clusterTitle: 'Cluster Automóvel - Indústria Automóvel em Portugal',
  clusterConstructors: 'Construtores',
  clusterComponents: 'Fabricantes de Componentes',
  clusterProviders: 'Outros fornecedores',
  clusterEnterprises: 'Associações empresariais',
  clusterUniversities: 'Instituições de Ensino Superior',
  clusterInvestigation: 'Centros de Investigação',
  clusterKnowledge: 'Entidades de Transferência de Conhecimento',
  clusterProfessional: 'Centros de Formação Profissional',
  knowledgeChain: 'Cadeia de conhecimento',
  investigationInnovation: 'Investigação & Inovação',
  internationalSupport: 'Suporte à Internacionalização',
  knowledgeChainText: 'Esta é uma Plataforma agregadora de conhecimento entre toda a Indústria Automóvel Nacional.',
  investigationInnovationText: 'Promovemos a Investigação & Inovação no sector aumentando o nível de competitividade da Indústria Automóvel Portuguesa.',
  internationalSupportText: 'Criar um ambiente de promoção e apoio à internacionalização da Indústria Automóvel Nacional.',
  homepage1: 'Uma Plataforma Agregadora de Conhecimento e Competências',
  homepage2: 'Conheça a MOBINOV, o Cluster Automóvel de Portugal',
  homepageVideoDesc: 'A MOBINOV caracteriza-se como uma plataforma agregadora de conhecimento e competência no âmbito da indústria do setor automóvel, para promover uma crescente valorização da competitividade e da internacionalização do setor. O principal objetivo desta associação será transformar Portugal numa referência na investigação, inovação, concepção, desenvolvimento, fabrico e teste de produtos e serviços da indústria do setor automóvel.',
  text404: 'Página não encontrada.',
  button404: 'Voltar à página inicial',
  text401: 'Acesso negado. Não tem permissão para aceder a esta página.',
  button401: 'Voltar à página anterior',
  recaptchaValidate: 'Valide que é humano',
  statisticsTitle: 'Estatística do Cluster Automóvel de Portugal',
  workStations: 'Postos de Trabalho',
  businessVolume: 'Voluem de Negócios',
  exports: 'Exportações',
  moreStatistics: 'Mais Estatísticas',
};

export default LOCALES_PT;
