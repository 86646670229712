/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { RiDeleteBinFill } from 'react-icons/ri';
import { MdModeEdit } from 'react-icons/md';
import DeleteModal from '../../components/modals/DeleteModal';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import '../../style/Configurations.css';
import Datatable from '../../components/DataTable';
import EventModal from '../../components/modals/EventModal';
import CredentialsModal from '../../components/modals/CredentialsModal';
import OpportunityModal from '../../components/modals/OpportunityModal';

export default function PrivateArea() {
  const axiosPrivate = useAxiosPrivate();
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [members, setMembers] = useState([]);

  const [modalInfo, setModalInfo] = useState([]);
  const [modalType, setModalType] = useState('');
  const [showEdit, setEditShow] = useState(false);
  const [showDelete, setDeleteShow] = useState(false);

  const handleCloseEdit = () => setEditShow(false);
  const handleCloseDelete = () => setDeleteShow(false);

  const handleShowEdit = () => setEditShow(true);
  const handleShowDelete = () => setDeleteShow(true);

  const createUser = (user) => {
    axiosPrivate.post('users', JSON.stringify(user), {
      withCredentials: true,
      headers: { 'Content-Type': 'application/json' },
    })
      .then(() => {
        window.location.reload();
      })
      .catch((err) => console.log(err));
  };

  const eventsColumns = [
    {
      name: 'Título',
      selector: (row) => row.title_pt,
      cell: ((row) => (
        <span>
          <b>Título: </b>
          {row.title_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Data',
      selector: (row) => row.start_date,
      cell: ((row) => (
        <span>
          <b>Data: </b>
          {row.start_date}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Localidade',
      selector: (row) => row.location_pt,
      cell: ((row) => (
        <span>
          <b>Localização: </b>
          {row.location_pt}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.editButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.link,
      cell: ((row) => row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  const userColumns = [
    {
      name: 'Nome',
      selector: (row) => row.username,
      cell: ((row) => (
        <span>
          <b>Nome: </b>
          {row.username}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Cargo',
      selector: (row) => row.role,
      cell: ((row) => (
        <span>
          <b>Cargo: </b>
          {row.role}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      cell: ((row) => (
        <span>
          <b>Email: </b>
          {row.email}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '100px',
      selector: (row) => row.email,
      cell: ((row) => row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  const opportunitiesColumns = [
    {
      name: 'Título',
      selector: (row) => row.title,
      cell: ((row) => (
        <span>
          <b>Título: </b>
          {row.title}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Tipo',
      selector: (row) => row.type,
      cell: ((row) => (
        <span>
          <b>Área de negócio: </b>
          {row.type}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: 'Localização',
      selector: (row) => row.location,
      cell: ((row) => (
        <span>
          <b>Localização: </b>
          {row.location}
        </span>
      )),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.type,
      cell: ((row) => row.editButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
    {
      name: '',
      width: '70px',
      selector: (row) => row.type,
      cell: ((row) => row.deleteButton),
      style: {
        background: '#E0E7EF',
        fontSize: '20px',
      },
    },
  ];

  useEffect(() => {
    axiosPrivate.get('users', {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        const usersData = res.data;
        usersData.forEach((user) => {
          const button = (
            <button type='button' onClick={() => { setModalInfo(user); setModalType('user'); handleShowDelete(); }} aria-label='delete event' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );
          user.deleteButton = button;
        });
        setUsers(usersData);
      })
      .catch((err) => console.log(err));

    axiosPrivate.get('events', {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => {
        const eventsData = res.data;
        eventsData.forEach((event) => {
          const deleteButton = (
            <button type='button' onClick={() => { setModalInfo(event); setModalType('event'); handleShowDelete(); }} aria-label='delete event' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );
          const editButton = (
            <button type='button' onClick={() => { setModalInfo(event); setModalType('event'); handleShowEdit(); }} aria-label='edit event' className='icon'><MdModeEdit style={{ color: ' #4D4D4D' }} /></button>
          );
          event.deleteButton = deleteButton;
          event.editButton = editButton;
        });
        setEvents(eventsData);
      })
      .catch((err) => console.log(err));

    axiosPrivate.get(
      'opportunities',
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      },
    )
      .then((res) => {
        const opportunitiesData = res.data;
        opportunitiesData.forEach((opportunity) => {
          const deleteButton = (
            <button type='button' onClick={() => { setModalInfo(opportunity); setModalType('opportunity'); handleShowDelete(); }} aria-label='delete event' className='icon'><RiDeleteBinFill style={{ color: ' #4D4D4D' }} /></button>
          );
          const editButton = (
            <button type='button' onClick={() => { setModalInfo(opportunity); setModalType('opportunity'); handleShowEdit(); }} aria-label='edit event' className='icon'><MdModeEdit style={{ color: ' #4D4D4D' }} /></button>
          );
          opportunity.deleteButton = deleteButton;
          opportunity.editButton = editButton;
        });
        setOpportunities(opportunitiesData);
      })
      .catch((err) => console.log(err));

    axiosPrivate.get('members', {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then((res) => { setMembers(res.data); })
      .catch((err) => console.log(err));
  }, []);

  /* eslint-disable react/function-component-definition */
  const ModalContent = () => {
    if (modalType === 'user') {
      const deleteUser = () => {
        axiosPrivate.delete(`users/${modalInfo.user_id}`, {
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      if (showDelete) return <DeleteModal show={showDelete} onHide={handleCloseDelete} text='o utilizador' deleteFunction={deleteUser} />;
    }

    if (modalType === 'event') {
      const deleteEvent = () => {
        axiosPrivate.delete(`events/${modalInfo.event_id}`, {
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      const editEvent = (type_, newEvent, formData = null) => {
        if (formData === null) {
          const {
            title_pt, title_en, description_pt, description_en, location_pt, location_en, start_date, end_date, user_id, type_pt, type_en, link,
          } = newEvent;
          axiosPrivate.patch(`${type_}/${modalInfo.event_id}`, JSON.stringify({
            title_pt, title_en, description_pt, description_en, location_pt, location_en, start_date, end_date, user_id, type_pt, type_en, link,
          }), {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }).then(() => window.location.reload())
            .catch((e) => console.log(e));

          return;
        }
        axiosPrivate.patch(`${type_}/${modalInfo.event_id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      return showEdit ? <EventModal show={showEdit} onHide={handleCloseEdit} event={modalInfo} callback={editEvent} buttonText='Editar' />
        : <DeleteModal show={showDelete} onHide={handleCloseDelete} text='o evento' deleteFunction={deleteEvent} />;
    }

    if (modalType === 'opportunity') {
      const deleteOpportunity = () => {
        axiosPrivate.delete(`opportunities/${modalInfo.opportunity_id}`, {
          withCredentials: true,
        }).then(() => window.location.reload())
          .catch((e) => console.log(e));
      };

      const editOpportunity = (newOpportunity, formData = null) => {
        if (formData === null) {
          axiosPrivate.patch(`opportunities/${modalInfo.opportunity_id}`, JSON.stringify(newOpportunity), {
            withCredentials: true,
            headers: { 'Content-Type': 'application/json' },
          }).then(() => window.location.reload())
            .catch((e) => console.log(e));
        } else {
          axiosPrivate.patch(`opportunities/${modalInfo.opportunity_id}`, formData, {
            withCredentials: true,
            headers: { 'Content-Type': 'multipart/form-data' },
          }).then(() => window.location.reload())
            .catch((e) => console.log(e));
        }
      };

      return showEdit ? <OpportunityModal show={showEdit} onHide={handleCloseEdit} opportunity={modalInfo} callback={editOpportunity} buttonText='Editar' />
        : <DeleteModal show={showDelete} onHide={handleCloseDelete} text=' a oportunidade' deleteFunction={deleteOpportunity} />;
    }

    return null;
  };

  return (
    <div id='privateArea'>
      <div className='row w-100'>
        <ModalContent />
        <div className='col d-flex align-items-start align-items-center'>
          <span className='mobinov-title mobinov-grey-color'>Área Reservada</span>
        </div>
        <div className='col d-flex justify-content-end align-items-start'>
          <button type='button' className='btn btn-primary mobinov-subtitle mb-3 py-2' data-toggle='modal' data-target='#credentialsModal'>Criar Credenciais</button>
        </div>
      </div>
      <div className='config-row'>
        <div className='row w-100'>
          <div className='col d-flex align-items-start align-items-center'>
            <span className='mobinov-title mobinov-grey-color'>Eventos:</span>
          </div>
        </div>
        <div className='my-3 mr-4'>
          <Datatable columns={eventsColumns} data={events} text='title_pt' searchbar='título' paginationB searchable />
        </div>
        <div className='row w-100'>
          <div className='col d-flex align-items-start align-items-center'>
            <span className='mobinov-title mobinov-grey-color mt-4'>Oportunidades de negócio:</span>
          </div>
        </div>
        <div className='my-3 mr-4'>
          <Datatable columns={opportunitiesColumns} data={opportunities} text='title' searchbar='título' paginationB searchable />
        </div>
        <div className='row w-100'>
          <div className='col d-flex align-items-start align-items-center'>
            <span className='mobinov-title mobinov-grey-color mt-4'>Utilizadores:</span>
          </div>
        </div>
        <div className='my-3 mr-4'>
          <Datatable columns={userColumns} data={users} text='username' searchbar='nome' paginationB searchable />
        </div>
      </div>
      {members && <CredentialsModal members={members} callback={createUser} />}
    </div>
  );
}
