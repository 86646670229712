/* eslint-disable camelcase */
import { Modal, Form, Button } from 'react-bootstrap';
import '../../style/Modal.css';
import { useState } from 'react';

export default function NewsModal({
  show, onHide, news, callback, buttonText,
}) {
  const [title_pt, setTitle_pt] = useState(news ? news.title_pt : '');
  const [title_en, setTitle_en] = useState(news ? news.title_en : '');
  const [description_pt, setDescription_pt] = useState(news ? news.description_pt : '');
  const [description_en, setDescription_en] = useState(news ? news.description_en : '');
  const [date, setDate] = useState(news ? news.date : '');
  const [link, setLink] = useState(news ? news.link : '');

  const [eventImage, setEventImage] = useState(null);

  const submition = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();

    if (form.checkValidity() === false) {
      e.stopPropagation();
    } else if (eventImage == null) {
      callback('news', {
        title_pt, title_en, description_pt, description_en, date, link,
      });
    } else {
      const formData = new FormData();
      formData.append('title_pt', title_pt);
      formData.append('title_en', title_en);
      formData.append('description_pt', description_pt);
      formData.append('description_en', description_en);
      formData.append('date', date);
      formData.append('link', link);
      formData.append('image', eventImage);
      callback('news', {
        title_pt, title_en, description_pt, description_en, date, link,
      }, formData);
    }
  };

  const handleFileSelect = (e) => {
    setEventImage(e.target.files[0]);
  };

  return (
    <Modal show={show} onHide={onHide} centered animation>
      <div className='modal-background rounded'>
        <Modal.Body className='mx-5 my-3'>
          <Form className='needs-validation' onSubmit={submition}>
            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Título PT </Form.Label>
              <Form.Control type='text' value={title_pt} required onChange={(e) => { setTitle_pt(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Título EN </Form.Label>
              <Form.Control type='text' value={title_en} required onChange={(e) => { setTitle_en(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Descrição PT </Form.Label>
              <Form.Control as='textarea' value={description_pt} required style={{ height: '150px' }} onChange={(e) => { setDescription_pt(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Descrição EN </Form.Label>
              <Form.Control as='textarea' value={description_en} required style={{ height: '150px' }} onChange={(e) => { setDescription_en(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Data </Form.Label>
              <Form.Control type='date' value={date} required onChange={(e) => { setDate(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Link </Form.Label>
              <Form.Control type='url' value={link} required onChange={(e) => { setLink(e.target.value); }} />
            </Form.Group>

            <Form.Group className='mb-3 validate-me validate-me'>
              <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Imagem </Form.Label>
              <Form.Control type='file' accept='image/*' required={news == null} onChange={handleFileSelect} />
            </Form.Group>

            <div className='row d-flex justify-content-center mt-4'>
              <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                {buttonText}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
}
