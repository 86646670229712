import { useNavigate } from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export default function Unauthorized() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const goBack = () => navigate(-1, { replace: true });

  return (
    <Row
      className='g-0 text-center align-items-center'
      style={{ height: '100vh' }}
    >
      <Col className='g-0'>
        <img width='500' src='/images/logo-grey.svg' alt='logo' />
        <h2 className='mobinov-grey-color mb-5'>{t('text401')}</h2>
        <Button onClick={goBack}>
          <b className='fs-5'>{t('button401')}</b>
        </Button>
      </Col>
    </Row>
  );
}
