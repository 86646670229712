const LOCALES_EN = {
  contactUs: 'Contact us',
  calendar: 'Calendar',
  subjectForm: 'Subject',
  nameForm: 'Name',
  phoneForm: 'Telephone',
  messageForm: 'Message',
  termsForm: 'I agree with the Terms of Use and Privacy Policy and I declare that I have read the information that is required in accordance with Article 13 of GDPR.',
  emailValidation: 'Please provide a valid email.',
  fieldValidation: 'Please fill the missing field.',
  list: 'List',
  messageValidation: 'Please enter a message.',
  mustAgree: 'You must agree before submitting.',
  moreEvents: 'More events',
  sendEmail: 'Send email',
  mobinovHeadquarters: 'Mobinov Headquarters',
  mobinovLisbon: 'Lisbon Delegation',
  membersPartners: 'Members and Partners',
  associations: 'Associations',
  entities: 'STC Entities',
  education: 'University education',
  enterprises: 'Enterprises',
  builders: 'Builders',
  faqQuestion1: 'What is the number of jobs in the automotive industry?',
  faqAnswer1: '12.7 million Europeans work in the auto industry (directly and indirectly), accounting for 6.6% of all EU jobs.',
  faqQuestion2: 'What is the turnover generated by the automotive industry in the EU?',
  faqAnswer2: 'The turnover generated by the auto industry represents more than 8% of the EU’s GDP.',
  faqQuestion3: 'How many vehicles were produced in Portugal?',
  faqAnswer3: 'A total of 264.000 vehicles were produced in Portugal in 2020.',
  faqQuestion4: 'How many car models are produced in Portugal?',
  faqAnswer4: 'By the end of 2019, 8 models from 4 different manufacturers were produced.',
  becomeMember: 'Become a Member',
  moreNews: 'More News',
  fullSchedule: 'Complete Agenda',
  moreInfo: 'Additional information',
  cofinanced: 'Co-financed by:',
  socialDesignation: 'Social designation',
  enterpriseAddress: 'Enterprise address',
  postalCode: 'Postal Code',
  locality: 'Locality',
  cc: 'cc',
  enterpriseDescription: 'Enterprise`s description',
  contactName: 'Contact name',
  logo: 'Logo',
  postalCodeValidation: 'Please provide a valid postal code.',
  ccValidation: 'Please provide a valid cc.',
  rememberMe: 'Remember me',
  forgotPassword: 'Forgot you password?',
  errorLogin: 'Wrong email/password combination',
  news: 'News',
  contacts: 'Contacts',
  reservedArea: 'Private Area',
  members: 'Members',
  services: 'Services',
  projects: 'Projects',
  projectsDescription1: 'English version of description',
  projectsDescription2: 'English version of description',
  projectsApproved: 'Approved',
  projectsFinished: 'Finished',
  partnerships: 'Partnerships',
  readMore: 'Read More',
  viewProjectFile: 'View Project File',
  gallery: 'Gallery',
  statistics: 'Statistics',
  newPassword: 'New Password',
  repeatNewPassword: 'Repeat the New Password',
  resetPasswordSuccess: 'Your password has been successfully reset, please try logging in again.',
  resetPasswordFailure: 'Problem recovering password. Please send another email.',
  backToLogin: 'Back to Login',
  sendAnotherEmail: 'Send another email',
  resetPassword: 'Reset Password',
  resetPasswordSubmit: 'Change Password',
  resetPasswordInvalidPass: 'Invalid Passwords',
  recoverPassword: 'Password Recovery',
  recoverPasswordSubmit: 'Recover Password',
  recoverPasswordText: 'Forgot your login credentials? Enter your email address and receive instructions to recover your account.',
  errorRecoverPassword: 'Invalid email',
  successRecoverPassword: 'Email sent with success',
  errorEmailSent: 'Error sending email',
  mobinovHistory: 'MOBINOV\'s History',
  whoarewe1: 'MOBINOV is a platform that aggregates knowledge and competence within the automotive industry, aiming to promote an increasing valorisation of competitiveness and internalization of the sector, as well as to contribute to turning Portugal into a reference in research, innovation, design, development, manufacturing and testing products and services in the automotive industry.',
  whoarewe2: 'MOBINOV supports its members in various innovation, development and acceleration processes, at national and international level. By becoming a member of the cluster, you can benefit from a wide range of services dedicated to the automotive industry. Find below the of services that MOBINOV provides to all its members.',
  whoarewe: 'Who Are We',
  vision: 'Vision',
  visionText: 'Our vision is to be a competitive and recognized Automotive Industry in the development of technological solutions and the industrialization of components, modules, systems, and vehicles.',
  mission: 'Mission',
  missionText: 'Our Mission is to promote a sustainable growth and competitiveness of the national automotive industry through greater cooperation and coordination between companies, associations, public administration bodies and SCTN entities. ',
  chart: 'Chart',
  statute: 'Statutes',
  ourServices: 'Our Services',
  servicesDescription1: 'MOBINOV supports its members in various innovation, development and acceleration processes, at national and international level. By becoming a member of the cluster, you can benefit from a wide range of services dedicated to the automotive industry.',
  servicesDescription2: 'Find below the list of services that MOBINOV provides to all its members.',
  service1: 'Bringing component manufacturers and suppliers closer together',
  service2: 'Access to the Reserved Area',
  service3: 'Access to updated studies',
  service4: 'Participation in working groups ',
  service5: 'Support in the preparation of National and European Calls',
  service6: 'Integration in National and European Projects',
  service7: 'Support in the integration and constitution of European consortiums',
  service8: 'Networking',
  service9: 'National and International visibility',
  service10: 'Weekly Newsletter',
  service11: 'Participation in the construction of public policies for the cluster',
  service12: 'Workshops and thematic seminars',
  service13: 'Lobby',
  clusterTitle: 'Cluster Automóvel - Automotive Industry in Portugal',
  clusterConstructors: 'Constructors',
  clusterComponents: 'Component manufacturers',
  clusterProviders: 'Other suppliers',
  clusterEnterprises: 'Business associations',
  clusterUniversities: 'Higher Education Institutions',
  clusterInvestigation: 'Research Centers',
  clusterKnowledge: 'Knowledge Transfer Entities',
  clusterProfessional: 'Vocational Training Centers',
  knowledgeChain: 'Knowledge Chain',
  investigationInnovation: 'Investigation & Inovation',
  internationalSupport: 'Internationalization support',
  knowledgeChainText: 'This is a knowledge aggregator platform between the entire National Automotive Industry.',
  investigationInnovationText: 'We promote Research & Innovation in the sector, increasing the level of competitiveness of the Portuguese Automotive Industry.',
  internationalSupportText: 'Create an environment to promote and support the internationalization of the National Automotive Industry.',
  homepage1: 'A Knowledge and Competence Aggregator Platform',
  homepage2: 'Get to know MOBINOV, the Automotive Cluster of Portugal',
  homepageVideoDesc: 'MOBINOV is characterized as a platform for aggregating knowledge and competence within the automotive sector, to promote a growing appreciation of the sector`s competitiveness and internationalization. The main objective of this association will be to transform Portugal into a reference in research, innovation, design, development, manufacture and testing of products and services in the automotive industry.',
  text404: 'Page not found.',
  button404: 'Go to Homepage',
  text401: 'Unauthorized. You do not have permission to access this page.',
  button401: 'Go back',
  recaptchaValidate: 'Validate that you are a human',
  statisticsTitle: 'Statistics of the Automotive Cluster of Portugal',
  workStations: 'Work Stations',
  businessVolume: 'Business Volume',
  exports: 'Exports',
  moreStatistics: 'More Statistics',
};

export default LOCALES_EN;
