import {
  Row, Col, Container, Form, Button,
} from 'react-bootstrap';
import { useState } from 'react';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import MapContact from '../components/MapContact';
import FAQitem from '../components/FAQitem';
import axios from '../api/axios';

export default function ContactUs() {
  const { t } = useTranslation();

  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [showEmailError, setShowEmailError] = useState(false);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);

  window.addEventListener('load', () => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');

    // Get all form-groups in need of validation
    const validateGroup = document.getElementsByClassName('validate-me');

    // Loop over them and prevent submission
    Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        // Added validation class to all form-groups in need of validation
        for (let i = 0; i < validateGroup.length; i++) {
          validateGroup[i].classList.add('was-validated');
        }
      }, false);
    });
  }, false);

  const sendEmail = async (e) => {
    setShowEmailSuccess(false);
    setShowEmailSuccess(false);
    e.preventDefault();

    axios
      .post('/contactUs', JSON.stringify({
        subject, name, email, phone, message,
      }), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        setShowEmailSuccess(true);
      })
      .catch(() => {
        setShowEmailError(true);
      });
  };

  return (
    <Container className='g-0'>
      <h1 className='mobinov-header mobinov-grey-color text-center mb-5'>{t('contactUs')}</h1>
      <Row className='g-0 justify-content-around'>
        <Col className='g-0 col-11 col-md-5'>
          <div className='p-4 rounded mobinov-grey-bg shadow'>
            <Form noValidate className='needs-validation' onSubmit={sendEmail}>
              <Form.Group className='mb-3' controlId='formBasicSubject'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>{t('subjectForm')}</Form.Label>
                <Form.Control type='text' value={subject} onChange={(e) => setSubject(e.target.value)} />
              </Form.Group>

              <Form.Group className='mb-3' controlId='formBasicName'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>{t('nameForm')}</Form.Label>
                <Form.Control type='text' value={name} onChange={(e) => setName(e.target.value)} />
              </Form.Group>

              <Form.Group className='mb-3 validate-me' controlId='formBasicEmail'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Email*</Form.Label>
                <Form.Control type='email' required value={email} onChange={(e) => setEmail(e.target.value)} />
                <Form.Control.Feedback type='invalid'>{t('emailValidation')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3' controlId='formBasicPhone'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>{t('phoneForm')}</Form.Label>
                <Form.Control type='text' value={phone} onChange={(e) => setPhone(e.target.value)} />
              </Form.Group>

              <Form.Group className='mb-3 validate-me' controlId='formBasicMessage'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>
                  {t('messageForm')}
                  *
                </Form.Label>
                <Form.Control as='textarea' required style={{ height: '150px' }} value={message} onChange={(e) => setMessage(e.target.value)} />
                <Form.Control.Feedback type='invalid'>{t('messageValidation')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 validate-me' controlId='formBasicCheckbox'>
                <Form.Check
                  className='mobinov-text-small mobinov-darkgrey-color'
                  type='checkbox'
                  required
                  label={t('termsForm')}
                  feedback={t('mustAgree')}
                  feedbackType='invalid'
                />
              </Form.Group>

              {showEmailError && (
                <div className='alert alert-danger py-1'>
                  {t('errorEmailSent')}
                </div>
              )}

              {showEmailSuccess && (
                <div className='alert alert-success py-1'>
                  {t('successRecoverPassword')}
                </div>
              )}

              <div className='text-end'>
                <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                  {t('sendEmail')}
                </Button>
              </div>
            </Form>
          </div>
        </Col>
        <Col className='g-0 col-11 col-md-5 mt-5 mt-md-0'>
          <p className='mb-2'>
            <span className='h-100 me-3'><FaEnvelope /></span>
            <span className='mobinov-subtitle mobinov-grey-color'>geral@mobinov.pt</span>
          </p>
          <p className='mb-2'>
            <span className='h-100 me-3'><FaPhoneAlt /></span>
            <span className='mobinov-subtitle mobinov-grey-color align-middle'>+351 220 164 898</span>
          </p>
          <p className='mb-0'>
            <span className='h-100 me-3'><FaPhoneAlt /></span>
            <span className='mobinov-subtitle mobinov-grey-color align-middle'>+351 911 089 214</span>
          </p>

          <MapContact
            title={t('mobinovHeadquarters')}
            framescr='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3003.265639245893!2d-8.681587284743111!3d41.172372979284546!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd246f6a58a7a4df%3A0x3689553849a70079!2sAv.%20Dom%20Afonso%20Henriques%201825%2C%204450-017%20Matosinhos!5e0!3m2!1spt-PT!2spt!4v1646661861761!5m2!1spt-PT!2spt'
            location='Av. D. Afonso Henriques 1825, 4450-017 Matosinhos'
          />
          <MapContact
            title={t('mobinovLisbon')}
            framescr='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3113.7990923342077!2d-9.216583684823696!3d38.699458979601665!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd1ecb67a90b55cb%3A0xba9126a82ff7adad!2sAv.%20da%20Torre%20de%20Bel%C3%A9m%2029%2C%201400-343%20Lisboa!5e0!3m2!1spt-PT!2spt!4v1646665293994!5m2!1spt-PT!2spt'
            location='Avenida Torre de Belém, 29 1400-342 Lisboa'
          />
        </Col>
      </Row>
      <div id='FAQs' className='mt-5'>
        <h1 className='mobinov-header mobinov-grey-color text-center pt-4 my-5'>FAQ&apos;s</h1>
        <Col className='g-0 mx-auto w-75'>
          <Row className='g-0 row-cols-2 justify-content-xl-around justify-content-between'>
            <FAQitem
              question={t('faqQuestion1')}
              answer={t('faqAnswer1')}
            />
            <FAQitem
              question={t('faqQuestion2')}
              answer={t('faqAnswer2')}
            />
          </Row>
          <Row className='g-0 row-cols-2 justify-content-xl-around justify-content-between mt-4'>
            <FAQitem
              question={t('faqQuestion3')}
              answer={t('faqAnswer3')}
            />
            <FAQitem
              question={t('faqQuestion4')}
              answer={t('faqAnswer4')}
            />
          </Row>
        </Col>
      </div>
      <div className='text-center mt-5'>
        <Button href='/become-member' variant='primary' className='mobinov-subtitle mobinov-blue-bg w-auto px-3 mt-5'>
          {t('becomeMember')}
        </Button>
      </div>
    </Container>
  );
}
