import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../locales/i18n';

import '../style/Navbar.css';

function Navbar() {
  const { t } = useTranslation();
  const [currentLang, setCurrentLang] = useState(i18n.language);

  useEffect(() => {
    const otherLang = currentLang === 'en' ? 'pt' : 'en';

    document.getElementById(currentLang).style.fontWeight = 'bold';
    document.getElementById(otherLang).style.fontWeight = 'normal';
  }, [currentLang]);

  const changeLanguage = () => {
    const nextLang = currentLang === 'en' ? 'pt' : 'en';

    setCurrentLang(nextLang);
    i18n.changeLanguage(nextLang);
    localStorage.setItem('language', nextLang);
  };

  return (
    <nav id='navbar' className='navbar navbar-expand-lg navbar-light bg-light'>
      <div className='container-fluid'>
        <a className='navbar-brand mt-2 ml-5' href='/'><img id='logo' width='135' height='70' src='/images/logo-grey.svg' alt='logo' /></a>
        <button
          className='navbar-toggler'
          type='button'
          data-bs-toggle='collapse'
          data-bs-target='#navbarSupportedContent'
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
        >
          <span className='navbar-toggler-icon' />
        </button>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav d-flex w-100 align-items-center justify-content-evenly align-content-around mb-2 mb-lg-0'>
            <li className='nav-item'>
              <a className='nav-link' href='/whoarewe'>{t('whoarewe')}</a>
              <div className='block-navbar' />
              <div className='sub-navbar'>
                <div className='sub-navbar-content'>
                  <a href='/whoarewe'>{t('whoarewe')}</a>
                  <a href='/members'>{t('members')}</a>
                  <a href='/services'>{t('services')}</a>
                  <a href='/projects'>{t('projects')}</a>
                  <a href='/gallery'>{t('gallery')}</a>
                  <a href='/statistics'>{t('statistics')}</a>
                </div>
              </div>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/news'>{t('news')}</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/schedule'>Agenda</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/cluster'>Cluster Auto</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/contact-us'>{t('contacts')}</a>
            </li>
            <li className='nav-item'>
              <a className='nav-link' href='/become-member'>{t('becomeMember')}</a>
            </li>
            <li className='nav-item'>
              {/* TODO change to reserved member's area endpoint */}
              <a className='nav-link' href='/reserved-home-page'>{t('reservedArea')}</a>
            </li>
            <button type='button' className='nav-item' onClick={changeLanguage}>
              <span id='pt'> PT </span>
              |
              <span id='en'> EN </span>
            </button>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
