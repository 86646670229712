import '../../style/Modal.css';
import { Modal } from 'react-bootstrap';

export default function DeleteModal({
  show, onHide, text, deleteFunction,
}) {
  return (
    <Modal show={show} onHide={onHide} centered animation>
      <div className='modal-background rounded'>
        <Modal.Body className='mx-3 my-3 d-flex justify-content-center'>
          <div className='row w-70 text-center'>
            <h4 className='mobinov-subtitle mobinov-grey-color'>
              Tem a certeza que pretende eliminar
              {` ${text} `}
              ?
            </h4>
            <div className='mt-4 d-grid gap-2 d-md-flex justify-content-center justify-content-md-between'>
              <button type='button' onClick={onHide} className='btn-primary mobinov-text py-2 px-4 btn'>
                <span aria-hidden='true'>Cancelar</span>
              </button>
              <button type='button' className='btn-primary mobinov-text py-2 px-4 btn' onClick={deleteFunction}>Eliminar</button>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}
