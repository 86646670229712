import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import axios from 'axios';
import { Row, Col } from 'react-bootstrap';
import TextField from '@mui/material/TextField';
import '../../style/Contacts.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

export default function Contacts() {
  const axiosPrivate = useAxiosPrivate();
  const [contacts, setContacts] = useState([]);
  const [query, setQuery] = useState(null);
  const [wait, setWait] = useState(false);
  const [className, setClassName] = useState(null);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 20;

  const inputHandler = (e) => {
    const lowerCase = e.toLowerCase().trim();
    setQuery(lowerCase);
    setOffset(0);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setWait(false);
    }, 500);
    return () => {
      clearTimeout(timeOutId);
      setWait(true);
    };
  }, [query]);

  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (loading) return;
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setOffset((prevOffset) => prevOffset + limit);
      }
    });
    if (node) observer.current.observe(node);
  }, [loading, contacts]);

  useEffect(() => {
    if (wait) return;

    setLoading(true);
    let cancel;

    // eslint-disable-next-line no-return-assign
    axiosPrivate.get('/users/search', { params: { query, offset, limit }, cancelToken: new axios.CancelToken((c) => cancel = c) })
      .then((res) => {
        if (!className) {
          res.data.length === 1 ? setClassName('contact-unique') : setClassName('contact');
        }

        if (offset === 0) {
          setContacts(res.data);
        } else {
          setContacts((prevContacts) => [...prevContacts, ...res.data]);
        }
        setHasMore(res.data.length === limit);
        setLoading(false);
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        console.log(err);
      });

    // eslint-disable-next-line consistent-return
    return () => cancel();
  }, [wait, offset]);

  return (
    <div id='contacts'>
      <Row className='g-0 search'>
        <TextField
          id='outlined-basic'
          onChange={(e) => inputHandler(e.target.value)}
          variant='outlined'
          fullWidth
          label='Pesquise por nome, email ou empresa'
          className='d-flex justify-content-center'
        />
        <img className='search-image' alt='' src='/images/search.png' />
      </Row>
      <Row id='contacts-list' className='g-0 justify-content-center'>
        {contacts.map((contact, index) => (
          // Contact Card
          <Col
            id={`contact-${contact.user_id}`}
            className={`${className} d-flex flex-row col-md-6 justify-content-center`}
            key={contact.user_id}
            ref={(contacts.length === index + 1) ? lastElementRef : null}
          >
            <div className='me-2'>
              <img className='profile' alt='Profile' src={`${process.env.REACT_APP_BACKEND_URL}/${contact.profile_image}`} />
            </div>
            <Col className='margin'>
              <Row className='g-0 mb-2'>
                <span className='text-center mobinov-title grey-card'>{contact.username}</span>
                <span className='text-center mobinov-formlabel grey-card'>{contact.enterprise}</span>
              </Row>
              <Col className='d-flex align-items-center'>
                <img className='image margin-img' alt='' src='/images/email.png' />
                <span className='info-contacts'>{contact.email}</span>
              </Col>
              <Col className='d-flex justify-content-between'>
                <div>
                  <img className='image-square margin-img' alt='' src='/images/phone.png' />
                  <span className='info-contacts'>{!contact.phone_number ? 'Nenhum associado' : contact.phone_number}</span>
                </div>
                {
                    contact.linkedin && (
                      <a href={contact.linkedin}>
                        <img className='image-square' alt='' src='/images/linkedin.png' />
                      </a>
                    )
                  }
              </Col>
            </Col>
          </Col>
        ))}
      </Row>
    </div>
  );
}
