import axios from '../api/axios';
import useAuth from './useAuth';

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  return async () => {
    const response = await axios.get('/refresh', { withCredentials: true });

    setAuth((prev) => ({
      ...prev,
      userID: response.data.user_id,
      username: response.data.username,
      email: response.data.email,
      enterprise: response.data.enterprise,
      role: response.data.role,
      permissions: response.data.permissions,
      accessToken: response.data.accessToken,
      profileImage: response.data.profile_image,
    }));

    return response.data.accessToken;
  };
};

export default useRefreshToken;
