import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './style/App.css';
import 'react-datepicker/dist/react-datepicker.css';

import ScrollToTop from './components/ScrollToTop';
import Layout from './components/Layout';
import WhoAreWe from './pages/WhoAreWe';
import MembersPartners from './pages/MembersPartners';
import Services from './pages/Services';
import Statistics from './pages/Statistics';
import ContactUs from './pages/ContactUs';
import Projects from './pages/Projects';
import News from './pages/News';
import Gallery from './pages/Gallery';
import ClusterAuto from './pages/ClusterAuto';
import HomePage from './pages/HomePage';
import BecomeMember from './pages/BecomeMember';
import Schedule from './pages/Schedule';

import Login from './pages/auth/Login';
import RecoverPassword from './pages/RecoverPassword';
import ResetPassword from './pages/ResetPassword';
import PersistLogin from './components/auth/PersistLogin';
import RedirectAuth from './components/auth/RedirectAuth';
import RequireAuth from './components/auth/RequireAuth';
import Unauthorized from './pages/error/Unauthorized';
import NotFound from './pages/error/NotFound';
import Opportunities from './pages/configurations/Opportunities';
import Opportunity from './pages/configurations/Opportunity';
import PrivateArea from './pages/configurations/PrivateArea';
import Profile from './pages/configurations/Profile';
import ReservedHomePage from './pages/auth/HomePage';
import PrivateSchedule from './pages/configurations/Schedule';
import ReservedLayout from './components/reservedArea/ReservedLayout';
import Notifications from './pages/configurations/Notifications';
import Administration from './pages/configurations/Administration';
import Contacts from './pages/configurations/Contacts';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* public routes */}
        <Route element={<Layout />}>
          <Route path='/' element={<HomePage />} />
          <Route path='/whoarewe' element={<WhoAreWe />} />
          <Route path='/members' element={<MembersPartners />} />
          <Route path='/services' element={<Services />} />
          <Route path='/statistics' element={<Statistics />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/news' element={<News />} />
          <Route path='/gallery' element={<Gallery />} />
          <Route path='/cluster' element={<ClusterAuto />} />
          <Route path='/schedule' element={<Schedule />} />
          <Route path='/become-member' element={<BecomeMember />} />
          <Route path='/recover-password' element={<RecoverPassword />} />
          <Route path='/reset-password/:token' element={<ResetPassword />} />
        </Route>

        <Route path='/unauthorized' element={<Unauthorized />} />

        {/* auth & protected routes */}
        <Route element={<PersistLogin />}>
          {/* Redirect user from off-auth pages (like Login and Register) if he is authenticated */}
          <Route element={<RedirectAuth />}>
            <Route element={<Layout />}>
              <Route path='/login' element={<Login />} />
            </Route>
          </Route>

          <Route element={<RequireAuth allowedPermissions={['Member', 'Admin']} />}>
            <Route element={<ReservedLayout />}>
              <Route
                path='/reserved-home-page'
                element={<ReservedHomePage />}
              />
              <Route
                path='/agenda'
                element={<PrivateSchedule />}
              />
              <Route
                path='/opportunities'
                element={<Opportunities />}
              />
              <Route
                path='configurations/opportunity/:id'
                element={<Opportunity />}
                exact
              />
              <Route
                path='/conf/account'
                element={<Profile />}
              />
              <Route element={<RequireAuth allowedPermissions={['Admin']} />}>
                <Route
                  path='/conf/reserved-area'
                  element={<PrivateArea />}
                />
                <Route
                  path='/conf/site'
                  element={<Administration />}
                />
              </Route>
              <Route
                path='/contacts'
                element={<Contacts />}
              />
              <Route
                path='/notifications'
                element={<Notifications />}
              />
            </Route>
          </Route>
        </Route>

        {/* 404 */}
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
