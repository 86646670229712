import {
  Row,
} from 'react-bootstrap';
import '../../style/ReservedHomePage.css';
import {
  useNavigate,
} from 'react-router-dom';
import useLogout from '../../hooks/useLogout';

export default function ReservedHomePage() {
  const navigate = useNavigate();
  const logout = useLogout();

  const logOut = async () => {
    await logout();
    navigate('/login');
  };

  return (
    <Row className='d-flex align-items-center justify-content-center'>
      <a className='text-decoration-none d-flex flex-column align-items-center justify-content-center mobinov-reserved rounded' href='/agenda'>
        <img alt='Agenda' className='img-responsive statics' src='/images/reservedHomePage/calendar.svg' />
        <h5 className='text-center'>
          Agenda
        </h5>
      </a>
      <a className='text-decoration-none d-flex flex-column align-items-center justify-content-center mobinov-reserved rounded' href='/opportunities'>
        <img alt='Handshake' className='img-responsive statics' src='/images/reservedHomePage/handshake.svg' />
        <h5 className='text-center'>
          Oportunidades de Negócio
        </h5>
      </a>
      <a className='text-decoration-none d-flex flex-column align-items-center justify-content-center mobinov-reserved rounded' href='conf/account'>
        <img alt='Configurations' className='img-responsive statics' src='/images/reservedHomePage/config.svg' />
        <h5 className='text-center'>
          Configurações
        </h5>
      </a>
      <a className='text-decoration-none d-flex flex-column align-items-center justify-content-center mobinov-reserved rounded' href='/contacts'>
        <img alt='Chat bubbles' className='img-responsive statics' src='/images/reservedHomePage/chat.svg' />
        <h5 className='text-center'>
          Contactos
        </h5>
      </a>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className='text-decoration-none d-flex flex-column align-items-center justify-content-center mobinov-reserved rounded' onClick={logOut}>
        <img alt='Log Out' className='img-responsive statics' src='/images/reservedHomePage/logout.svg' />
        <h5 className='text-center'>
          Log Out
        </h5>
      </div>
    </Row>
  );
}
