import { CgClose } from 'react-icons/cg';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import useAuth from '../hooks/useAuth';

export default function Notification({ notification }) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const deleteNotification = (e) => {
    const button = e.currentTarget.id;
    const id = button.replace('notification-button-', '');

    axiosPrivate.delete(`notifications/${auth.userID}/${id}`, {
      headers: { 'Content-Type': 'application/json' },
      withCredentials: true,
    })
      .then(() => {
        const element = document.getElementById(`notification-${id}`);
        element.parentNode.removeChild(element);
      })
      .catch((err) => console.log(err));
  };
  return (
    <tr id={`notification-${notification.notification_id}`} className='border-blue blue-table-row'>
      <td className='w-50'><a className='text-decoration-none text-reset' href={`/configurations/opportunity/${notification.opportunity_id}`}>{notification.title}</a></td>
      <td>{notification.date}</td>
      <td>{notification.username}</td>
      <td>
        {' '}
        <button type='button' id={`notification-button-${notification.notification_id}`} aria-label='delete notification' className='delete-notification' onClick={deleteNotification}><CgClose style={{ color: ' #8A3024' }} /></button>
        {' '}
      </td>
    </tr>
  );
}
