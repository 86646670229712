import {
  Row, Col, Container, Form, Button,
} from 'react-bootstrap';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from 'react-google-recaptcha';
import { useState } from 'react';
import axios from '../api/axios';

export default function BecomeMember() {
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [locality, setLocality] = useState('');
  const [cc, setCC] = useState('');
  const [website, setWebsite] = useState('');
  const [description, setDescription] = useState('');
  const [contactName, setContactName] = useState('');
  const [recaptchaVerified, setRecaptcha] = useState(false);

  const [showEmailError, setShowEmailError] = useState(false);
  const [showEmailSuccess, setShowEmailSuccess] = useState(false);

  window.addEventListener('load', () => {
    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    const forms = document.getElementsByClassName('needs-validation');

    // Get all form-groups in need of validation
    const validateGroup = document.getElementsByClassName('validate-me');
    // Loop over them and prevent submission
    Array.prototype.filter.call(forms, (form) => {
      form.addEventListener('submit', (event) => {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }

        // Added validation class to all form-groups in need of validation
        for (let i = 0; i < validateGroup.length; i++) {
          validateGroup[i].classList.add('was-validated');
        }
      }, false);
    });
  }, false);

  const changeVal = (e) => {
    setRecaptcha(e);
  };

  const sendEmail = async (e) => {
    setShowEmailSuccess(false);
    setShowEmailSuccess(false);

    e.preventDefault();
    console.log(recaptchaVerified);
    if (!recaptchaVerified) {
      document.getElementById('recaptcha-error').style.display = 'block';
      return;
    }
    document.getElementById('recaptcha-error').style.display = 'none';

    axios
      .post('/becomeMember', JSON.stringify({
        name, address, postalCode, locality, cc, website, description, contactName,
      }), {
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        setShowEmailSuccess(true);
      })
      .catch(() => {
        setShowEmailError(true);
      });
  };

  return (
    <Container className='g-0'>
      <h1 className='mobinov-header mobinov-grey-color text-center mb-5'>{t('becomeMember')}</h1>
      <Row className='g-0 justify-content-center mb-2'>
        <Col className='g-0 col-11 col-lg-8'>
          <Row className='g-0'>
            <Col className='d-flex justify-content-center col-12 col-md-4'>
              <span className='text-center text-truncate mobinov-subtitle mobinov-grey-color'>
                <FaEnvelope style={{ marginRight: '0.5rem' }} size='21px' />
                geral@mobinov.pt
              </span>
            </Col>
            <Col className='d-flex justify-content-center col-12 col-md-4'>
              <span className='text-center text-truncate mobinov-subtitle mobinov-grey-color align-middle'>
                <FaPhoneAlt style={{ marginRight: '0.5rem' }} size='21px' />
                +351 220 164 898
              </span>
            </Col>
            <Col className='d-flex justify-content-center col-12 col-md-4'>
              <span className='text-center text-truncate mobinov-subtitle mobinov-grey-color align-middle'>
                <FaPhoneAlt style={{ marginRight: '0.5rem' }} size='21px' />
                +351 911 089 214
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className='g-0 justify-content-around'>
        <Col id='form' className='g-0 col-11 col-lg-8 mt-2'>
          <div className='p-4 rounded mobinov-grey-bg shadow'>
            <Form noValidate className='needs-validation p-4' onSubmit={sendEmail}>
              <Form.Group className='mb-3 validate-me' controlId='formBasicSubject'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>
                  {t('nameForm')}
                  /
                  {t('socialDesignation')}
                </Form.Label>
                <Form.Control type='text' required value={name} onChange={(e) => setName(e.target.value)} />
                <Form.Control.Feedback type='invalid'>{t('fieldValidation')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 validate-me' controlId='formBasicName'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>{t('enterpriseAddress')}</Form.Label>
                <Form.Control type='text' required value={address} onChange={(e) => setAddress(e.target.value)} />
                <Form.Control.Feedback type='invalid'>{t('fieldValidation')}</Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Col>
                  <Form.Group className='mb-3 validate-me' controlId='formBasicEmail'>
                    <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>
                      {t('postalCode')}
                    </Form.Label>
                    <Form.Control type='text' pattern='[0-9]{4}-[0-9]{3}' required value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                    <Form.Control.Feedback type='invalid'>{t('postalCodeValidation')}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className='mb-3 validate-me' controlId='formBasicMessage'>
                    <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>{t('locality')}</Form.Label>
                    <Form.Control type='text' required value={locality} onChange={(e) => setLocality(e.target.value)} />
                    <Form.Control.Feedback type='invalid'>{t('fieldValidation')}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className='mb-3 validate-me' controlId='formBasicMessage'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>
                  {t('cc')}
                </Form.Label>
                <Form.Control type='tel' inputmode='numeric' pattern='[0-9\s]{13,19}' required value={cc} onChange={(e) => setCC(e.target.value)} />
                <Form.Control.Feedback type='invalid'>{t('ccValidation')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 validate-me' controlId='formBasicName'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>Website URL</Form.Label>
                <Form.Control required type='text' value={website} onChange={(e) => setWebsite(e.target.value)} />
                <Form.Control.Feedback type='invalid'>{t('fieldValidation')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 validate-me' controlId='formBasicMessage'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>
                  {t('enterpriseDescription')}
                </Form.Label>
                <Form.Control as='textarea' required style={{ height: '150px' }} value={description} onChange={(e) => setDescription(e.target.value)} />
                <Form.Control.Feedback type='invalid'>{t('fieldValidation')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 validate-me' controlId='formBasicName'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>{t('contactName')}</Form.Label>
                <Form.Control required type='text' value={contactName} onChange={(e) => setContactName(e.target.value)} />
                <Form.Control.Feedback type='invalid'>{t('fieldValidation')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 col-2 validate-me' controlId='formBasicName'>
                <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'>{t('logo')}</Form.Label>
                <input type='file' accept='image/*' required className='form-control-file' />
                <Form.Control.Feedback type='invalid'>{t('fieldValidation')}</Form.Control.Feedback>
              </Form.Group>

              <Form.Group className='mb-3 validate-me' controlId='formBasicCheckbox'>
                <Form.Check
                  className='mobinov-text-small mobinov-darkgrey-color h-100'
                  type='checkbox'
                  required
                  label={t('termsForm')}
                  feedback={t('mustAgree')}
                  feedbackType='invalid'
                />
              </Form.Group>
              <Form.Group className='mb-3 validate-me' id='recaptchaForm'>
                <ReCAPTCHA
                  className='col-6'
                  sitekey='6LfzMi4gAAAAAPL84kKOIui-XCBcsGVfZl9XboQG'
                  onChange={changeVal}
                />
                <span id='recaptcha-error'>{t('recaptchaValidate')}</span>
              </Form.Group>

              {showEmailError && (
                <div className='alert alert-danger py-1'>
                  {t('errorEmailSent')}
                </div>
              )}

              {showEmailSuccess && (
                <div className='alert alert-success py-1'>
                  {t('successRecoverPassword')}
                </div>
              )}

              <div className='text-end'>
                <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                  {t('becomeMember')}
                </Button>
              </div>

            </Form>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
