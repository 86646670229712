import { FaMapMarkerAlt } from 'react-icons/fa';

export default function MapContact({ title, framescr, location }) {
  return (
    <div className='mt-5'>
      <p className='mobinov-subtitle mobinov-grey-color mb-1'>{title}</p>
      <iframe
        title={title}
        src={framescr}
        width='100%'
        height='250'
        style={{ border: 0 }}
        loading='lazy'
      />
      <p className='mb-0'>
        <span className='h-100 me-2'><FaMapMarkerAlt /></span>
        <span className='mobinov-text mobinov-grey-color align-middle'>{location}</span>
      </p>
    </div>
  );
}
