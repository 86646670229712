import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Carousel, Button, Card, Row, Col, Container,
} from 'react-bootstrap';
import { FaMapMarkerAlt, FaClock } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import axios from '../api/axios';

import '../style/HomePage.css';
import useWindowDimensions from '../components/HandleResize';

export default function HomePage() {
  const { t, i18n } = useTranslation();
  const { width } = useWindowDimensions();
  const [newCard, setNewCard] = useState(0);
  const navigate = useNavigate();
  const [events, setEvents] = useState([]);
  const [news, setNews] = useState([]);

  const getDate = (date) => {
    const dateInfo = date.split('-');
    return `${dateInfo[2]}/${dateInfo[1]}/${dateInfo[0]}`;
  };

  const getEvents = async () => {
    axios
      .get('/eventsReader', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((res) => {
        setEvents(res.data.slice(0, 3));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getNews = async () => {
    axios
      .get('/newsReader', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      .then((res) => {
        setNews(res.data.slice(0, 3));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getEvents();
    getNews();
  }, []);

  return (
    <>
      <div>
        <Carousel>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src='/images/home/1.png'
              alt='First slide'
            />
            <Carousel.Caption>
              <div className='text-start'>
                <h3 className='carousel-portugal mb-4'>PORTUGAL</h3>
                <h1 className='carousel-collaborate'>Collaborate to Grow</h1>
                <h1 className='carousel-mobinov'>MOBINOV</h1>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src='/images/home/2.png'
              alt='Second slide'
            />
            <Carousel.Caption>
              <div className='text-start'>
                <h3 className='carousel-portugal mb-4'>PORTUGAL</h3>
                <h1 className='carousel-collaborate'>Collaborate to Grow</h1>
                <h1 className='carousel-mobinov'>MOBINOV</h1>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img
              className='d-block w-100'
              src='/images/home/3.png'
              alt='Third slide'
            />
            <Carousel.Caption>
              <div className='text-start'>
                <h3 className='carousel-portugal mb-4'>PORTUGAL</h3>
                <h1 className='carousel-collaborate'>Collaborate to Grow</h1>
                <h1 className='carousel-mobinov'>MOBINOV</h1>
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className='homesection-1 align-middle w-100 p-5'>
        <h3 className='mobinov-header mobinov-grey-color text-center mb-5'>{t('homepage1')}</h3>
        {
          width < 992 ? <InfoMobile /> : <Info />
        }
      </div>
      <div className='homesection-2 align-middle p-5'>
        <h3 className='mobinov-header mobinov-grey-color text-center mb-5'>Agenda</h3>
        <div className='d-flex flex-wrap justify-content-around mx-auto w-75 mb-3'>
          {events.map((eventInfo) => (
            <Card key={eventInfo.event_id} className='m-1' style={{ width: '15rem' }}>
              <Card.Img variant='top' src={`${process.env.REACT_APP_BACKEND_URL}/${eventInfo.image}`} />
              <Card.Body className='d-flex flex-column'>
                <Card.Title>{i18n.language === 'pt' ? eventInfo.title_pt : eventInfo.title_en}</Card.Title>
                <div>
                  <p>
                    <FaClock className='m-1' />
                    {getDate(eventInfo.start_date)}
                  </p>
                  {eventInfo.start_date !== eventInfo.end_date
                  && (
                    <p>
                      <FaClock className='m-1' />
                      {getDate(eventInfo.end_date)}
                    </p>
                  )}
                  <p>
                    <FaMapMarkerAlt className='m-1' />
                    {i18n.language === 'pt' ? eventInfo.location_pt : eventInfo.location_en}
                  </p>
                </div>
                <div className='text-center mx-auto w-100 mt-auto'>
                  <Button variant='primary' href={eventInfo.link} className='mobinov-subtitle mobinov-blue-bg w-auto'>
                    {t('moreInfo')}
                  </Button>
                </div>
              </Card.Body>
            </Card>
          ))}
        </div>
        <div className='text-end mx-auto w-75'>
          <Button variant='primary' onClick={() => navigate('/schedule')} className='mobinov-subtitle mobinov-blue-bg w-auto px-3'>
            {t('fullSchedule')}
          </Button>
        </div>
      </div>
      <div className='homesection-3 align-middle w-100 p-5'>
        <h3 className='mobinov-header mobinov-grey-color text-center mb-5'>{t('homepage2')}</h3>
        <div className='m-5 mx-auto w-75'>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video src='/videos/mobinov-institucional-HD.mp4' type='video/mp4' width='100%' height='100%' controls playsInline poster='/images/logo-grey.svg'>
            Your browser does not support the video tag.
          </video>
        </div>
        <p className='my-5 mx-auto w-75 mobinov-text'>{t('homepageVideoDesc')}</p>
        <div className='text-end mx-auto w-75'>
          <Button variant='primary' onClick={() => navigate('cluster')} className='mobinov-subtitle mobinov-blue-bg w-auto px-3'>
            {t('Cluster Auto')}
          </Button>
        </div>
        <h3 className='mobinov-header mobinov-grey-color text-center my-5'>{t('news')}</h3>
        <div className='d-flex flex-wrap justify-content-around mx-auto w-75 mb-3'>
          {news.map((newInfo) => (
            <Card key={newInfo.news_id} className='m-1' style={{ width: '15rem' }} onMouseEnter={() => { setNewCard(newInfo.news_id); }} onMouseLeave={() => { setNewCard(0); }}>
              <Card.Img style={newCard === newInfo.news_id ? { display: 'none' } : { display: 'block' }} variant='top' src={`${process.env.REACT_APP_BACKEND_URL}/${newInfo.image}`} />
              <Card.Body>
                <Card.Title>
                  {i18n.language === 'pt' ? newInfo.title_pt : newInfo.title_en}
                </Card.Title>
                <Card.Text style={newCard === newInfo.news_id ? { display: '-webkit-box' } : { display: 'none' }}>
                  {i18n.language === 'pt' ? newInfo.description_pt : newInfo.description_en}
                </Card.Text>
                <Card.Text style={newCard === newInfo.news_id ? { display: 'block' } : { display: 'none' }} className='card-date'>{getDate(newInfo.date)}</Card.Text>
              </Card.Body>
            </Card>
          ))}
        </div>
        <div className='text-end mx-auto w-75'>
          <Button variant='primary' onClick={() => navigate('/news')} className='mobinov-subtitle mobinov-blue-bg w-auto px-3'>
            {t('moreNews')}
          </Button>
        </div>
        <div className='text-center mt-5'>
          <Button variant='primary' onClick={() => navigate('/become-member')} className='mobinov-subtitle mobinov-blue-bg w-auto px-3 mt-5'>
            {t('becomeMember')}
          </Button>
        </div>
      </div>
    </>
  );
}

function Info() {
  const { t } = useTranslation();

  return (
    <Container className='g-0'>
      <Row className='g-0 sectioninfo row mx-auto'>
        <Col className='g-0 col-4 info-1 text-center my-1 px-3'>
          <img src='/images/home/Union.svg' alt='Union' />
        </Col>
        <Col className='g-0 col-4 info-1 text-center my-1 px-3'>
          <img src='/images/home/Lamp.svg' alt='Union' />
        </Col>
        <Col className='g-0 col-4 info-1 text-center my-1 px-3'>
          <img src='/images/home/World.svg' alt='Union' />
        </Col>
      </Row>
      <Row className='g-0 sectioninfo row mx-auto'>
        <Col className='g-0 col-4 info-1 text-center my-1 px-3'>
          <h5 className='mobinov-title m-3 text-wrap'>{t('knowledgeChain')}</h5>
        </Col>
        <Col className='g-0 col-4 info-1 text-center my-1 px-3'>
          <h5 className='mobinov-title m-3 text-wrap'>{t('investigationInnovation')}</h5>
        </Col>
        <Col className='g-0 col-4 info-1 text-center my-1 px-3'>
          <h5 className='mobinov-title m-3 text-wrap'>{t('internationalSupport')}</h5>
        </Col>
      </Row>
      <Row className='g-0 sectioninfo row mx-auto'>
        <Col className='g-0 col-4 info-1 text-center my-1 px-3'>
          <p className='mobinov-text-nojustify mobinov-grey-color'>
            {t('knowledgeChainText')}
          </p>
        </Col>
        <Col className='g-0 col-4 info-1 text-center my-1 px-3'>
          <p className='mobinov-text-nojustify mobinov-grey-color'>
            {t('investigationInnovationText')}
          </p>
        </Col>
        <Col className='g-0 col-4 info-1 text-center my-1 px-3'>
          <p className='mobinov-text-nojustify mobinov-grey-color'>
            {t('internationalSupportText')}
          </p>
        </Col>
      </Row>
    </Container>
  );
}

function InfoMobile() {
  const { t } = useTranslation();

  return (
    <Row className='g-0 sectioninfo row w-75 mx-auto'>
      <Col className='g-0 col-12 info-1 text-center p-1'>
        <img src='/images/home/Union.svg' alt='Union' />
        <h5 className='mobinov-title m-3 text-wrap'>{t('knowledgeChain')}</h5>
        <p className='mobinov-text-nojustify mobinov-grey-color'>
          {t('knowledgeChainText')}
        </p>
      </Col>
      <Col className='g-0 col-12 info-2 text-center p-1 mt-3'>
        <img src='/images/home/Lamp.svg' alt='Union' />
        <h5 className='mobinov-title m-3 text-wrap'>{t('investigationInnovation')}</h5>
        <p className='mobinov-text-nojustify mobinov-grey-color'>
          {t('investigationInnovationText')}
        </p>
      </Col>
      <Col className='g-0 col-12 info-3 text-center p-1 mt-3'>
        <img src='/images/home/World.svg' alt='Union' />
        <h5 className='mobinov-title m-3 text-wrap'>{t('internationalSupport')}</h5>
        <p className='mobinov-text-nojustify mobinov-grey-color'>
          {t('internationalSupportText')}
        </p>
      </Col>
    </Row>
  );
}
