import '../style/MembersPartners.css';
import React, { useState, useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import axios from '../api/axios';

export default function MembersPartners() {
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState(null);
  const [associations, setAssociations] = useState([]);
  const [entities, setEntities] = useState([]);
  const [colleges, setColleges] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [constructors, setConstructors] = useState([]);

  function changeCard(clustObj) {
    if (selectedCard && selectedCard.id === clustObj.id) {
      setSelectedCard(null);
    } else {
      setSelectedCard(clustObj);
    }
  }

  useEffect(() => {
    axios
      .get('/membersReader', {
      })
      .then(async (res) => {
        const membersA = res.data;
        const associationsArr = [];
        const entitiessArr = [];
        const collegesArr = [];
        const enterprisesArr = [];
        const constructorsArr = [];

        await membersA.forEach((member) => {
          switch (member.type) {
            case 'Associações':
              associationsArr.push(member);
              break;
            case 'Entidades STC':
              entitiessArr.push(member);
              break;
            case 'Ensino Superior':
              collegesArr.push(member);
              break;
            case 'Empresas':
              enterprisesArr.push(member);
              break;
            case 'Construtores':
              constructorsArr.push(member);
              break;
            default:
              break;
          }
        });

        setAssociations(associationsArr);
        setEntities(entitiessArr);
        setColleges(collegesArr);
        setEnterprises(enterprisesArr);
        setConstructors(constructorsArr);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedCard]);

  const members = {
    associations: {
      id: 1,
      name: t('associations'),
      clusters: associations,
    },

    entities: {
      id: 2,
      name: t('entities'),
      clusters: entities,
    },

    superior: {
      id: 3,
      name: t('education'),
      clusters: colleges,
    },

    enterprises: {
      id: 4,
      name: t('enterprises'),
      clusters: enterprises,
    },

    constructors: {
      id: 5,
      name: t('builders'),
      clusters: constructors,
    },
  };

  return (
    <div id='members'>
      <div className='container'>
        <h2 className='text-center mobinov-header mobinov-grey-color mb-5'>{t('membersPartners')}</h2>
        <div className='row'>
          {
            Object.entries(members).map(([, value]) => (
              <button
                type='button'
                className={`col text-center mobinov-grey-color members-card cluster-card-hover ${value.id === selectedCard?.id && 'text-selected mobinov-card-selected'}`}
                onClick={() => changeCard(value)}
              >
                {value.name}
              </button>
            ))
          }
        </div>

        {selectedCard
          && (
            <Row className='g-0 d-flex align-items-center justify-content-center'>
              {
                Object.entries(selectedCard.clusters).map(([, value]) => (
                  <div
                    key={value.logo}
                    className='d-flex flex-column align-items-center justify-content-center cluster-card cluster-card-hover'
                  >
                    <a href={value?.link} target='_blank' aria-label='Link to partner' rel='noopener noreferrer' className='partner-box'>
                      <img alt='Logo' className='logo-images' src={`${process.env.REACT_APP_BACKEND_URL}/${value.image_key}`} />
                    </a>
                  </div>
                ))
              }
            </Row>
          )}
      </div>
    </div>
  );
}
