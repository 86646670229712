import { Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import '../../style/Modal.css';

export default function PasswordModal({ callback }) {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const changePassword = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === false) {
      return;
    }
    if (password !== passwordConfirm) {
      document.getElementById('formPassword').classList.add('is-invalid');
    } else if (currentPassword) {
      document.getElementById('loading').style.display = 'block';
      callback({ currentPassword, password });
    }
  };
  return (
    <div className='modal fade' id='passwordModal' tabIndex='-1' role='dialog' aria-labelledby='credentialsModalType' aria-hidden='true'>
      <div className='modal-dialog modal-dialog-centered' role='document'>
        <div className='row d-flex justify-content-end'>
          <button type='button' className='close' data-dismiss='modal' aria-label='Close'>
            <span aria-hidden='true' className='modal-close-btn' />
          </button>
        </div>
        <div className='modal-content modal-background py-4'>
          <div className='modal-body d-flex justify-content-center'>
            <div className='row w-70'>
              <Form name='passwordForm' id='passwordForm' className='needs-validation' onSubmit={changePassword}>
                <Form.Group className='mb-3 validate-me' controlId='formCurrPassword'>
                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Password atual </Form.Label>
                  <Form.Control type='password' required onChange={(e) => setCurrentPassword(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>A palavra passe não está correta.</Form.Control.Feedback>
                </Form.Group>

                <Form.Group className='mb-3 validate-me' controlId='formPassword'>
                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Password </Form.Label>
                  <Form.Control type='password' required onChange={(e) => setPassword(e.target.value)} />

                  <Form.Label className='mobinov-formlabel mobinov-darkgrey-color'> Voltar a introduzir Password </Form.Label>
                  <Form.Control type='password' required onChange={(e) => setPasswordConfirm(e.target.value)} />
                  <Form.Control.Feedback type='invalid'>As palavras passes não são identicas.</Form.Control.Feedback>
                </Form.Group>

                <div className='row d-flex justify-content-center mt-4'>
                  <Button className='mobinov-subtitle mobinov-blue-bg mt-3 px-3' variant='primary' type='submit'>
                    Mudar palavra-passe
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <div id='loading'>
        <img id='loading-image' src='/videos/loading.gif' alt='Loading...' />
      </div>
    </div>
  );
}
