import React from 'react';
import { Link } from 'react-router-dom';
import { FaLinkedin, FaFacebookSquare, FaTwitterSquare } from 'react-icons/fa';
import { BsTelephone } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';
import { IoLocationOutline } from 'react-icons/io5';
import {
  Container, Row, Col, Stack,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import '../style/Footer.css';

function Footer() {
  const { t } = useTranslation();

  return (
    <footer className='px-3 py-5'>
      <Container className='g-0 justify-content-center'>
        <Row className='g-0 mb-4'>
          <Col lg={9}>
            <Row className='text-center align-items-center g-0 mb-3'>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/'>Homepage</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/services'>{t('services')}</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/members'>{t('members')}</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/cluster'>Cluster Auto</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/schedule'>Agenda</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/become-member'>{t('becomeMember')}</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/contact-us'>{t('contacts')}</Link></div>

              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/projects'>{t('projects')}</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/whoarewe'>{t('whoarewe')}</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/news'>{t('news')}</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/statistics'>{t('statistics')}</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/login'>{t('reservedArea')}</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6 px-3 py-2'><Link to='/gallery'>{t('gallery')}</Link></div>
              <div className='footer-link g-0 col-md-auto col-sm-6' />
            </Row>
          </Col>
          <Col lg={3} className='d-flex justify-content-center align-items-center g-0 mt-sm-3 mt-lg-0'>
            <a href='https://www.facebook.com/MobinovPT/'>
              <FaFacebookSquare className='social-media-icon' />
            </a>
            <a href='https://www.linkedin.com/company/mobinov-cluster-automóvel-portugal/?originalSubdomain=pt'>
              <FaLinkedin className='social-media-icon' />
            </a>
            <a href='https://twitter.com/MobinovPt'>
              <FaTwitterSquare className='social-media-icon' />
            </a>
          </Col>
        </Row>
        <Row className='g-0'>
          <Col sm={6} className='ps-3 footer-contacts text-center text-md-start'>
            <Row className='g-0'>
              <Col lg={6}>
                <Stack gap={1}>
                  <div>
                    <MdOutlineEmail className='contact-icon' />
                    geral@mobinov.pt
                  </div>
                  <div>
                    <BsTelephone className='contact-icon' />
                    +351 220 164 898
                  </div>
                  <div>
                    <BsTelephone className='contact-icon' />
                    +351 911 089 214
                  </div>
                </Stack>
              </Col>
              <Col lg={6} className='g-0'>
                <Stack gap={1}>
                  <div>
                    <IoLocationOutline className='contact-icon' />
                    Av. D. Afonso
                  </div>
                  <div>
                    <BsTelephone className='contact-icon disabled' />
                    Henriques 1825,
                  </div>
                  <div>
                    <BsTelephone className='contact-icon disabled' />
                    4450-017 Matosinhos
                  </div>
                </Stack>
              </Col>
            </Row>
          </Col>
          <Col sm={6} className='authorities mt-4 mt-md-0'>
            <p>{t('cofinanced')}</p>
            <img className='img-fluid' src='/images/authorities.png' alt='Authorities Logo' />
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
